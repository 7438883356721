import { MintInfo } from "../../../config/solana.types";
import InputMaxButton from "./InputMaxButton";

type Props = {
  handleClick?: ((balance: string) => void) | void;
  activeMint: MintInfo;
};

const InputMaxButtonMint = ({ handleClick, activeMint }: Props) => {
  return (
    <InputMaxButton
      handleClick={handleClick}
      assetName={activeMint.name}
      assetCurrency={activeMint.displayName ?? activeMint.name}
      assetDecimals={activeMint.decimals}
    />
  );
};

export default InputMaxButtonMint;
