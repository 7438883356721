import { Chain, ClusterName } from "../../../config/types";
import "./TransactionLink.scss";

type Props = {
  message: string;
  transaction: string;
  cluster: ClusterName;
  chain?: Chain;
};

const SOLANA_EXPLORER = "https://explorer.solana.com";
const OPTIMISM_EXPLORER = "https://goerli-optimism.etherscan.io";
const ARBITRUM_EXPLORER = "https://goerli.arbiscan.io";

function getExplorerLink(chain: Chain, cluster: ClusterName, tx: string) {
  let explorer = SOLANA_EXPLORER;
  switch (chain) {
    case "optimism":
      explorer = OPTIMISM_EXPLORER;
      break;
    case "arbitrum":
      explorer = ARBITRUM_EXPLORER;
      break;
  }

  const explorerURL = new URL(`/tx/${tx}`, explorer);
  const { searchParams } = explorerURL;
  if (cluster !== "mainnet-beta" && chain === "solana") {
    searchParams.append("cluster", cluster);
  }
  return explorerURL.toString();
}

const TransactionLink = ({
  message,
  transaction,
  cluster,
  chain = "solana",
}: Props): JSX.Element => {
  const href = getExplorerLink(chain, cluster, transaction);
  return (
    <a href={href} target="_blank" rel="noreferrer" className="transactionLink">
      {message}
    </a>
  );
};

export default TransactionLink;
