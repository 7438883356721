import { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import { deleteNotificationAction } from "../../../actions/notification";
import { useSelector } from "../../../hooks/hooks";
import Notification from "./Notification";

import "./NotificationContainer.scss";

const NotificationContainer = () => {
  const notification = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  const [closing, setClosing] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);

  const handleClosingClick = useCallback((): void => {
    if (notification == null) {
      return;
    }

    setClosing(true);

    setTimeout(() => {
      dispatch(deleteNotificationAction());
      setClosing(false);
      // Animation takes 500ms to complete
    }, 501);
  }, [dispatch, notification]);

  useEffect(() => {
    if (notification == null || hovering) {
      return;
    }

    const timer = setTimeout(handleClosingClick, 5000);

    return () => clearTimeout(timer);
  }, [handleClosingClick, notification, hovering]);

  if (!notification) {
    return null;
  }

  const className = clsx({
    NotificationContainer: true,
    "NotificationContainer-close": closing,
    "NotificationContainer-hovering": hovering,
  });

  return (
    <div
      className={className}
      data-testid="NotificationContainer-testid"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Notification
        level={notification.level}
        title={notification.title}
        message={notification.message}
        details={notification.details}
        icon={notification.icon}
        close={handleClosingClick}
      />
    </div>
  );
};

export default NotificationContainer;
