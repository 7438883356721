import { ReactNode } from "react";
import { IconName } from "../components/common/icon/Icon";
import { ACTION_TYPES } from "./constants";
import { NotificationLevels } from "./types";

export function createNotificationAction(payload: {
  title: string;
  message: string | ReactNode;
  level: NotificationLevels;
  details?: string;
  icon?: IconName;
}) {
  return {
    type: ACTION_TYPES.CREATE_NOTIFICATION,
    payload,
  } as const;
}

export function deleteNotificationAction() {
  return {
    type: ACTION_TYPES.DELETE_NOTIFICATION,
    payload: null,
  } as const;
}
