import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "./hooks";
import { setTermsConfirmation } from "../actions/terms";

const useTermsOfService = () => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const confirmed = useSelector((state) => state.terms);
  const setConfirmed = useCallback(
    () => dispatch(setTermsConfirmation()),
    [dispatch]
  );

  return {
    checked,
    confirmed,
    setChecked,
    setConfirmed,
  };
};

export default useTermsOfService;
