export const getLocalStorageItem = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch {
    return null;
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch {}
};

export const setLocalStorageItem = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value);
  } catch {}
};
