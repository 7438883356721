import { PublicKey } from "@solana/web3.js";
import {
  USDC_DEVNET,
  USDC_DECIMALS,
  UXD_DECIMALS,
} from "@uxd-protocol/uxd-client";
import { SlippageOptions } from "../components/slippage/Slippage";
import ClusterRPCEndpoint from "../solana/clusterRPCEndpoint";
import {
  ISolanaConfiguration,
  CollateralMints,
  CounterpartMints,
  OtherMints,
  ClusterRPCEndpoints,
} from "./solana.types";
import { ClusterName, Programs } from "./types";
import AUXDSolanaBaseConfig from "./uxd.solana.base";

class UXDDevnetConfig
  extends AUXDSolanaBaseConfig
  implements ISolanaConfiguration
{
  protected governanceRealmAccountPubkey = new PublicKey(
    "Dd28J3TjH72F4g6zqfMJfSGDqL5rPNsKz4pwVn8zkxxu"
  );
  protected governanceProgramPubkey = new PublicKey(
    "GovER5Lthms3bLBqWub97yVrMmEogzX7xNjdXpPPCVZw"
  );

  protected collateralMints: CollateralMints = {
    // USDC for Identity depository
    USDC_IDENTITY: {
      mint: USDC_DEVNET,
      decimals: USDC_DECIMALS,
      name: "USDC_IDENTITY",
      displayName: "USDC",
      features: ["mint", "redeem"],
    },
    // "Solend" USDC for Mercurial
    USDC_MERCURIAL: {
      mint: new PublicKey("zVzi5VAf4qMEwzv7NXECVx5v2pQ7xnqVVjCXZwS9XzA"),
      decimals: USDC_DECIMALS,
      name: "USDC_MERCURIAL",
      displayName: "USDC",
      features: ["mint", "redeem"],
    },
    // Custom USDC for Credix
    USDC_CREDIX: {
      mint: new PublicKey("Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr"),
      decimals: USDC_DECIMALS,
      name: "USDC_CREDIX",
      displayName: "USDC",
      features: ["mint", "redeem"],
    },
  };

  protected counterpartMints: CounterpartMints = {
    // "Mango" USDC
    USDC: {
      mint: new PublicKey("8FRFC6MoGGkMFQwngccyu69VnYbzykGeez7ignHVAFSN"),
      decimals: USDC_DECIMALS,
      name: "USDC",
    },
    UXD: {
      mint: new PublicKey("G3FHCWK3rZGvQvYoMB5j1vAZQ9nrtU7brQfwkN7xjyhS"),
      decimals: UXD_DECIMALS,
      name: "UXD",
    },
  };

  protected otherMints: OtherMints = {
    UXP: {
      mint: new PublicKey("UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M"),
      decimals: 9,
      name: "UXP",
    },
  };

  protected programs: Programs = {
    uxd: {
      address: new PublicKey("BLaLouDQKDDf8T9WCgQpzxCBRtcJ4nbqv7fVQeE9PuCY"),
    },
    staking: {
      address: new PublicKey("AFZhqjf4KTpxsVGb2J7tHkvcVPEVzLzMk9FhBMTparAk"),
    },
    credix: {
      address: new PublicKey("crdszSnZQu7j36KfsMJ4VEmMUTJgrNYXwoPVHUANpAu"),
    },
  };

  protected stakingCampaign: PublicKey = new PublicKey(
    "5Zbyf61mh6kEmtbVQUK2gvtX1ZbE5nr4u1xnYJsiZpAG"
  );

  protected clusterRPCEndpoints: ClusterRPCEndpoints = [
    new ClusterRPCEndpoint("https://api.devnet.solana.com"),
  ];

  protected defaultSlippage: SlippageOptions = "0.5";

  protected clusterName: ClusterName = "devnet";
}

const UXDDevnetConfigInstance = new UXDDevnetConfig();

export default UXDDevnetConfigInstance;
