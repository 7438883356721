import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";
import { removeLocalStorageItem } from "../utils/localstorage";

export type TermsState = boolean;

const getInitialState = () => {
  const terms = window.__UXD__.storage.terms ?? "[]";
  try {
    return new Set(JSON.parse(terms)).has(window.__UXD__.user.ip);
  } catch {
    removeLocalStorageItem(terms);
    return false;
  }
};

const termsReducer = (
  state: TermsState | null,
  action: Actions.AnyAction
): TermsState => {
  if (action.type === ACTION_TYPES.SET_TERMS_CONFIRMATION) {
    // This should ideally be done through a Redux Middleware.
    // It's generally not a good practice to perform side-effects in reducers.
    // Reducers are meant to be pure functions.
    // However, we do not want to introduce such advanced Redux functionality,
    // as we eventually want to move away from Redux, or maybe not.
    // In any case, this is fine for now, since we're careful about it.
    if (window !== undefined && window.localStorage !== undefined) {
      try {
        const terms = window.localStorage.getItem("terms") ?? "[]";
        const previous = JSON.parse(terms);
        const updated = [...new Set(previous.concat(window.__UXD__.user.ip))];
        window.localStorage.setItem("terms", JSON.stringify(updated));
      } catch {
        removeLocalStorageItem("terms");
      }
    }

    return true;
  }

  return state ?? getInitialState();
};

export default termsReducer;
