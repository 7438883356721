import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";
import { WalletAdapterName } from "../adapters/WalletAdapter";

export type WalletState =
  | WalletAdapterName
  | { name: "metamask"; account: string }
  | null;

const INITIAL_STATE = null;

const walletReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): WalletState => {
  switch (action.type) {
    case ACTION_TYPES.CONNECT_WALLET:
      return action.payload;

    case ACTION_TYPES.DISCONNECT_WALLET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default walletReducer;
