import TokenInfo from "../token-info/TokenInfo";
import "./Tokens.scss";

const Tokens = () => {
  return (
    <div className="Tokens">
      <TokenInfo symbol="UXD" />
      <TokenInfo symbol="UXP" decimals={3} />
    </div>
  );
};

export default Tokens;
