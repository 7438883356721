import { useEffect, useState } from "react";
import {
  nativeToUi,
  MercurialVaultDepositoryAccount,
} from "@uxd-protocol/uxd-client";
import { DepositoryInfo } from "../types";

const getRedeemableAmount = (
  depositoryAccount: MercurialVaultDepositoryAccount
) =>
  Math.round(
    nativeToUi(
      depositoryAccount.redeemableAmountUnderManagement,
      window.__UXD__.solana.services.UXD.getControllerRedeemableMintDecimals()
    )
  );

const useMercurialVaultDepositoryInfo = () => {
  const [depositoryInfo, setDepositoryInfo] = useState<DepositoryInfo | null>(
    null
  );

  const depository =
    window.__UXD__.solana.services.mercurial.getMercurialVaultDepository();

  useEffect(() => {
    const fetchDepositoryInfo = async (): Promise<DepositoryInfo> => {
      const mercurialVaultDepositoryAccount =
        await window.__UXD__.solana.services.mercurial.getMercurialVaultDepositoryAccount();

      const redeemableAmount = getRedeemableAmount(
        mercurialVaultDepositoryAccount
      );

      return {
        collateral: "USDC",
        redeemableAmount,
      };
    };

    fetchDepositoryInfo().then((info) => setDepositoryInfo(info));
  }, [depository]);

  return depositoryInfo;
};

export default useMercurialVaultDepositoryInfo;
