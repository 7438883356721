import { useCallback, useEffect, useState } from "react";
import usePeriodicFetch from "../../../hooks/usePeriodicFetch";
import { timestampToDateString } from "../../../utils/date";
import { renderAmount } from "../../../utils/formatting";
import logger from "../../../utils/logger";
import { Icon } from "../../common/icon/Icon";

import "./StakingCampaign.scss";

const FETCH_INTERVAL = 10_000;
const FALLBACK_DISPLAY = "_";
const CIRCULATING_SUPPLY_ENDPOINT =
  "https://api.uxd.fi/api/uxp-circulating-supply";

const StakingCampaign = () => {
  const [stakedAmount, setStakedAmount] = useState<number | null>(null);
  const [circulatingSupply, setCirculatingSupply] = useState<number | null>(
    null
  );

  const fetchCirculatingSupply = useCallback(async () => {
    try {
      logger.debug("Fetching UXP circulating supply ...");
      const response = await window.fetch(CIRCULATING_SUPPLY_ENDPOINT);
      const supply = await response.text();
      if (supply) {
        setCirculatingSupply(Number(supply));
      }
    } catch {
      logger.debug("Cannot load UXP circulating supply");
    }
  }, []);

  useEffect(() => {
    fetchCirculatingSupply();
  }, [fetchCirculatingSupply]);

  const fetchStakedAmount = useCallback(async () => {
    logger.debug("Fetching staked vault balance ...");
    const balance =
      await window.__UXD__.solana.services.staking.fetchStakedAmount();
    setStakedAmount(balance);
  }, []);

  usePeriodicFetch(fetchStakedAmount, FETCH_INTERVAL);

  const endTimestamp =
    window.__UXD__.solana.services.staking.getCampaignEndTimestamp();

  const formattedEndDate =
    endTimestamp !== null && timestampToDateString(endTimestamp);

  const percentageStaked =
    circulatingSupply && stakedAmount ? stakedAmount / circulatingSupply : null;

  const stakedAmountDisplay =
    stakedAmount &&
    renderAmount(stakedAmount, {
      decimals: 0,
      currency: "UXP",
    });

  return (
    <div className="StakingCampaign">
      <div className="StakingCampaign-token">
        <Icon name="UXPToken" className="StakingCampaign-token-icon" />
        <span className="StakingCampaign-token-label">
          <strong>UXP</strong>
          {formattedEndDate && <em>Ends: {formattedEndDate}</em>}
        </span>
      </div>

      <div className="StakingCampaign-info">
        <strong>UXP Circulating Supply</strong>
        <em>
          {circulatingSupply
            ? renderAmount(circulatingSupply, { decimals: 0, currency: "UXP" })
            : FALLBACK_DISPLAY}
        </em>
      </div>
      <div className="StakingCampaign-info">
        <strong>Total UXP Staked</strong>
        <em>
          {stakedAmountDisplay
            ? percentageStaked
              ? `${stakedAmountDisplay} (${percentageStaked.toLocaleString(
                  "en-US",
                  {
                    style: "percent",
                  }
                )})`
              : stakedAmountDisplay
            : FALLBACK_DISPLAY}
        </em>
      </div>
    </div>
  );
};

export default StakingCampaign;
