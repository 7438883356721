import { toFixedWithoutTrailingZeros } from "../utils";

export function calculateMinimumReceived({
  decimals,
  amount,
  slippage,
}: {
  decimals: number;
  amount: number;
  slippage: number | null;
}): string {
  const maximumLost = amount * (slippage ?? 0);
  const minimumReceived = amount - maximumLost;
  return toFixedWithoutTrailingZeros(minimumReceived, decimals);
}

export function calculateRedeemableAmount({
  redeemableDecimals,
  ...values
}: {
  collateralAmount: number;
  collateralPerpUIPrice: number;
  swapFees: number;
  redeemableDecimals: number;
}): string {
  if (values.collateralPerpUIPrice === 0) {
    return "0";
  }

  const collateralAmountUi = values.collateralAmount;
  const redeemableAmountUi = collateralAmountUi * values.collateralPerpUIPrice;

  const swapFeesAmountUi = redeemableAmountUi * values.swapFees;
  const redeemableAmountMinusSwapFeesUi = redeemableAmountUi - swapFeesAmountUi;

  const redeemableDecimalsExp10 = Math.pow(10, redeemableDecimals);
  const redeemableAmountFinalFloored =
    Math.floor(redeemableAmountMinusSwapFeesUi * redeemableDecimalsExp10) /
    redeemableDecimalsExp10;

  return toFixedWithoutTrailingZeros(
    redeemableAmountFinalFloored,
    redeemableDecimals
  );
}

export function calculateCollateralAmount({
  collateralDecimals,
  ...values
}: {
  redeemableAmount: number;
  collateralPerpUIPrice: number;
  swapFees: number;
  collateralDecimals: number;
}): string {
  if (values.collateralPerpUIPrice === 0) {
    return "0";
  }

  const redeemableAmountUi = values.redeemableAmount;
  const collateralAmountUi = redeemableAmountUi / values.collateralPerpUIPrice;

  const swapFeesAmountUi = collateralAmountUi * values.swapFees;
  const collateralAmountMinusSwapFeesUi = collateralAmountUi - swapFeesAmountUi;

  const collateralDecimalsExp10 = Math.pow(10, collateralDecimals);
  const collateralAmountFinalFloored =
    Math.floor(collateralAmountMinusSwapFeesUi * collateralDecimalsExp10) /
    collateralDecimalsExp10;

  return toFixedWithoutTrailingZeros(
    collateralAmountFinalFloored,
    collateralDecimals
  );
}
