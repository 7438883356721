import { Connection, ConnectionConfig, FetchFn } from "@solana/web3.js";
import { ISolanaConfiguration } from "../config/solana.types";
import { APP_SCOPE } from "./constants";
import ClusterRPCEndpoint from "../solana/clusterRPCEndpoint";
import { UXDClientService } from "../solana/uxdClientService";
import { StakingClientService } from "../solana/stakingClientService";
import { NoHealthyRPCAvailableError } from "../utils/error";
import logger from "../utils/logger";
import { SolanaStatus } from "../types";
import { TXN_OPTS } from "../solana/constants";
import { MercurialClientService } from "../solana/mercurialClientService";
import { UXDClient } from "@uxd-protocol/uxd-client";
import { CredixClientService } from "../solana/credixClientService";
import { getFastExpiryJWTToken } from "../utils/jwt";
import { IdentityClientService } from "../solana/identityClientService";
import { RouterClientService } from "../solana/routerClientService";

async function initConnection(clusterRPCEndpoint: ClusterRPCEndpoint) {
  const connectionConfig: ConnectionConfig = {
    commitment: TXN_OPTS.commitment,

    httpHeaders: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const jwt = clusterRPCEndpoint.jwt;

  if (jwt) {
    connectionConfig.fetchMiddleware = async (
      info: Parameters<FetchFn>[0],
      init: Parameters<FetchFn>[1],
      fetch: (...a: Parameters<FetchFn>) => void
    ) => {
      const token = await getFastExpiryJWTToken(jwt);

      // Insert new JWT token just in time
      init.headers["Authorization"] = `Bearer ${token}`;

      fetch(info, init);
    };
  }

  return new Connection(clusterRPCEndpoint.url, connectionConfig);
}

async function initServices(
  config: ISolanaConfiguration,
  connection: Connection
) {
  const uxdPromise = UXDClientService.initialize(config, connection);

  const stakingPromise =
    APP_SCOPE === "staking"
      ? StakingClientService.initialize(config, connection)
      : (Promise.resolve(
          undefined
        ) as unknown as Promise<StakingClientService>); // we force ignore the type safety here

  const uxdClient = new UXDClient(config.getPrograms().uxd.address);

  const identityPromise = IdentityClientService.initialize(
    config,
    connection,
    uxdClient
  );
  const mercurialPromise = MercurialClientService.initialize(
    config,
    connection,
    uxdClient
  );
  const credixPromise = CredixClientService.initialize(
    config,
    connection,
    uxdClient
  );

  const uxd = await uxdPromise;
  const identity = await identityPromise;
  const mercurial = await mercurialPromise;
  const credix = await credixPromise;

  const routerPromise = RouterClientService.initialize(
    config,
    connection,
    uxdClient,
    uxd,
    identity,
    mercurial,
    credix
  );

  return {
    UXD: uxd,
    identity: identity,
    mercurial: mercurial,
    credix: credix,
    router: await routerPromise,
    staking: await stakingPromise,
  };
}

async function initClusterRPCEndpoint(
  config: ISolanaConfiguration
): Promise<ClusterRPCEndpoint> {
  const clusterRPCEndpoints = config.getClusterRPCEndpoints();

  let healthyClusterRPCEndpoint: ClusterRPCEndpoint | null = null;

  for (const clusterRPCEndpoint of clusterRPCEndpoints) {
    if (await clusterRPCEndpoint.checkHealth()) {
      healthyClusterRPCEndpoint = clusterRPCEndpoint;
      break;
    }
  }

  if (!healthyClusterRPCEndpoint) {
    throw new NoHealthyRPCAvailableError(clusterRPCEndpoints);
  }

  return healthyClusterRPCEndpoint;
}

async function initSolanaStatus(): Promise<SolanaStatus> {
  const status = {
    indicator: "unknown",
    description: "Unable to retrieve Solana status",
  };

  if (
    APP_SCOPE !== "dashboard" &&
    process.env.REACT_APP_SOLANA_STATUS_CHECK === "true"
  ) {
    try {
      const res = await fetch("https://status.solana.com/", {
        headers: { Accept: "application/json", "Accept-Language": "en" },
      });
      const {
        status: { indicator, description },
      } = await res.json();

      status.indicator = indicator || status.indicator;
      status.description = description || status.description;

      logger.debug("retrieved solana status", { status });
    } catch (err) {
      logger.error(new Error("Unable to retrieve Solana status"), { err });
    }
  }

  return status;
}

export default async function init(config: ISolanaConfiguration) {
  // async init functions that can safely be ran in parallel with the rest
  const statusP = initSolanaStatus();

  const clusterRPCEndpoint = await initClusterRPCEndpoint(config);
  const connection = await initConnection(clusterRPCEndpoint);

  const servicesP = initServices(config, connection);

  const [status, services] = await Promise.all([statusP, servicesP]);

  return { config, clusterRPCEndpoint, connection, status, services };
}
