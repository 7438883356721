import { FunctionComponent, useEffect, useState } from "react";
import logger from "../../../utils/logger";

const KEY_PREFIX = "mount";

/**
 * Force the remount of the underlying component
 */
export default function withPeriodicRemount<P>(
  Component: FunctionComponent<P>,
  delay: number
) {
  return (props: P): JSX.Element => {
    const [keyId, setKeyId] = useState(0);

    useEffect(() => {
      setTimeout(() => {
        const newKeyId = (keyId * delay + delay) / delay;

        logger.debug("[withPeriodicRemount]: updating key id", {
          keyId: newKeyId,
          delay,
        });

        setKeyId(newKeyId);
      }, delay);
    }, [keyId]);

    const key = `${KEY_PREFIX}-${keyId}`;

    return <Component key={key} {...props} />;
  };
}
