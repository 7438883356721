import { memo, SyntheticEvent, useMemo, useState } from "react";
import { StakingAccount } from "@uxdprotocol/uxd-staking-client";
import Button from "../../common/button/Button";
import { timestampToDateString } from "../../../utils/date";
import { ONE_SECOND, secondsToLabel } from "../../../utils/time";
import { nativeToUi } from "../../../utils/amount";
import { renderAmount } from "../../../utils/formatting";
import { StakingOptionUI } from "../../../types";
import { MintInfo } from "../../../config/solana.types";

// Styling handled by parent component StakingPositions.tsx

const FALLBACK_DISPLAY = "-";
const UXP_DISPLAY_DECIMALS = 2;

type Props = {
  connected: boolean;
  account: StakingAccount;
  options: StakingOptionUI[];
  UXP: MintInfo;
  handleClick: (identifier: string) => Promise<void>;
};

const StakingPositionRow = ({ account, options, UXP, handleClick }: Props) => {
  const [claiming, setClaiming] = useState<boolean>(false);

  const endTimestamp = account.stakeEndTs.toNumber();
  const formattedEndDate = timestampToDateString(endTimestamp * ONE_SECOND);

  const option = account.stakingOptionIdentifier.toString();

  const duration = options.find(
    ({ identifier }) => identifier === option
  )?.lockupSecs;

  const durationDisplay =
    typeof duration !== "undefined"
      ? secondsToLabel(duration)
      : FALLBACK_DISPLAY;

  const amount = useMemo(
    () => nativeToUi(account.stakedAmount, UXP.decimals),
    [account.stakedAmount, UXP.decimals]
  );
  const rewardAmount = useMemo(
    () => nativeToUi(account.rewardAmount, UXP.decimals),
    [UXP.decimals, account.rewardAmount]
  );

  const claimLabel = claiming
    ? "Claiming ..."
    : `Claim ${renderAmount(rewardAmount, {
        decimals: UXP_DISPLAY_DECIMALS,
        currency: UXP.name,
      })}`;

  const claimable = !claiming && endTimestamp * ONE_SECOND <= Date.now();

  return (
    <tr>
      <td>{durationDisplay}</td>
      <td>
        {renderAmount(amount, {
          decimals: UXP_DISPLAY_DECIMALS,
          currency: UXP.name,
        })}
      </td>
      <td>{formattedEndDate}</td>
      <td>
        <Button
          type="button"
          disabled={!claimable}
          className="StakingPositions-claim-button"
          onClick={(evt: SyntheticEvent) => {
            evt.preventDefault();

            setClaiming(true);
            handleClick(option).finally(() => {
              setClaiming(false);
            });
          }}
        >
          {claimLabel}
        </Button>
      </td>
    </tr>
  );
};

export default memo(StakingPositionRow);
