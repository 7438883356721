import { ThunkDispatch as Dispatch } from "redux-thunk";

import logger from "../utils/logger";
import { WalletAdapterName } from "../adapters/WalletAdapter";
import { State } from "../types";
import { Actions } from "./types";
import { createNotificationAction } from "./notification";
import { ACTION_TYPES } from "./constants";

type CreateNotificationAction = ReturnType<typeof createNotificationAction>;

export const connectWalletAction =
  (name: WalletAdapterName | "metamask") =>
  async (
    dispatch: Dispatch<
      State,
      void,
      Actions.ConnectWallet | CreateNotificationAction
    >
  ) => {
    if (name === "metamask") {
      const provider =
        window.__UXD__[window.__UXD__.config.chain as "optimism" | "arbitrum"]
          .provider;

      if (!provider) {
        // User doesn't have metamask
        dispatch(
          createNotificationAction({
            title: "Connect Error",
            message: "Metamask wallet not detected.",
            level: "error",
            icon: "info",
          })
        );
        return;
      }

      const accounts = await provider.request<string[]>({
        method: "eth_requestAccounts",
      });

      const account = accounts?.length && accounts[0];
      if (account) {
        dispatch({
          type: ACTION_TYPES.CONNECT_WALLET,
          payload: { name, account },
        });

        // FIXME: notifications should be handled in the UI
        dispatch(
          createNotificationAction({
            title: "Wallet Connected",
            message: "You are now connected with Metamask.",
            level: "success",
            icon: "link",
          })
        );
      }
      return;
    }

    const { adapter, label } =
      window.__UXD__.solana.config.getWalletAdapter(name);

    adapter.on("connect", () => {
      dispatch({
        type: ACTION_TYPES.CONNECT_WALLET,
        payload: name,
      });

      dispatch(
        createNotificationAction({
          title: "Wallet Connected",
          message: `You are now connected with ${label}.`,
          level: "success",
          icon: "link",
        })
      );
    });

    try {
      await adapter.connect();
    } catch (err) {
      logger.error(new Error(`unable to connect to wallet ${name}`), { err });
    }
  };

export const disconnectWalletAction =
  (name: WalletAdapterName | "metamask") =>
  async (
    dispatch: Dispatch<
      State,
      void,
      Actions.DisconnectWallet | CreateNotificationAction
    >
  ) => {
    if (name === "metamask") {
      dispatch({ type: ACTION_TYPES.DISCONNECT_WALLET });
      dispatch(
        createNotificationAction({
          title: "Wallet Disconnected",
          message: `Your wallet is now disconnected.`,
          level: "warning",
          icon: "linkBroken",
        })
      );
      return;
    }

    const { adapter } = window.__UXD__.solana.config.getWalletAdapter(name);

    adapter.on("disconnect", () => {
      dispatch({ type: ACTION_TYPES.DISCONNECT_WALLET });

      dispatch(
        createNotificationAction({
          title: "Wallet Disconnected",
          message: `Your wallet is now disconnected.`,
          level: "warning",
          icon: "linkBroken",
        })
      );
    });

    try {
      await adapter.disconnect();
    } catch (err) {
      logger.error(new Error(`unable to disconnect to wallet ${name}`), {
        err,
      });
    }
  };
