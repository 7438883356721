import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";
import { CollateralMintName as SolanaCollateralMintName } from "../config/solana.types";
import { CollateralTokenName as OptimismCollateralTokenName } from "../config/optimism.types";
import { CollateralTokenName as ArbitrumCollateralTokenName } from "../config/arbitrum.types";

type Collaterals =
  | SolanaCollateralMintName
  | OptimismCollateralTokenName
  | ArbitrumCollateralTokenName;

export type CollateralPriceState = {
  [key in Collaterals]: string | null;
};

const INITIAL_STATE = {
  SOL: null,
  BTC: null,
  ETH: null,
  WETH: null,
  USDC_IDENTITY: "1",
  USDC_MERCURIAL: "1",
  USDC_CREDIX: "1",
  USDC_ARBITRUM: "1",
} as const;

const collateralPriceReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): CollateralPriceState => {
  switch (action.type) {
    case ACTION_TYPES.SET_COLLATERAL_PRICE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default collateralPriceReducer;
