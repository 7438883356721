import clsx from "clsx";
import { ReactNode } from "react";
import UXPCallToAction from "./uxp-call-to-action/UXPCallToAction";
import Network from "../network/Network";
import Wallet from "../wallet/Wallet";
import RedeemableSupplyGauge from "../redeemable-supply-gauge/RedeemableSupplyGauge";
import Navigation from "./Navigation/Navigation";
import { AppScope } from "../../types";
import "./Header.scss";

const HeaderWidgetGroup = ({ children }: { children: ReactNode }) => (
  <div className="Header-widget-group">{children}</div>
);

const HeaderWidget = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={clsx("Header-widget", className)}>{children}</div>;

const APP_SCOPE = (process.env.REACT_APP_SCOPE || "swap") as AppScope;

// Display network change only for swap
const activateNetwork = ["swap", "swap-optimism", "swap-arbitrum"].includes(
  APP_SCOPE
);

const activateWallet = APP_SCOPE !== "dashboard";

const Header = () => {
  return (
    <header className="Header">
      <HeaderWidgetGroup>
        <HeaderWidget>
          <div className="Header-logo" />
        </HeaderWidget>

        <HeaderWidget className="Header-widget-gauge">
          <RedeemableSupplyGauge />
        </HeaderWidget>
      </HeaderWidgetGroup>

      <Navigation className="Header-widget-group" />

      <HeaderWidgetGroup>
        {activateNetwork ? (
          <HeaderWidget>
            <Network />
          </HeaderWidget>
        ) : null}

        <HeaderWidget>
          <UXPCallToAction />
        </HeaderWidget>

        {activateWallet ? (
          <HeaderWidget>
            <Wallet />
          </HeaderWidget>
        ) : null}
      </HeaderWidgetGroup>
    </header>
  );
};

export default Header;
