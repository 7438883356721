import { ThunkDispatch as Dispatch } from "redux-thunk";

import { ACTION_TYPES } from "./constants";
import { Actions } from "./types";
import { getBalanceAction } from "./balance";
import { setLoadingAction } from "./loading";

import { State } from "../types";
import { RootState } from "../store";
import { CollateralMintName as SolanaCollateralMintName } from "../config/solana.types";
import { CollateralTokenName as OptimismCollateralTokenName } from "../config/optimism.types";
import { CollateralTokenName as ArbitrumCollateralTokenName } from "../config/arbitrum.types";
import { WalletAdapterName } from "../adapters/WalletAdapter";

export const setRedeemCollateralMintAction = (
  collateralMintName: SolanaCollateralMintName
) => ({
  type: ACTION_TYPES.SET_REDEEM_COLLATERAL_MINT,
  payload: {
    collateralMintName,
  },
});

export const setRedeemAmountsAction = (payload: {
  collateralAmount: string;
  redeemableAmount: string;
}) => ({
  type: ACTION_TYPES.SET_REDEEM_AMOUNTS,
  payload,
});

export const redeemAction =
  (
    redeemableAmount: string,
    collateralMintName:
      | SolanaCollateralMintName
      | OptimismCollateralTokenName
      | ArbitrumCollateralTokenName
  ) =>
  async (
    dispatch: Dispatch<
      State,
      void,
      Actions.SetLoading | Actions.RedeemSuccess | Actions.CreateNotification
    >,
    getState: () => RootState
  ): Promise<string> => {
    const walletAdapterName = ((state: State) => state.wallet)(getState());

    if (!walletAdapterName) {
      throw new Error("Missing wallet in `state.wallet`.");
    }

    const uiSlippage = ((state: RootState) => state.slippage)(getState());

    let redeemFunction: () => Promise<string> = () => {
      throw new Error("Unreachable");
    };
    switch (window.__UXD__.config.chain) {
      case "optimism": {
        const collateral = collateralMintName as OptimismCollateralTokenName;
        const collateralPrices = ((state: RootState) => state.collateralPrices)(
          getState()
        );
        const collateralPrice = collateralPrices[collateral];
        const { address, decimals: collateralDecimals } =
          window.__UXD__.optimism.config.tokens[collateral];

        redeemFunction = () =>
          window.__UXD__.optimism.services.UXD.redeem({
            redeemableAmount: Number(redeemableAmount),
            collateralPrice: Number(collateralPrice),
            slippage: Number(uiSlippage),
            collateral: address,
            collateralDecimals,
          });
        break;
      }
      case "arbitrum": {
        const collateral = collateralMintName as ArbitrumCollateralTokenName;
        const collateralPrices = ((state: RootState) => state.collateralPrices)(
          getState()
        );
        const collateralPrice = collateralPrices[collateral];
        const { address, decimals: collateralDecimals } =
          window.__UXD__.arbitrum.config.tokens[collateral];

        redeemFunction = () =>
          window.__UXD__.arbitrum.services.UXD.redeem({
            redeemableAmount: Number(redeemableAmount),
            collateralPrice: Number(collateralPrice),
            slippage: Number(uiSlippage),
            collateral: address,
            collateralDecimals,
          });
        break;
      }
      case "solana":
      default: {
        const walletAdapter = window.__UXD__.solana.config.getWalletAdapter(
          walletAdapterName as WalletAdapterName
        );

        redeemFunction = () =>
          window.__UXD__.solana.services.router.redeem(
            Number(redeemableAmount),
            walletAdapter
          );
      }
    }

    try {
      dispatch(setLoadingAction(true));

      const redeemTxId = await redeemFunction();

      dispatch({ type: ACTION_TYPES.REDEEM_SUCCESS });

      return redeemTxId;
    } catch (err) {
      throw err;
    } finally {
      dispatch(getBalanceAction(collateralMintName, "UXD"));
      dispatch(setLoadingAction(false));
    }
  };
