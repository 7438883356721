import { useRef } from "react";
import { useDispatch } from "react-redux";

import { setSlippage as _setSlippage } from "../actions/slippage";
import { useSelector } from "./hooks";

const useSlippage = () => {
  const dispatch = useDispatch();
  const { current: setSlippage } = useRef((slippage: string) => {
    dispatch(_setSlippage(slippage));
  });
  const slippage = useSelector((state) => state.slippage);

  return {
    slippage,
    setSlippage,
  };
};

export default useSlippage;
