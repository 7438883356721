import { toFixedWithoutTrailingZeros } from "../../../utils";
import "./Gauge.scss";

type Props = {
  actualValue: number;
  maximumValue: number | null;
  title: string;
};

function calculateMostAppropriateUnit(value: number) {
  return [
    {
      divisor: 1_000_000_000,
      symbol: "B",
    },
    {
      divisor: 1_000_000,
      symbol: "M",
    },
    {
      divisor: 1_000,
      symbol: "K",
    },
    {
      divisor: 1,
      symbol: "",
    },
  ].find((x) => value >= x.divisor);
}

function formatValues(actualValue: number, maximumValue: number | null) {
  const unit = calculateMostAppropriateUnit(maximumValue ?? actualValue);

  if (!unit) {
    return {
      formattedMaximumValue:
        maximumValue === null
          ? null
          : toFixedWithoutTrailingZeros(maximumValue, 2),
      formattedActualValue: toFixedWithoutTrailingZeros(actualValue, 2),
    };
  }

  return {
    formattedMaximumValue:
      maximumValue === null
        ? null
        : `${toFixedWithoutTrailingZeros(maximumValue / unit.divisor, 2)}${
            unit.symbol
          }`,
    formattedActualValue: `${toFixedWithoutTrailingZeros(
      actualValue / unit.divisor,
      2
    )}${unit.symbol}`,
  };
}

const Gauge = ({ actualValue, maximumValue, title }: Props) => {
  const { formattedActualValue, formattedMaximumValue } = formatValues(
    actualValue,
    maximumValue
  );

  const display =
    formattedMaximumValue === null
      ? formattedActualValue
      : `${formattedActualValue} / ${formattedMaximumValue}`;

  return (
    <div className="Gauge">
      <span>{display}</span>

      <span className="Gauge-title">{title}</span>
    </div>
  );
};

export default Gauge;
