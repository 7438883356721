import { TokenInfo } from "../../../config/optimism.types";
import { CLASS_NAME as COLLATERAL_FORM_CLASS_NAME } from "./CollateralForm";
import InputAmountOptimism from "../../common/input-amount/InputAmountOptimism";
import InputMaxButtonOptimism from "../../common/input-max-button/InputMaxButtonOptimism";

type Props = {
  id: string;
  name: string;
  label: string;
  value: string;
  handleChange: (amount: string) => void;
  maxButtonEnabled: boolean;
  connected: boolean;
  tokenInfo: TokenInfo;
};

const CollateralFormInput = ({
  id,
  name,
  label,
  value,
  handleChange,
  maxButtonEnabled,
  connected,
  tokenInfo,
}: Props) => {
  return (
    <>
      <span className={`${COLLATERAL_FORM_CLASS_NAME}-label-wrapper`}>
        <label htmlFor={id}>{label}</label>
        <InputMaxButtonOptimism
          activeToken={tokenInfo}
          handleClick={maxButtonEnabled ? handleChange : undefined}
        />
      </span>
      <InputAmountOptimism
        id={id}
        name={name}
        amount={value}
        handleChange={handleChange}
        reset={connected}
        token={tokenInfo}
      />
    </>
  );
};

export default CollateralFormInput;
