import { Connection } from "@solana/web3.js";
import { Controller, ControllerAccount } from "@uxd-protocol/uxd-client";
import { ISolanaConfiguration } from "../config/solana.types";
import { ABaseClientService } from "./baseClientService";
import { TXN_OPTS } from "./constants";

export class UXDClientService extends ABaseClientService {
  constructor(
    config: ISolanaConfiguration,
    connection: Connection,
    private controller: Controller
  ) {
    super(config, connection);
  }

  public static initialize = async (
    config: ISolanaConfiguration,
    connection: Connection
  ) => {
    const programAddress = config.getPrograms().uxd.address;

    const mints = config.getMints();

    const controller = new Controller(
      mints.UXD.name,
      mints.UXD.decimals,
      programAddress
    );

    return new UXDClientService(config, connection, controller);
  };

  public getController(): Controller {
    return this.controller;
  }

  public getControllerAccount(): Promise<ControllerAccount> {
    return this.controller.getOnchainAccount(this.connection, TXN_OPTS);
  }

  public getControllerRedeemableMintDecimals(): number {
    return this.controller.redeemableMintDecimals;
  }
}
