import * as icons from "./icons";

type SVGProps = React.SVGProps<SVGSVGElement> & { title?: string };
type SVGComponent = React.FunctionComponent<SVGProps>;
type Props = SVGProps & { name: IconName };

export type IconName =
  | "cog"
  | "cross"
  | "discord"
  | "gitbook"
  | "github"
  | "hourglass"
  | "info"
  | "solana"
  | "optimism"
  | "arbitrum"
  | "linkBroken"
  | "link"
  | "medium"
  | "twitter"
  | "SOL"
  | "USDC"
  | "UXD"
  | "UXDToken"
  | "UXPToken"
  | "alert";

export const Icon = ({ name, ...svgProps }: Props) => {
  const Component: SVGComponent = icons[name];

  if (!Component) {
    throw new Error(`Unable to find icon ${name}`);
  }

  return <Component height="1rem" width="auto" {...svgProps} />;
};
