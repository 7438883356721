import { ThunkDispatch as Dispatch } from "redux-thunk";
import { Actions } from "./types";
import { ACTION_TYPES } from "./constants";
import { State } from "../types";
import { CollateralMintName as SolanaCollateralMintName } from "../config/solana.types";
import { CollateralTokenName as OptimismCollateralTokenName } from "../config/optimism.types";
import { CollateralTokenName as ArbitrumCollateralTokenName } from "../config/arbitrum.types";

// Because all collateral used with solana are USDC-like, collateral price is always 1
function getSolanaCollateralPerpPrice() {
  return "1";
}

async function getOptimisimCollateralPerpPrice(
  collateral: OptimismCollateralTokenName
) {
  const price =
    await window.__UXD__.optimism.services.UXD.getCollateralPerpPriceUI(
      collateral
    );
  const value = price.toFixed(
    window.__UXD__.optimism.config.tokens.UXD.decimals
  );
  return value;
}

// Because all collateral used with arbitrum are USDC-like, collateral price is always 1
async function getArbitrumCollateralPerpPrice() {
  return "1";
}

export const getCollateralPerpPriceAction =
  (
    collateralMintName:
      | SolanaCollateralMintName
      | OptimismCollateralTokenName
      | ArbitrumCollateralTokenName
  ) =>
  async (
    dispatch: Dispatch<State, void, Actions.GetCollateralPerpPriceAction>
  ) => {
    let getPriceFunction = (): ReturnType<
      | typeof getOptimisimCollateralPerpPrice
      | typeof getSolanaCollateralPerpPrice
    > => {
      throw new Error("Unreachable");
    };
    switch (window.__UXD__.config.chain) {
      case "optimism": {
        const collateral = collateralMintName as OptimismCollateralTokenName;
        getPriceFunction = getOptimisimCollateralPerpPrice.bind(
          null,
          collateral
        );
        break;
      }
      case "arbitrum": {
        getPriceFunction = getArbitrumCollateralPerpPrice;
        break;
      }
      case "solana":
      default:
        getPriceFunction = getSolanaCollateralPerpPrice;
    }

    const price = await getPriceFunction();

    dispatch({
      type: ACTION_TYPES.SET_COLLATERAL_PRICE,
      payload: {
        [collateralMintName]: price,
      },
    });

    return price;
  };
