import logger from "../utils/logger";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getCollateralPerpPriceAction } from "../actions/price";
import { useSelector } from "./hooks";
import {
  calculateCollateralAmount,
  calculateRedeemableAmount,
} from "../solana/utils";
import usePeriodicFetch from "./usePeriodicFetch";
import { CollateralMintName } from "../config/solana.types";
import { SwapAction } from "../types";
import {
  setMintCollateralMintAction,
  setMintAmountsAction,
} from "../actions/mint";
import {
  setRedeemCollateralMintAction,
  setRedeemAmountsAction,
} from "../actions/redeem";

const FETCH_INTERVAL = 10_000;

const usePrepareSwap = (swapAction: SwapAction) => {
  const dispatch = useDispatch();

  const collateralPrices = useSelector((state) => state.collateralPrices);

  const { collateralMintName, collateralAmount, redeemableAmount } =
    useSelector((state) => (swapAction === "mint" ? state.mint : state.redeem));

  const getCollateralToRedeemableFeesInBps = (): number => {
    if (swapAction === "mint") {
      // When minting, value is positive because collateral >= redeemable
      return window.__UXD__.solana.services.router.getMintingFeeInBps();
    }
    // When redeeming, value is negative because collateral <= redeemable
    return -window.__UXD__.solana.services.router.getRedeemingFeeInBps();
  };

  const collateralToRedeemableFeesInBps = getCollateralToRedeemableFeesInBps();
  const redeemableToCollateralFeesInBps = -collateralToRedeemableFeesInBps;

  const { decimals: collateralMintDecimals } =
    window.__UXD__.solana.config.getCollateralMintInfo(collateralMintName);
  const { decimals: redeemableMintDecimals } =
    window.__UXD__.solana.config.getMintInfo("UXD");

  const setAmountsAction =
    swapAction === "mint" ? setMintAmountsAction : setRedeemAmountsAction;

  const onCollateralAmountChange = (amount: string) => {
    const collateralPerpUIPrice = collateralPrices[collateralMintName];

    // Ignore user input while Waiting for price to arrive ... shouldn't happen
    if (collateralPerpUIPrice === null) {
      logger.error(
        new Error(
          "collateralPerpUIPrice is null, ignoring onCollateralAmountChange ..."
        ),
        { collateralPrices, collateralMintName }
      );
      return;
    }

    dispatch(
      setAmountsAction({
        collateralAmount: amount,
        redeemableAmount:
          amount !== ""
            ? calculateRedeemableAmount({
                collateralAmount: Number(amount),
                collateralPerpUIPrice: Number(collateralPerpUIPrice),
                swapFees: collateralToRedeemableFeesInBps / 10_000,
                redeemableDecimals: redeemableMintDecimals,
              })
            : "",
      })
    );
  };

  const onRedeemableAmountChange = (redeemableAmount: string) => {
    const collateralPerpUIPrice = collateralPrices[collateralMintName];

    // Ignore user input while Waiting for price to arrive ... shouldn't happen
    if (collateralPerpUIPrice === null) {
      logger.error(
        new Error(
          "collateralPerpUIPrice is null, ignoring onRedeemableAmountChange ..."
        ),
        { collateralPrices, collateralMintName }
      );
      return;
    }

    dispatch(
      setAmountsAction({
        collateralAmount:
          redeemableAmount !== ""
            ? calculateCollateralAmount({
                redeemableAmount: Number(redeemableAmount),
                collateralPerpUIPrice: Number(collateralPerpUIPrice),
                swapFees: redeemableToCollateralFeesInBps / 10_000,
                collateralDecimals: collateralMintDecimals,
              })
            : "",
        redeemableAmount,
      })
    );
  };

  const onCollateralMintChange = (
    newCollateralMintName: CollateralMintName
  ) => {
    const redeemableAmountBefore = redeemableAmount;

    const { features: newCollateralMintFeatures } =
      window.__UXD__.solana.config.getCollateralMintInfo(newCollateralMintName);
    const mintEnabledForNewCollateralMint =
      newCollateralMintFeatures.includes("mint");
    if (mintEnabledForNewCollateralMint) {
      dispatch(setMintCollateralMintAction(newCollateralMintName));
    }
    const redeemEnabledForNewCollateralMint =
      newCollateralMintFeatures.includes("redeem");
    if (redeemEnabledForNewCollateralMint) {
      dispatch(setRedeemCollateralMintAction(newCollateralMintName));
    }

    // Setting the new collateral mint has reset redeemable / collateral amounts
    // Compute the new collateral amount based on the previous redeemable amount
    // This would also be cleaner using a Redux Middleware, but it's the only
    // place we do this today so it's still fine.
    if (Number(redeemableAmountBefore) !== 0) {
      Promise.resolve()
        .then(() =>
          dispatch(getCollateralPerpPriceAction(newCollateralMintName))
        )
        .then((newCollateralPerpUIPrice) => {
          dispatch(
            setAmountsAction({
              collateralAmount: calculateCollateralAmount({
                redeemableAmount: Number(redeemableAmountBefore),
                collateralPerpUIPrice: Number(newCollateralPerpUIPrice),
                swapFees: redeemableToCollateralFeesInBps / 10_000,
                collateralDecimals: collateralMintDecimals,
              }),

              redeemableAmount: String(redeemableAmount),
            })
          );
        });
    }
  };

  const fetchCollateralPerpPrice = useCallback(async () => {
    try {
      logger.debug("Fetching collateral perp price ...", {
        collateralMintName,
      });

      await dispatch(getCollateralPerpPriceAction(collateralMintName));
    } catch (err) {
      logger.error(err, {
        info: "Unable to fetch collateral perp price",
        collateralMintName,
      });
    }
  }, [collateralMintName, dispatch]);

  usePeriodicFetch(fetchCollateralPerpPrice, FETCH_INTERVAL);

  return {
    onCollateralMintChange,
    onCollateralAmountChange,
    onRedeemableAmountChange,
    collateralAmount,
    collateralMintName,
    redeemableAmount,
  };
};

export default usePrepareSwap;
