const SEC = 1;
const MIN = 60 * SEC;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;

export const ONE_YEAR = 365 * DAY;
export const ONE_SECOND = 1000;

export function secondsToLabel(seconds: number) {
  if (seconds / DAY > 1) {
    return `${seconds / DAY}D`;
  }

  if (seconds / HOUR > 1) {
    return `${seconds / HOUR}M`;
  }

  if (seconds / MIN > 1) {
    return `${seconds / MIN}M`;
  }

  return `${seconds / SEC}S`;
}
