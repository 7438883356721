import "./Options.scss";

export type Option = { value: string; label: string };

type Props = {
  options: Array<string | Option>;
  value: string;
  legend?: string;
  handleChange: (value: string) => void;
  name: string;
  children?: (value: string) => void;
};

const Options = ({
  options,
  value,
  handleChange,
  name,
  legend,
  children,
}: Props) => {
  return (
    <fieldset className="Options">
      {legend && (
        <div className="Options-legend">
          <legend>{legend}</legend>
          {children && children(value)}
        </div>
      )}
      <div className="Options-container">
        {options.map((option) => {
          const optionValue =
            typeof option === "string" ? option : option.value;
          const optionLabel =
            typeof option === "string" ? option : option.label;

          const id = `${name}-option-${optionValue}`;

          return (
            <div className="Options-option" key={id}>
              <input
                id={id}
                checked={optionValue === value}
                onChange={() => handleChange(optionValue)}
                value={optionValue}
                name={name}
                type="radio"
              />
              <label htmlFor={id}>{optionLabel}</label>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

export default Options;
