import { ReactNode } from "react";
import clsx from "clsx";
import { Icon, IconName } from "../icon/Icon";

import IconButton from "../icon-button/IconButton";
import { NotificationLevels } from "../../../actions/types";

import "./Notification.scss";

type Props = {
  level: NotificationLevels;
  title: string;
  message: string | ReactNode;
  icon?: IconName;
  details?: string;
  close: () => void;
};

const Notification = ({
  level,
  title,
  message,
  details,
  icon,
  close,
}: Props) => {
  const iconClassName = clsx([
    "Notification-title-icon",
    `Notification-title-icon--${level}`,
  ]);

  return (
    <div className="Notification" data-testid="Notification-testid">
      <div className="Notification-header">
        {icon && (
          <span
            className={iconClassName}
            data-testid="Notification-icon-testid"
          >
            <Icon name={icon} />
          </span>
        )}
        <h5>{title}</h5>
        <IconButton
          icon="cross"
          className="Notification-close-button"
          onClick={() => {
            close();
          }}
          type="button"
        />
      </div>
      <div className="Notification-inner">
        <p className="Notification-message">{message}</p>
        {details ? (
          <p className="Notification-details">
            {(() => {
              const formattingSplit = details.split("**");
              const hasFormatting = formattingSplit.length === 3;

              return (
                <>
                  {hasFormatting
                    ? formattingSplit.filter(Boolean).map((substr, idx) => {
                        const Wrap = idx === 1 ? "em" : "span";
                        return <Wrap key={substr + idx}>{substr}</Wrap>;
                      })
                    : details}
                </>
              );
            })()}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Notification;
