import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { IArbitrumConfiguration } from "../config/arbitrum.types";
import { UXDClientService } from "../arbitrum/uxdClientService";

async function initProvider(): Promise<MetaMaskInpageProvider | null> {
  // https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider
  const provider =
    ((await detectEthereumProvider({
      mustBeMetaMask: true,
    }).catch(() => {})) as unknown as MetaMaskInpageProvider | void) || null;

  return provider;
}

function initRpcProvider(config: IArbitrumConfiguration) {
  return new ethers.providers.JsonRpcProvider(config.rpcProviderUrl);
}

export default async function init(config: IArbitrumConfiguration) {
  const rpcProvider = initRpcProvider(config);
  const providerP = initProvider();
  const uxdClientServiceP = UXDClientService.initialize({
    config,
    rpcProvider,
    provider: providerP,
  });

  const [provider, uxdClientService] = await Promise.all([
    providerP,
    uxdClientServiceP,
  ]);

  return {
    config,
    provider,
    rpcProvider,
    services: {
      UXD: uxdClientService,
    },
  };
}
