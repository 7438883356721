import { Connection } from "@solana/web3.js";
import {
  UXDClient,
  CredixLpDepository,
  CredixLpDepositoryAccount,
} from "@uxd-protocol/uxd-client";
import { ISolanaConfiguration } from "../config/solana.types";
import { ABaseClientService } from "./baseClientService";
import { TXN_OPTS } from "./constants";

export class CredixClientService extends ABaseClientService {
  private client: UXDClient;
  private depository: CredixLpDepository;
  private onChainAccount: CredixLpDepositoryAccount;

  constructor(
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient,
    depository: CredixLpDepository,
    onChainAccount: CredixLpDepositoryAccount
  ) {
    super(config, connection);
    this.client = client;
    this.depository = depository;
    this.onChainAccount = onChainAccount;
  }

  public static initialize = async (
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient
  ): Promise<CredixClientService> => {
    const uxdProgramId = config.getPrograms().uxd.address;
    const credixProgramId = config.getPrograms().credix.address;
    const collateralInfo = config.getMintInfo("USDC_CREDIX");

    const depository = await CredixLpDepository.initialize({
      connection,
      uxdProgramId,
      collateralMint: collateralInfo.mint,
      collateralSymbol: collateralInfo.name,
      credixProgramId,
    });

    return new CredixClientService(
      config,
      connection,
      client,
      depository,
      // Load the account once at start. Useful to get minting/redeeming fee and else
      await depository.getOnchainAccount(connection, TXN_OPTS)
    );
  };

  public getCredixLpDepository(): CredixLpDepository {
    return this.depository;
  }

  public async getCredixLpDepositoryAccount(): Promise<CredixLpDepositoryAccount> {
    return this.depository.getOnchainAccount(this.connection, TXN_OPTS);
  }

  public getCredixLpDepositoryMintingFeeInBps(): number {
    return this.onChainAccount.mintingFeeInBps;
  }

  public getCredixLpDepositoryRedeemingFeeInBps(): number {
    return this.onChainAccount.redeemingFeeInBps;
  }
}
