import { SlippageOptions } from "../components/slippage/Slippage";
import { Contracts, IArbitrumConfiguration, Tokens } from "./arbitrum.types";
import { ClusterName } from "./types";
import AUXDArbitrumBaseConfig from "./uxd.arbitrum.base";

class UXDArbitrumTestnetConfig
  extends AUXDArbitrumBaseConfig
  implements IArbitrumConfiguration
{
  public readonly clusterName: ClusterName = "testnet";

  public readonly contracts: Contracts = {
    controller: {
      address: "0x9C572248f36263A0496F78bDA05102f9EcDb2802",
    },
  };

  public readonly defaultSlippage: SlippageOptions = "0.1";

  public readonly rpcProviderUrl =
    "https://arbitrum-goerli.infura.io/v3/1b07c06cf0494e879d4eaa6f555cbd7f";

  // Goerli Arbitrum
  public readonly chainId = 421_613;

  public readonly tokens: Tokens = {
    USDC_ARBITRUM: {
      name: "USDC_ARBITRUM",
      displayName: "USDC",
      decimals: 6,
      address: "0x6775842AE82BF2F0f987b10526768Ad89d79536E",
    },
    UXD: {
      name: "UXD",
      displayName: "UXD",
      decimals: 18,
      address: "0xAAFA462cfA89619CE72a0A5b9938Eb3Ab3166581",
    },
  };
}

const UXDArbitrumTestnetConfigInstance = new UXDArbitrumTestnetConfig();

export default UXDArbitrumTestnetConfigInstance;
