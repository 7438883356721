import { ReactNode } from "react";
import {
  CollateralMintInfo,
  CollateralMintName,
  MintInfo,
  MintName,
} from "../../../config/solana.types";
import { Feature } from "../../../config/types";
import Form, { Props as FormProps } from "../form/Form";
import "./CollateralForm.scss";

export type Props = Omit<FormProps, "children" | "className"> & {
  collateralMintName: CollateralMintName;
  redeemableMintName: MintName;
  feature: Feature;
  children: (props: RenderProps) => ReactNode;
};

type RenderProps = {
  collateral: MintInfo;
  redeemable: MintInfo;
  availableCollateralMints: Array<CollateralMintInfo>;
};

export const CLASS_NAME = "CollateralForm" as const;

const CollateralForm = ({
  collateralMintName,
  redeemableMintName,
  feature,
  children,
  ...formProps
}: Props) => {
  const collateral =
    window.__UXD__.solana.config.getMintInfo(collateralMintName);
  const redeemable =
    window.__UXD__.solana.config.getMintInfo(redeemableMintName);

  const knownCollateralMintInfos = Object.values(
    window.__UXD__.solana.config.getCollateralMints()
  );

  const uniqueCollateralMintKeys = new Set();
  const uniqueCollateralMintInfos = knownCollateralMintInfos.filter(
    ({ mint }) =>
      uniqueCollateralMintKeys.has(mint)
        ? false
        : uniqueCollateralMintKeys.add(mint)
  );

  const availableCollateralMints = uniqueCollateralMintInfos.filter(
    ({ features }) => features.includes(feature)
  );

  return (
    <Form className={CLASS_NAME} {...formProps}>
      {children({
        collateral,
        redeemable,
        availableCollateralMints,
      })}
    </Form>
  );
};

export default CollateralForm;
