import { useEffect, useState } from "react";
import {
  nativeToUi,
  CredixLpDepositoryAccount,
} from "@uxd-protocol/uxd-client";
import { DepositoryInfo } from "../types";

const getRedeemableAmount = (depositoryAccount: CredixLpDepositoryAccount) =>
  Math.round(
    nativeToUi(
      depositoryAccount.redeemableAmountUnderManagement,
      window.__UXD__.solana.services.UXD.getControllerRedeemableMintDecimals()
    )
  );

const useCredixLpDepositoryInfo = () => {
  const [depositoryInfo, setDepositoryInfo] = useState<DepositoryInfo | null>(
    null
  );

  const depository =
    window.__UXD__.solana.services.credix.getCredixLpDepository();

  useEffect(() => {
    const fetchDepositoryInfo = async (): Promise<DepositoryInfo> => {
      const credixLpDepositoryAccount =
        await window.__UXD__.solana.services.credix.getCredixLpDepositoryAccount();

      const redeemableAmount = getRedeemableAmount(credixLpDepositoryAccount);

      return {
        collateral: "USDC",
        redeemableAmount,
      };
    };

    fetchDepositoryInfo().then((info) => setDepositoryInfo(info));
  }, [depository]);

  return depositoryInfo;
};

export default useCredixLpDepositoryInfo;
