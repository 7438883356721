import { Actions, NotificationLevels } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";
import { ReactNode } from "react";
import { IconName } from "../components/common/icon/Icon";

export type NotificationState = {
  level: NotificationLevels;
  title: string;
  message: string | ReactNode;
  icon?: IconName;
  details?: string;
} | null;

const INITIAL_STATE = null;

const notificationReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): NotificationState => {
  switch (action.type) {
    case ACTION_TYPES.CREATE_NOTIFICATION:
      return action.payload;

    case ACTION_TYPES.DELETE_NOTIFICATION:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default notificationReducer;
