import clsx from "clsx";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { mintAction, setMintAmountsAction } from "../../actions/mint";
import { getCollateralPerpPriceAction } from "../../actions/price";
import { redeemAction, setRedeemAmountsAction } from "../../actions/redeem";
import { useSelector } from "../../hooks/hooks";
import usePeriodicFetch from "../../hooks/usePeriodicFetch";
import {
  calculateCollateralAmount,
  calculateMinimumReceived,
  calculateRedeemableAmount,
} from "../../solana/utils";
import { SwapAction } from "../../types";
import logger from "../../utils/logger";
import TabButton from "../common/tab-button/TabButton";
import FromCollateralForm from "./collateral-form/FromCollateralForm";
import ToCollateralForm from "./collateral-form/ToCollateralForm";
import transactionLink from "../common/transaction-link/TransactionLink";
import Settings, { Row as SettingsRow } from "../settings/Settings";
import { TokenName } from "../../config/arbitrum.types";
import { RootState } from "../../store";
import SwapButton from "../common/swap-button/SwapButton";
import "./SwapArbitrum.scss";
import { createNotificationAction } from "../../actions/notification";
import { getDisplayMessageFromError } from "../../utils/error";
import { connectWalletAction } from "../../actions/wallet";
import useCheckWalletEvmChain from "../../hooks/useCheckWalletEvmChain";
import { Icon } from "../common/icon/Icon";
import Button from "../common/button/Button";

type Tab = SwapAction;

const COLLATERAL = "USDC_ARBITRUM";
const FETCH_INTERVAL = 10_000;
const SWAP_FEES_IN_BPS = 1;

const TABS = [
  { name: "mint", label: "Mint", component: FromCollateralForm },
  { name: "redeem", label: "Redeem", component: ToCollateralForm },
] as const;

const EMPTY_VALUE_PLACEHOLDER = " - ";

function formatAmount(amount: number, displayDecimals: number) {
  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: displayDecimals,
  });
}

// FIXME: should probably belong to mint configuration instead
// and also be used in redeemable / collateral input values.
function getDisplayDecimals(token: TokenName) {
  switch (token) {
    case "UXD":
      return 2;
    case "USDC_ARBITRUM":
      return 6;
    default:
      // @ts-ignore should never happens
      return window.__UXD__.arbitrum.config.tokens[token].decimals;
  }
}

function getDisplayPrice(price: string | null, collateralMintName: string) {
  if (!price) return EMPTY_VALUE_PLACEHOLDER;

  return `${formatAmount(
    Number(price),
    getDisplayDecimals("UXD")
  )} UXD per ${collateralMintName}`;
}

function getDisplayMinimumReceived(
  minimumReceived: number,
  collateralTokenName: typeof COLLATERAL,
  action: SwapAction
) {
  if (!minimumReceived) return EMPTY_VALUE_PLACEHOLDER;

  const counterpartMint = action === "redeem" ? collateralTokenName : "UXD";

  return `${formatAmount(
    minimumReceived,
    getDisplayDecimals(counterpartMint)
  )} ${counterpartMint}`;
}

const AMOUNT_SELECTORS = {
  mint: (state: RootState) => state.mint.redeemableAmount,
  redeem: (state: RootState) => state.redeem.collateralAmount,
} as const;

const Swap = () => {
  const loading = useSelector((state) => state.loading);
  const [activeTab, setActiveTab] = useState<Tab>("mint");
  const walletChainState = useCheckWalletEvmChain();

  const connected = !!useSelector(
    (state) => typeof state.wallet === "object" && !!state.wallet?.account
  );

  const dispatch = useDispatch();

  const collateralPrices = useSelector((state) => state.collateralPrices);
  const price = collateralPrices[COLLATERAL];

  const { decimals: collateralTokenDecimals, displayName } =
    window.__UXD__.arbitrum.config.tokens[COLLATERAL];

  const displayPrice = getDisplayPrice(price, displayName);
  const swapFeesPercentage = (SWAP_FEES_IN_BPS / 100).toFixed(2);
  const displaySwapFeesPercentage = `${swapFeesPercentage}%`;

  const { decimals: redeemableTokenDecimals } =
    window.__UXD__.arbitrum.config.tokens.UXD;

  const slippage = Number(useSelector((state) => state.slippage)) / 100;
  const amount = Number(useSelector(AMOUNT_SELECTORS[activeTab]));

  const minimumReceived = Number.parseFloat(
    calculateMinimumReceived({
      amount,
      slippage,
      decimals: collateralTokenDecimals,
    })
  );

  const displayMinimumReceived = getDisplayMinimumReceived(
    minimumReceived,
    COLLATERAL,
    activeTab
  );

  const { collateralAmount, redeemableAmount } = useSelector((state) =>
    activeTab === "mint" ? state.mint : state.redeem
  );

  const setAmountsAction =
    activeTab === "mint" ? setMintAmountsAction : setRedeemAmountsAction;

  const onCollateralAmountChange = (amount: string) => {
    // Ignore user input while Waiting for price to arrive ... shouldn't happen
    if (price === null) {
      logger.error(
        new Error(
          "collateralPerpUIPrice is null, ignoring onCollateralAmountChange ..."
        ),
        { collateralPrices }
      );
      return;
    }

    dispatch(
      setAmountsAction({
        collateralAmount: amount,
        redeemableAmount:
          amount !== ""
            ? calculateRedeemableAmount({
                collateralAmount: Number(amount),
                collateralPerpUIPrice: Number(price),
                swapFees: SWAP_FEES_IN_BPS / 10_000,
                redeemableDecimals: redeemableTokenDecimals,
              })
            : "",
      })
    );
  };

  const onRedeemableAmountChange = (redeemableAmount: string) => {
    // Ignore user input while Waiting for price to arrive ... shouldn't happen
    if (price === null) {
      logger.error(
        new Error(
          "collateralPerpUIPrice is null, ignoring onRedeemableAmountChange ..."
        ),
        { collateralPrices }
      );
      return;
    }

    dispatch(
      setAmountsAction({
        collateralAmount:
          redeemableAmount !== ""
            ? calculateCollateralAmount({
                redeemableAmount: Number(redeemableAmount),
                collateralPerpUIPrice: Number(price),
                swapFees: SWAP_FEES_IN_BPS / 10_000,
                collateralDecimals: collateralTokenDecimals,
              })
            : "",
        redeemableAmount,
      })
    );
  };

  const fetchCollateralPerpPrice = useCallback(async () => {
    try {
      logger.debug("Fetching collateral perp price ...");

      await dispatch(getCollateralPerpPriceAction(COLLATERAL));
    } catch (err) {
      logger.error(err, {
        info: "Unable to fetch collateral perp price",
      });
    }
  }, [dispatch]);

  usePeriodicFetch(fetchCollateralPerpPrice, FETCH_INTERVAL);

  const isActive = (tab: Tab) => activeTab === tab;

  const activeTabObject = TABS.find(({ name }) =>
    isActive(name)
  ) as (typeof TABS)[number];

  const SwapContent = activeTabObject.component;

  const getTabButtonClassName = (tab: Tab) =>
    clsx({
      "SwapArbitrum-tab": true,
      [`SwapArbitrum-tab-${tab}`]: true,
      "SwapArbitrum-tab-active": isActive(tab),
    });

  const formId = `swap-form--${activeTab}`;

  const handleSubmit = useCallback(async () => {
    switch (activeTab) {
      case "mint": {
        try {
          const txId = await dispatch(mintAction(collateralAmount, COLLATERAL));

          dispatch(
            createNotificationAction({
              title: "Mint Successful",
              message: transactionLink({
                message: "Your mint transaction was a success",
                transaction: txId as unknown as string,
                cluster: window.__UXD__.arbitrum.config.getClusterName(),
                chain: window.__UXD__.arbitrum.config.chain,
              }),
              level: "success",
              icon: "info",
            })
          );
        } catch (err) {
          logger.error(err);

          dispatch(
            createNotificationAction({
              title: "Mint Error",
              message: "Transaction Failed",
              details: getDisplayMessageFromError(err),
              level: "error",
              icon: "info",
            })
          );
        }
        break;
      }
      case "redeem": {
        try {
          const txId = await dispatch(
            redeemAction(redeemableAmount, COLLATERAL)
          );

          dispatch(
            createNotificationAction({
              title: "Redeem Successful",
              message: transactionLink({
                message: "Your redeem transaction was a success",
                transaction: txId as unknown as string,
                cluster: window.__UXD__.arbitrum.config.getClusterName(),
                chain: window.__UXD__.arbitrum.config.chain,
              }),
              level: "success",
              icon: "info",
            })
          );
        } catch (err) {
          logger.error(err);

          dispatch(
            createNotificationAction({
              title: "Redeem Error",
              message: "Transaction Failed",
              details: getDisplayMessageFromError(err),
              level: "error",
              icon: "info",
            })
          );
        }
        break;
      }
      default:
        throw new Error("Unreachable");
    }
  }, [activeTab, redeemableAmount, collateralAmount, dispatch]);

  const switchToTargetChain = async () => {
    if (!walletChainState || walletChainState === true) {
      return;
    }

    const {
      provider,
      config: { rpcProviderUrl },
    } = window.__UXD__[window.__UXD__.config.chain] as any;

    const chainId = `0x${walletChainState.configurationTargetChainId.toString(
      16
    )}`;

    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId,
          },
        ],
      });
    } catch (e) {
      // This error code indicates that the chain has not been added to MetaMask.
      if ((e as any).code === 4902) {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId,
              chainName: window.__UXD__.config.chain,
              rpcUrls: [rpcProviderUrl],
            },
          ],
        });
      }
    }
  };

  return (
    <main className="SwapArbitrum">
      {walletChainState && walletChainState !== true ? (
        <div className="SwapArbitrum-warning">
          <Icon name="alert" />
          <span>
            Please switch to {walletChainState.configurationTargetChainName}{" "}
            network
          </span>
          <Button type="button" onClick={switchToTargetChain}>
            Switch
          </Button>
        </div>
      ) : null}

      <div className="SwapArbitrum-inner">
        <nav className="SwapArbitrum-tabs">
          {TABS.map(({ name: tab, label }) => (
            <TabButton
              key={`SwapArbitrum-tab-${tab}`}
              label={label}
              className={getTabButtonClassName(tab)}
              handleClick={() => setActiveTab(tab)}
              disabled={false}
            />
          ))}
        </nav>
        <div className="SwapArbitrum-content">
          <SwapContent
            id={formId}
            onCollateralAmountChange={onCollateralAmountChange}
            onRedeemableAmountChange={onRedeemableAmountChange}
            collateralAmount={collateralAmount}
            redeemableAmount={redeemableAmount}
            connected={connected}
            handleSubmit={handleSubmit}
          />
          <Settings displayPrice={displayPrice}>
            <SettingsRow
              label="Minimum Received"
              value={displayMinimumReceived}
            />
            <SettingsRow label="Swap Fee" value={displaySwapFeesPercentage} />
          </Settings>
        </div>
      </div>
      <SwapButton
        disabled={walletChainState && walletChainState !== true}
        action={activeTab}
        connected={connected}
        valid={Number(collateralAmount) > 0 && Number(redeemableAmount) > 0}
        loading={loading}
        formId={formId}
        handleClick={() => {
          dispatch(connectWalletAction("metamask"));
        }}
      />
    </main>
  );
};

export default Swap;
