import "./ExternalLink.scss";

const ExternalLink = ({
  name,
  href,
  title,
}: {
  name: string;
  href: string;
  title?: string;
}) => (
  <a
    className="ExternalLink"
    href={href}
    title={title}
    rel="external noreferrer"
    target="_blank"
  >
    {name}
  </a>
);

export default ExternalLink;
