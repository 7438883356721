import { useCallback, useState } from "react";
import { nativeToUi } from "../utils/amount";
import logger from "../utils/logger";
import usePeriodicFetch from "./usePeriodicFetch";

const FETCH_INTERVAL = 10_000;

const fetchUnsupportedSupply = (): ReturnType<
  | typeof fetchSolanaSupply
  | typeof fetchOptimismSupply
  | typeof fetchArbitrumSupply
> => {
  throw new Error("Unreachable");
};

async function fetchSolanaSupply() {
  try {
    const {
      redeemableCirculatingSupply,
      redeemableGlobalSupplyCap,
      redeemableMintDecimals,
    } = await window.__UXD__.solana.services.UXD.getControllerAccount();

    const redeemableCirculatingSupplyUI = nativeToUi(
      redeemableCirculatingSupply,
      redeemableMintDecimals
    );
    const redeemableGlobalSupplyCapUI = nativeToUi(
      redeemableGlobalSupplyCap,
      redeemableMintDecimals
    );

    logger.debug("Fetched redeemable supply", {
      native: {
        redeemableCirculatingSupply: redeemableCirculatingSupply.toString(),
        redeemableGlobalSupplyCap: redeemableGlobalSupplyCap.toString(),
      },
      ui: {
        redeemableCirculatingSupplyUI,
        redeemableGlobalSupplyCapUI,
      },
    });

    return {
      redeemableCirculatingSupply: redeemableCirculatingSupplyUI,
      redeemableGlobalSupplyCap: redeemableGlobalSupplyCapUI,
    };
  } catch (err) {
    logger.error(new Error("Unable to fetch redeemable supply"), { err });
    return null;
  }
}

// @TODO
async function fetchOptimismSupply() {
  return null;
}

// @TODO
async function fetchArbitrumSupply() {
  return null;
}

const useRedeemableSupply = () => {
  const [redeemableSupply, setRedeemableSupply] = useState<{
    redeemableCirculatingSupply: number;
    redeemableGlobalSupplyCap: number;
  } | null>(null);

  let fetchSupplyFunction = fetchUnsupportedSupply;
  switch (window.__UXD__.config.chain) {
    case "optimism":
      fetchSupplyFunction = fetchOptimismSupply;
      break;
    case "arbitrum":
      fetchSupplyFunction = fetchArbitrumSupply;
      break;
    case "solana":
    default:
      fetchSupplyFunction = fetchSolanaSupply;
  }

  const fetchSupply = useCallback(async () => {
    const supply = await fetchSupplyFunction();
    setRedeemableSupply(supply);
  }, [fetchSupplyFunction]);

  usePeriodicFetch(fetchSupply, FETCH_INTERVAL);

  return redeemableSupply;
};

export default useRedeemableSupply;
