import { useMemo } from "react";
import { StakingOptionUI, State } from "../../types";
import { useSelector } from "../../hooks/hooks";
import useStakingAccounts from "../../hooks/useStakingAccounts";
import StakingPositions from "./staking-positions/StakingPositions";
import StakingForm from "./staking-form/StakingForm";

import "./Staking.scss";
import { WalletAdapterName } from "../../adapters/WalletAdapter";

const Staking = () => {
  const options = useMemo<Array<StakingOptionUI>>(() => {
    return window.__UXD__.solana.services.staking
      .getStakingOptions()
      .map(({ identifier, lockupSecs, apr, active }) => ({
        active,
        identifier: identifier.toString(),
        lockupSecs: lockupSecs.toNumber(),
        apr: apr.toNumber(),
      }));
  }, []);

  const walletAdapterName = useSelector((state: State) => state.wallet);
  const user =
    walletAdapterName &&
    window.__UXD__.solana.config.getWalletAdapter(
      walletAdapterName as WalletAdapterName
    ).adapter.publicKey;

  const { accounts, signalAccountsFetch, status } = useStakingAccounts(user);

  if (!options.length) {
    return null;
  }

  const activeOptions = options.filter(({ active }) => active);

  return (
    <main className="Staking">
      <StakingPositions
        options={options}
        accounts={accounts}
        status={status}
        signalAccountsFetch={signalAccountsFetch}
        walletAdapterName={walletAdapterName}
      />

      <StakingForm
        options={activeOptions}
        accounts={accounts}
        status={status}
        walletAdapterName={walletAdapterName}
        signalAccountsFetch={signalAccountsFetch}
      />
    </main>
  );
};

export default Staking;
