export const leadingZero = new RegExp("^0+(?!$)", "g");

export const sanitizeDots = (str: string): string =>
  str.replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, ""));

export const sanitizeValue = (
  value: string | number,
  decimals: number = 6
): string => {
  let _value = String(value);
  if (!_value?.length) return "";
  _value = _value.replaceAll(leadingZero, "");
  _value = sanitizeDots(_value.replace(",", ".").replace(/[^0-9.]/g, ""));
  if (_value.startsWith(".")) _value = "0" + _value;
  if (!_value.endsWith(".")) {
    if (_value.indexOf(".") >= 0)
      _value =
        _value.substr(0, _value.indexOf(".")) +
        _value.substr(_value.indexOf("."), decimals + 1);
  }

  return _value;
};

export const toFixedWithoutTrailingZeros = (
  number: number,
  decimals: number
): string => {
  const hardAccuracyLimitDigit = 16;
  const numberOfUsefulDigits = Math.min(hardAccuracyLimitDigit, decimals);
  return parseFloat(number.toFixed(numberOfUsefulDigits)).toLocaleString(
    "en-US",
    {
      useGrouping: false,

      // seems that we will never get more digits in a number
      maximumSignificantDigits: hardAccuracyLimitDigit,
    }
  );
};

// minATARentExemptAmount * 2 + txFee
export const MAX_POSSIBLE_TX_FEE = 0.00204 * 2 + 0.00005;
