import useRedeemableSupply from "../../hooks/useRedeemableSupply";
import Gauge from "../common/gauge/Gauge";

const RedeemableSupplyGauge = () => {
  const redeemableSupply = useRedeemableSupply();

  if (redeemableSupply === null) {
    return null;
  }

  const { redeemableCirculatingSupply, redeemableGlobalSupplyCap } =
    redeemableSupply;

  const hideGlobalSupplyCap = window.__UXD__.config.chain === "solana";

  return (
    <Gauge
      actualValue={redeemableCirculatingSupply}
      maximumValue={hideGlobalSupplyCap ? null : redeemableGlobalSupplyCap}
      title={hideGlobalSupplyCap ? "circ. supply" : "supply cap"}
    />
  );
};

export default RedeemableSupplyGauge;
