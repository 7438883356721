import { useEffect, useRef } from "react";

type FetchFunc = () => Promise<void>;

const usePeriodicFetch = (fetch: FetchFunc | null, interval: number) => {
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchPeriodically = async () => {
      const timeout = timeoutIdRef.current;
      if (typeof fetch === "function") {
        await fetch();
      }
      // continue the loop only if we're still in the same loop.
      // the timeoutId will be different if the effect has been cleaned
      // due to a dependency change.
      if (timeout === timeoutIdRef.current) {
        timeoutIdRef.current = setTimeout(() => {
          fetchPeriodically();
        }, interval);
      }
    };

    fetchPeriodically();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [fetch, interval]);
};

export default usePeriodicFetch;
