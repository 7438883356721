import { ReactNode, useCallback } from "react";
import { useDispatch } from "react-redux";
import { StakingAccount } from "@uxdprotocol/uxd-staking-client";

import { StakingOptionUI } from "../../../types";
import { Status } from "../../../hooks/useStakingAccounts";
import { WalletState } from "../../../reducers/walletReducer";
import { createNotificationAction } from "../../../actions/notification";
import { getDisplayMessageFromError } from "../../../utils/error";
import logger from "../../../utils/logger";
import PositionRow from "./PositionRow";
import transactionLink from "../../common/transaction-link/TransactionLink";
import Button from "../../common/button/Button";

import "./StakingPositions.scss";
import { WalletAdapterName } from "../../../adapters/WalletAdapter";

type Props = {
  options: StakingOptionUI[];
  accounts: StakingAccount[];
  status: Status;
  signalAccountsFetch: () => void;
  walletAdapterName: WalletState;
};

const Wrapper = ({
  children,
  footer,
  callToAction,
}: {
  children?: ReactNode;
  footer?: ReactNode;
  callToAction?: ReactNode;
}) => {
  return (
    <section className="StakingPositions">
      <div className="StakingPositions-header">
        <h3>Staking Positions</h3>
        {callToAction}
      </div>

      <div className="StakingPositions-content">{children ?? null}</div>
      {footer && <div className="StakingPositions-footer">{footer}</div>}
    </section>
  );
};

const StakingPositions = ({
  options,
  accounts,
  status,
  walletAdapterName,
  signalAccountsFetch,
}: Props) => {
  const dispatch = useDispatch();
  const connected = !!walletAdapterName;
  const UXP = window.__UXD__.solana.config.getMintInfo("UXP");
  const stakingAccountsRequiringMigrationFromV1ToV2 =
    window.__UXD__.solana.services.staking.getStakingAccountsRequiringMigrationFromV1ToV2(
      accounts
    );

  const handleClaimingVotingPowerClick = useCallback(async () => {
    if (!walletAdapterName) {
      return;
    }

    const walletAdapter = window.__UXD__.solana.config.getWalletAdapter(
      walletAdapterName as WalletAdapterName
    );

    try {
      const txId =
        await window.__UXD__.solana.services.staking.migrateUserStakingAccountsFromV1ToV2(
          walletAdapter
        );

      dispatch(
        createNotificationAction({
          title: "Claim Voting Power Successful",
          message: transactionLink({
            message: "Your claim voting power transaction was a success",
            transaction: txId as unknown as string,
            cluster: window.__UXD__.solana.config.getClusterName(),
          }),
          level: "success",
          icon: "info",
        })
      );
    } catch (err) {
      logger.error(err);

      dispatch(
        createNotificationAction({
          title: "Claim Voting Power Error",
          message: "Transaction Failed",
          details: getDisplayMessageFromError(err),
          level: "error",
          icon: "info",
        })
      );
    } finally {
      signalAccountsFetch();
    }
  }, [walletAdapterName, dispatch, signalAccountsFetch]);

  const handleClaimClick = useCallback(
    async (option: string) => {
      if (!walletAdapterName) {
        return;
      }

      const walletAdapter = window.__UXD__.solana.config.getWalletAdapter(
        walletAdapterName as WalletAdapterName
      );

      try {
        const txId = await window.__UXD__.solana.services.staking.unstake({
          option: Number(option),
          walletAdapter,
        });

        dispatch(
          createNotificationAction({
            title: "Claim Successful",
            message: transactionLink({
              message: "Your claim transaction was a success",
              transaction: txId,
              cluster: window.__UXD__.solana.config.getClusterName(),
            }),
            level: "success",
            icon: "info",
          })
        );
      } catch (err) {
        logger.error(err);

        dispatch(
          createNotificationAction({
            title: "Claim Error",
            message: "Transaction Failed",
            details: getDisplayMessageFromError(err),
            level: "error",
            icon: "info",
          })
        );
      } finally {
        signalAccountsFetch();
      }
    },
    [walletAdapterName, dispatch, signalAccountsFetch]
  );

  if (status === Status.ERRORED) {
    return (
      <Wrapper>
        <p>Unable to retrieve staking positions at this time.</p>
      </Wrapper>
    );
  }

  if (accounts.length) {
    const callToAction =
      stakingAccountsRequiringMigrationFromV1ToV2.length > 0 ? (
        <Button type="button" onClick={handleClaimingVotingPowerClick}>
          Claim Voting Power
        </Button>
      ) : null;

    return (
      <Wrapper
        footer={
          accounts.length > 0 && (
            <p>
              Claiming rewards will automatically unstake your locked position
              as well
            </p>
          )
        }
        callToAction={callToAction}
      >
        <table>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>Staked</th>
              <th>Locked</th>
              <th>Unlocks On</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account) => {
              return (
                <PositionRow
                  key={account.stakingOptionIdentifier.toString()}
                  connected={connected}
                  account={account}
                  options={options}
                  UXP={UXP}
                  handleClick={handleClaimClick}
                />
              );
            })}
          </tbody>
        </table>
      </Wrapper>
    );
  }

  if (status === Status.LOADING && !accounts.length) {
    return (
      <Wrapper>
        <p>Loading ...</p>
      </Wrapper>
    );
  }

  if (status === Status.LOADED && !accounts.length) {
    return (
      <Wrapper>
        <p>No staking positions yet.</p>
      </Wrapper>
    );
  }

  if (!connected) {
    return (
      <Wrapper>
        <p>Connect your wallet to see your staking positions.</p>
      </Wrapper>
    );
  }

  if (status === Status.INITIAL && !accounts.length) {
    return <Wrapper />;
  }

  return null;
};

export default StakingPositions;
