import { PublicKey } from "@solana/web3.js";
import { USDC_DECIMALS, UXD_DECIMALS } from "@uxd-protocol/uxd-client";
import { SlippageOptions } from "../components/slippage/Slippage";
import ClusterRPCEndpoint from "../solana/clusterRPCEndpoint";
import {
  ISolanaConfiguration,
  ClusterRPCEndpoints,
  CollateralMints,
  CounterpartMints,
  OtherMints,
} from "./solana.types";
import { ClusterName, Programs } from "./types";
import AUXDSolanaBaseConfig from "./uxd.solana.base";

const UXP_DECIMALS = 9;

const USDC_INFO = {
  mint: new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"),
  decimals: USDC_DECIMALS,
};

const PRIMARY_RPC_ENDPOINTS = [
  // TO DELETE (DEV ONLY)
  new ClusterRPCEndpoint(
    "https://realms-realms-c335.mainnet.rpcpool.com/258d3727-bb96-409d-abea-0b1b4c48af29/"
  ),
  new ClusterRPCEndpoint(process.env.REACT_APP_QUICKNODE_RPC_ENDPOINT ?? ""),
];

const SECONDARY_RPC_ENDPOINTS = [
  new ClusterRPCEndpoint("https://api.mainnet-beta.solana.com"),
  new ClusterRPCEndpoint("https://solana-api.projectserum.com"),
];

class UXDMainnetConfig
  extends AUXDSolanaBaseConfig
  implements ISolanaConfiguration
{
  // LET IT EMPTY FOR NOW SO IT CAN'T BE USED ON MAINNET
  protected governanceRealmAccountPubkey = null as unknown as PublicKey;
  protected governanceProgramPubkey = null as unknown as PublicKey;

  protected collateralMints: CollateralMints = {
    USDC_IDENTITY: {
      ...USDC_INFO,
      name: "USDC_IDENTITY",
      displayName: "USDC",
      features: ["mint", "redeem"],
    },
    USDC_MERCURIAL: {
      ...USDC_INFO,
      name: "USDC_MERCURIAL",
      displayName: "USDC",
      features: ["mint", "redeem"],
    },
    USDC_CREDIX: {
      ...USDC_INFO,
      name: "USDC_CREDIX",
      displayName: "USDC",
      features: ["mint", "redeem"],
    },
  };

  protected counterpartMints: CounterpartMints = {
    USDC: {
      ...USDC_INFO,
      name: "USDC",
    },
    UXD: {
      mint: new PublicKey("7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT"),
      // mint: new PublicKey("9BxgG2CqDL4kqERLP54N4vxEj7fygp6jtst8JFy4DfTK"),
      decimals: UXD_DECIMALS,
      name: "UXD",
    },
  };

  protected otherMints: OtherMints = {
    UXP: {
      mint: new PublicKey("UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M"),
      decimals: UXP_DECIMALS,
      name: "UXP",
    },
  };

  protected programs: Programs = {
    uxd: {
      // address: new PublicKey("EmXCGBmeZ7vTZu1NcuR5Cod8438aQdghhVa69zcBVF23"),
      address: new PublicKey("UXD8m9cvwk4RcSxnX2HZ9VudQCEeDH6fRnB4CAP57Dr"),
    },
    staking: {
      address: new PublicKey("UXDSkps5NR8Lu1HB5uPLFfuB34hZ6DCk7RhYZZtGzbF"),
    },
    credix: {
      address: new PublicKey("CRDx2YkdtYtGZXGHZ59wNv1EwKHQndnRc1gT4p8i2vPX"),
    },
  };

  protected clusterRPCEndpoints: ClusterRPCEndpoints = [
    ...PRIMARY_RPC_ENDPOINTS,
    ...SECONDARY_RPC_ENDPOINTS,
  ];

  protected stakingCampaign: PublicKey = new PublicKey(
    "GMkG1Xr1ZAtLbHRxfbqLFEHqjP7rGwEfhQFed41aEL1k"
  );

  protected defaultSlippage: SlippageOptions = "0.5";

  protected clusterName: ClusterName = "mainnet-beta";

  protected mercurialCollateralMint = {
    mint: new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"),
    decimals: USDC_DECIMALS,
    symbol: "USDC" as const,
    name: "USDC" as const,
  };
}

const UXDMainnetConfigInstance = new UXDMainnetConfig();

export default UXDMainnetConfigInstance;
