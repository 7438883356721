import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import "./Dropdown.scss";
import { Icon, IconName } from "../icon/Icon";

export type TOption = {
  name: string;
  label: string;
  value: string;
  icon?: IconName;
  href?: string;
};

type Props = {
  name: string;
  value: string;
  options: TOption[];
  standalone?: boolean;
  handleChange?: (value: string) => void;
  children?: ReactNode;
};

const Anchor = ({
  children,
  open,
  handleClick,
}: {
  children: ReactNode;
  open: boolean;
  handleClick?: () => void;
}) => {
  const className = clsx("Dropdown-anchor", {
    "Dropdown-anchor--open": open,
  });
  return (
    <button type="button" className={className} onClick={handleClick}>
      {children}
    </button>
  );
};

const Option = ({
  children,
  active,
  icon,
  href,
  handleClick,
}: {
  children: ReactNode;
  active: boolean;
  icon: IconName | void;
  href: string | void;
  handleClick: () => void;
}) => {
  const iconNode = icon ? <Icon name={icon} height="2rem" /> : null;
  const className = icon
    ? "Dropdown-option Dropdown-option--with-icon"
    : "Dropdown-option";

  if (href && !active) {
    return (
      <a
        className={className}
        title={typeof children === "string" ? children : undefined}
        href={href}
      >
        {iconNode}
        <span>{children}</span>
      </a>
    );
  }

  return (
    <button
      type="button"
      className={className}
      disabled={active}
      title={typeof children === "string" ? children : undefined}
      onClick={handleClick}
    >
      {iconNode}
      <span>{children}</span>
    </button>
  );
};

const Content = ({ children }: { children: ReactNode }) => {
  return <div className="Dropdown-content">{children}</div>;
};

const ESCAPE_KEYS = ["Esc", "Escape"];

const Dropdown = ({
  name,
  value,
  options,
  handleChange,
  standalone = true,
  children = null,
}: Props) => {
  const [open, setOpen] = useState(false);

  const id = `Dropdown--${name}--${value}`;

  const { current: handleKeyDown } = useRef((evt: KeyboardEvent) => {
    if (ESCAPE_KEYS.includes(evt.key)) {
      setOpen(false);
    }
  });

  const handleMouseDown = useCallback(
    (evt: MouseEvent) => {
      const container = document.getElementById(id);
      const target = evt.target as Node;
      const self = container === target;
      const contained = container?.contains(target);

      if (!self && !contained) {
        setOpen(false);
      }
    },
    [id]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleKeyDown, handleMouseDown]);

  const className = standalone ? "Dropdown Dropdown-standalone" : "Dropdown";

  return (
    <div className={className} id={id}>
      <Anchor open={open} handleClick={() => setOpen(!open)}>
        {children}
      </Anchor>
      {open ? (
        <Content>
          {options
            .sort((a) => (a.value !== value ? 1 : 0))
            .map((option) => {
              return (
                <Option
                  key={option.value}
                  icon={option.icon}
                  href={option.href}
                  active={option.value === value}
                  handleClick={() => {
                    setOpen(false);
                    handleChange?.(option.value);
                  }}
                >
                  {option.label}
                </Option>
              );
            })}
        </Content>
      ) : null}
    </div>
  );
};

export default Dropdown;
