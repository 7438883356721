import {
  createStore as createReduxStore,
  applyMiddleware,
  compose,
  Store,
} from "redux";
import { combineReducers } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "../reducers";
import { State } from "../types";

// __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ is injected by browser extension
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type GetState = Store<State>["getState"];
export type RootState = ReturnType<GetState>;

export const createStore = (preloadedState?: Partial<RootState>) => {
  const combinedReducers = combineReducers(reducers);
  const composedEnhancers = composeEnhancers(applyMiddleware(reduxThunk));

  if (preloadedState !== void 0) {
    // TRICKS: must use as any to make typescript happy here. Really tricky typing.
    return createReduxStore(
      combinedReducers,
      preloadedState as any,
      composedEnhancers
    );
  }

  return createReduxStore(combinedReducers, composedEnhancers);
};
