export type JWTConfig = {
  // Name of the environment variable storing the private key
  privateKeyEnv: string;
};

// Use this function to get one-time use JWT token
// Technically, generates a JWT token that expires 30 seconds after being generated
// The purpose is to not let anyone catch this token and reuse it
export async function getFastExpiryJWTToken(
  config: JWTConfig
): Promise<string> {
  return getJWTToken("30 seconds", config);
}

// Uses a global variable as cache to avoid calling jose.importPKCS8 too many times
const privateKeyCache: {
  [key: string]: unknown;
} = {};

// (Expiration Time) When number is passed that is used as a value,
// when string is passed it is resolved to a time span and added to the current timestamp.
export async function getJWTToken(
  expirationTime: string | number,
  config: JWTConfig
): Promise<string> {
  // Use dynamic require versus import because tests are crashing due to some TextEncoder being undefined
  const jose = require("jose");

  if (!privateKeyCache[config.privateKeyEnv]) {
    const privateKey = process.env[config.privateKeyEnv];

    if (!privateKey) {
      throw new Error(`Missing ${config.privateKeyEnv} environment variable`);
    }

    privateKeyCache[config.privateKeyEnv] = await jose.importPKCS8(
      privateKey,
      "RS256"
    );
  }

  return await new jose.SignJWT({})
    .setProtectedHeader({ alg: "RS256" })
    .setIssuedAt()
    .setExpirationTime(expirationTime)
    .sign(privateKeyCache[config.privateKeyEnv]);
}

// Call this function to generate and display a new Private Key / Public Key
// Import the public key in quicknode and import the private key in vercel environment variable
export async function generateNewJWTKeypair(): Promise<void> {
  const jose = require("jose");

  const keypair = await jose.generateKeyPair("RS256", {
    extractable: true,
  });

  console.log(
    "Private key to import in vercel env variable :",
    await jose.exportPKCS8(keypair.privateKey)
  );
  console.log(
    "Public key to import in quicknode :",
    await jose.exportSPKI(keypair.publicKey)
  );
}
