import clsx from "clsx";
import { WalletReadyState } from "@solana/wallet-adapter-base";

import {
  WalletAdapterName,
  WalletAdapters,
} from "../../../adapters/WalletAdapter";
import Button from "../../common/button/Button";

import "./WalletList.scss";

type Props = {
  handleSelectWallet: (walletAdapter: WalletAdapterName) => void;
  walletAdapters: WalletAdapters;
};

const WalletList = ({ handleSelectWallet, walletAdapters }: Props) => {
  return (
    <div className="WalletList">
      {Object.entries(walletAdapters).map(([key, { name, label, adapter }]) => {
        const walletAdapterName = key as WalletAdapterName;

        const className = clsx([
          "WalletList-button",
          `WalletList-button--${name}`,
        ]);

        // button is disabled if the wallet is not either loadable or installed
        const disabled = ![
          WalletReadyState.Installed,
          WalletReadyState.Loadable,
        ].some((x) => x === adapter.readyState);

        return (
          <Button
            key={walletAdapterName}
            className={className}
            type="button"
            onClick={() => handleSelectWallet(name)}
            disabled={disabled}
          >
            <img src={adapter.icon} className="WalletList-button-icon" alt="" />
            <span>{label}</span>
          </Button>
        );
      })}
    </div>
  );
};

export default WalletList;
