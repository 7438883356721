import { ReactNode } from "react";
import Dropdown from "../dropdown/Dropdown";
import {
  CollateralMintInfo,
  CollateralMintName,
} from "../../../config/solana.types";

type Props = {
  collateralMintName: CollateralMintName;
  availableCollateralMints: CollateralMintInfo[];
  onCollateralMintChange: (mintName: CollateralMintName) => void;
  children?: ReactNode;
};

const CollateralMintDropdown = ({
  collateralMintName,
  availableCollateralMints,
  onCollateralMintChange,
  children = null,
}: Props) => {
  return (
    <Dropdown
      name="collateral-mint-dropdown"
      value={collateralMintName}
      options={availableCollateralMints.map(({ displayName, name }) => ({
        name,
        label: displayName || name,
        value: name,
      }))}
      handleChange={(value: string) =>
        onCollateralMintChange(value as CollateralMintName)
      }
    >
      {children}
    </Dropdown>
  );
};

export default CollateralMintDropdown;
