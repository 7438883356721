import { useEffect, useState } from "react";
import {
  nativeToUi,
  IdentityDepositoryAccount,
} from "@uxd-protocol/uxd-client";
import { DepositoryInfo } from "../types";

const getRedeemableAmount = (depositoryAccount: IdentityDepositoryAccount) =>
  Math.round(
    nativeToUi(
      depositoryAccount.redeemableAmountUnderManagement,
      window.__UXD__.solana.services.UXD.getControllerRedeemableMintDecimals()
    )
  );

const useIdentityDepositoryInfo = () => {
  const [depositoryInfo, setDepositoryInfo] = useState<DepositoryInfo | null>(
    null
  );

  const depository =
    window.__UXD__.solana.services.identity.getIdentityDepository();

  useEffect(() => {
    const fetchDepositoryInfo = async (): Promise<DepositoryInfo> => {
      const identityDepositoryAccount =
        await window.__UXD__.solana.services.identity.getIdentityDepositoryAccount();

      const redeemableAmount = getRedeemableAmount(identityDepositoryAccount);

      return {
        collateral: "USDC",
        redeemableAmount,
      };
    };

    fetchDepositoryInfo().then((info) => setDepositoryInfo(info));
  }, [depository]);

  return depositoryInfo;
};

export default useIdentityDepositoryInfo;
