import { FormEvent, ReactNode } from "react";

export type Props = {
  id: string;
  className: string;
  handleSubmit: () => void;
  children: ReactNode;
};

export const FormAddon = ({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) => <span className={`${className}-addon`}>{children}</span>;

const Form = ({ id, className, handleSubmit, children }: Props) => {
  return (
    <form
      id={id}
      className={className}
      onSubmit={(evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        handleSubmit();
      }}
      data-testid="Form-testid"
    >
      {children}
    </form>
  );
};

export default Form;
