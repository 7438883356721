/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode, useState } from "react";
import useSlippage from "../../hooks/useSlippage";

import SlippageModal from "../slippage/Slippage";
import "./Settings.scss";

type RowProps = { label: string; value: string };
type Props = {
  displayPrice: string;
  children?: ReactNode;
  slippageEnabled?: boolean;
};

export const Row = ({ label, value }: RowProps) => {
  return (
    <div className="Settings-row">
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};

const Settings = ({
  displayPrice,
  children,
  slippageEnabled = true,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { slippage, setSlippage } = useSlippage();
  const slippageDisplay =
    !slippageEnabled || slippage === null ? "-" : `${slippage}%`;

  return (
    <div className="Settings">
      <div className="Settings-row">
        <span>Price</span>
        <span>{displayPrice}</span>
      </div>
      <div className="Settings-row">
        <span>Slippage tolerance</span>
        <a
          className="Settings-slippage-button"
          href="#"
          role="button"
          data-disabled={!slippageEnabled ? "disabled" : undefined}
          onClick={(evt) => {
            evt.preventDefault();
            if (!slippageEnabled) return;
            setModalOpen(true);
          }}
        >
          {slippageDisplay}
        </a>
      </div>
      {children ?? null}
      {modalOpen ? (
        <SlippageModal
          setOpen={setModalOpen}
          slippage={slippage}
          setSlippage={setSlippage}
        />
      ) : null}
    </div>
  );
};

export default Settings;
