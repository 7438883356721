import UXDDevnetConfig from "./uxd.solana.devnet";
import UXDMainnetConfig from "./uxd.solana.mainnet";
import UXDOptimismTestnetConfig from "./uxd.optimism.testnet";
import UXDArbitrumTestnetConfig from "./uxd.arbitrum.testnet";
import UXDArbitrumMainnetConfig from "./uxd.arbitrum.mainnet";
import { Chain, ClusterName, Network } from "./types";

export const getAvailableClusters = (chain: Chain): Array<ClusterName> => {
  switch (chain) {
    case "solana":
      return ["devnet", "mainnet-beta"];
    case "optimism":
      return ["testnet"];
    case "arbitrum":
      return ["testnet", "mainnet"];
    default:
      throw new Error(`${chain} :: unhandled chain in UXD configuration`);
  }
};

export const getConfigByNetwork = (
  chain: Chain,
  cluster: ClusterName
):
  | typeof UXDOptimismTestnetConfig
  | typeof UXDArbitrumTestnetConfig
  | typeof UXDArbitrumMainnetConfig
  | typeof UXDDevnetConfig
  | typeof UXDMainnetConfig => {
  const network: Network = `${chain}/${cluster}`;

  switch (network) {
    case "optimism/testnet":
      return UXDOptimismTestnetConfig;
    case "arbitrum/testnet":
      return UXDArbitrumTestnetConfig;
    case "arbitrum/mainnet":
      return UXDArbitrumMainnetConfig;
    case "solana/devnet":
      return UXDDevnetConfig;
    case "solana/mainnet-beta":
      return UXDMainnetConfig;
    default:
      throw new Error(`${cluster} :: unhandled cluster in UXD configuration`);
  }
};
