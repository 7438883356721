import { SendTransactionError } from "@solana/web3.js";
import { Extras } from "@sentry/types";
import {
  WalletError,
  WalletSignTransactionError,
} from "@solana/wallet-adapter-base";
import { UserInfo } from "../types";
import { ClusterRPCEndpoints } from "../config/solana.types";

type ContextInformation = Extras;

type UXDErrorCtx = ContextInformation & {
  display?: string;
};

export class UXDError extends Error {
  public ctx: UXDErrorCtx;

  // Where is this display variable used (?)
  public display: string;

  constructor(message: string) {
    super(message);

    this.ctx = {};
    this.display = "";
  }

  set context(infos: ContextInformation) {
    this.ctx = { ...infos, display: this.getDisplayMessage() };
  }

  get context() {
    const { display, ...infos } = this.ctx;
    return infos;
  }

  public getDisplayMessage(): string {
    if (!(this.context.err instanceof Error)) {
      return super.toString();
    }

    return [
      `${this.message} (${this.context.err.name})`,
      this.context.err.message,
    ].join("\n");
  }
}

export class UXDTransactionSignError extends UXDError {
  constructor(err: WalletError | unknown) {
    super("Unable to sign transaction");
    this.name = "UXDTransactionSignError";
    this.context = { err };
  }

  public getDisplayMessage(): string {
    if (!(this.context.err instanceof WalletSignTransactionError)) {
      return super.getDisplayMessage();
    }

    if (this.context.err.message.includes("unknown signer")) {
      return [
        `${this.message} (unknown signer)`,
        "Did you switch wallets?",
      ].join("\n");
    }

    if (this.context.err.error?.code) {
      return [
        `${this.message} (${this.context.err.error.code})`,
        this.context.err.error.message,
      ].join("\n");
    }

    return super.getDisplayMessage();
  }
}

type CustomProgramErrorInfo = {
  programId: string;
  customProgramError: string;
};

export class UXDTransactionSendError extends UXDError {
  constructor(err: SendTransactionError | unknown) {
    super("Unable to send transaction");
    this.name = "UXDTransactionSendError";
    this.context = { err };
  }

  protected customProgramErrorDescription = {
    [window.__UXD__.solana.config.getProgramInfo("uxd").address.toString()]: {
      "5": "Collateral balance is insufficient to mint.",
      "7": "Redeemable balance is insufficient to redeem.",
      "18": "Minting amount below base lot size.",
    },
  } as const;

  // Parse programId + custom program error code
  // E.g: Program UXD8m9cvwk4RcSxnX2HZ9VudQCEeDH6fRnB4CAP57Dr failed: custom program error: 0x7
  protected extractCustomProgramErrorRegexp =
    /Program ([a-zA-Z0-9]+) failed: custom program error: 0x([a-fA-F0-9]+)/;

  // Extract information from logs
  protected getCustomProgramErrorInfo(
    sendTransactionErrLogs: string[]
  ): CustomProgramErrorInfo | null {
    const customProgramErrorLogLine = sendTransactionErrLogs
      .filter((log) => log.includes("failed: custom program error: "))
      .pop();

    if (!customProgramErrorLogLine) {
      return null;
    }

    const ret = this.extractCustomProgramErrorRegexp.exec(
      customProgramErrorLogLine
    );

    if (!ret || ret.length !== 3) {
      return null;
    }

    const [, programId, customProgramError] = ret;

    return {
      programId,
      customProgramError,
    };
  }

  public getDisplayMessage(): string {
    if (
      !(this.context.err instanceof SendTransactionError) ||
      !Array.isArray(this.context.err.logs)
    ) {
      return super.getDisplayMessage();
    }

    const sendTransactionErrLogs = this.context.err.logs;

    const programFailure = sendTransactionErrLogs
      .filter((log) => log.startsWith("Program failed to complete: "))
      .pop()
      ?.split("Program failed to complete: ")
      .pop();

    if (programFailure) {
      return [
        `${this.message} (failure)`,
        `Program failure: ${programFailure}`,
      ].join("\n");
    }

    const customProgramErrorInfo = this.getCustomProgramErrorInfo(
      sendTransactionErrLogs
    );

    if (customProgramErrorInfo === null) {
      const programLogs = sendTransactionErrLogs.filter((log) =>
        log.startsWith("Program log: ")
      );

      const programTrace = programLogs.find((log) =>
        log.startsWith("Program log: <*>")
      );

      const actualLog = programTrace
        ? programTrace.split(";")[0]
        : programLogs.pop() ?? "Unknown";

      return [`${this.message} (0x??)`, actualLog].join("\n");
    }

    const { programId, customProgramError } = customProgramErrorInfo;

    // Extract program related logs from logs
    // This part is tricky and very specific to the existing program traces
    // It's a best effort to display errors the best possible way to the user
    const { programTraces, programLogs } = sendTransactionErrLogs
      .reverse()
      .reduce(
        (tmp, log) => {
          if (tmp.stop) {
            return tmp;
          }

          // Keep going until we find something unrelated to the UXD program
          if (log.startsWith(`Program ${programId}`)) {
            return tmp;
          }

          if (log.startsWith("Program log: <*>")) {
            tmp.programTraces.push(log.split(";")[0]);
            return tmp;
          }

          if (
            (log.startsWith("Program log: ") &&
              !log.startsWith("Program log: Custom program error")) ||
            log.startsWith("Transfer: ")
          ) {
            tmp.programLogs.push(log);
            return tmp;
          }

          // If we encounter an other ProgramId when we already have programLogs
          // then stop
          if (/Program [a-zA-Z0-9]+ /.test(log) && tmp.programLogs.length) {
            tmp.stop = true;
            return tmp;
          }

          return tmp;
        },
        {
          stop: false,
          programTraces: [],
          programLogs: [],
        } as {
          stop: boolean;
          programTraces: string[];
          programLogs: string[];
        }
      );

    const description =
      this.customProgramErrorDescription[programId] &&
      (this.customProgramErrorDescription[programId] as any)[
        customProgramError
      ];

    const message = [`${this.message} (0x${customProgramError})`];

    if (programTraces.length > 0) {
      message.push(...programTraces);
    } else {
      message.push(...programLogs);
    }

    if (description) {
      message.push(description);
    }

    return message.join("\n");
  }
}

export class UXDTransactionConfirmError extends UXDError {
  constructor({ err, signature }: { err: Error | unknown; signature: string }) {
    super("Unable to confirm transaction");
    this.name = "UXDTransactionConfirmError";
    this.context = { err, signature };
  }

  public getDisplayMessage(): string {
    const { err } = this.context;

    if (err instanceof Error && err.message.includes("not confirmed in")) {
      return [
        `${this.message} (timeout)`,
        this.context.signature &&
          `Check signature **${this.context.signature}**`,
      ].join("\n");
    }

    return super.getDisplayMessage();
  }
}

export class NoHealthyRPCAvailableError extends UXDError {
  constructor(clusterRPCEndpoints: ClusterRPCEndpoints) {
    super("Unable to find an healthy RPC");
    this.name = "NoHealthyRPCAvailableError";
    this.context = {
      urls: clusterRPCEndpoints.map((x) => x.url),
    };
  }
}

export class FetchUserInfoError extends UXDError {
  constructor(endpoint: string) {
    super("Unable to fetch user info");
    this.name = "FetchUserInfoError";
    this.context = { endpoint };
  }
}

export class UnknownUserError extends UXDError {
  constructor({ country, ip }: UserInfo) {
    super("User's IP address or its location couldn't be determined");
    this.context = { country, ip };
  }
}

export class SanctionedUserError extends UXDError {
  constructor({ country, ip }: UserInfo) {
    super("User's IP address was identified as from a sanctioned area");
    this.name = "SanctionedUserError";
    this.context = { country, ip };
  }
}

export function getDisplayMessageFromError(error: unknown): string {
  if (error instanceof UXDError) {
    return error.getDisplayMessage();
  }

  if (error === null || typeof error === "undefined") {
    return "";
  }

  if (typeof error === "object" && error !== null) {
    if ((error as any).code && (error as any).message) {
      return (error as any).message;
    }

    if ((error as any).data?.message) {
      return (error as any).data?.message;
    }
  }

  return String(error);
}
