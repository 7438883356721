import { ReactNode } from "react";
import { TokenInfo } from "../../../config/arbitrum.types";
import Form, { Props as FormProps } from "../../common/form/Form";
import "./CollateralForm.scss";

export type Props = Omit<FormProps, "children" | "className"> & {
  children: (props: RenderProps) => ReactNode;
};

type RenderProps = {
  collateral: TokenInfo;
  redeemable: TokenInfo;
};

export const CLASS_NAME = "CollateralForm" as const;
const COLLATERAL = "USDC_ARBITRUM";
const REDEEMABLE = "UXD";

const CollateralForm = ({ children, ...formProps }: Props) => {
  const collateral = window.__UXD__.arbitrum.config.tokens[COLLATERAL];
  const redeemable = window.__UXD__.arbitrum.config.tokens[REDEEMABLE];

  return (
    <Form className={CLASS_NAME} {...formProps}>
      {children({
        collateral,
        redeemable,
      })}
    </Form>
  );
};

export default CollateralForm;
