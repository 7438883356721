import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "../../hooks/hooks";
import usePrepareSwap from "../../hooks/usePrepareSwap";
import useSwap from "../../hooks/useSwap";
import { SwapAction } from "../../types";
import TabButton from "../common/tab-button/TabButton";
import FromCollateralForm from "../common/collateral-form/FromCollateralForm";
import ToCollateralForm from "../common/collateral-form/ToCollateralForm";
import Settings, { Row as SettingsRow } from "../settings/Settings";
import SwapButton from "../common/swap-button/SwapButton";
import "./Swap.scss";
import { CollateralMintName, MintName } from "../../config/solana.types";
import useMinimumReceived from "../../hooks/useMinimumReceived";
import { WalletAdapterName } from "../../adapters/WalletAdapter";
import WalletModal from "../wallet/wallet-modal/WalletModal";

type Tab = SwapAction;

const TABS = [
  { name: "mint", label: "Mint", component: FromCollateralForm },
  { name: "redeem", label: "Redeem", component: ToCollateralForm },
] as const;

const EMPTY_VALUE_PLACEHOLDER = " - ";

function formatAmount(amount: number, displayDecimals: number) {
  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: displayDecimals,
  });
}

// FIXME: should probably belong to mint configuration instead
// and also be used in redeemable / collateral input values.
function getDisplayDecimals(mintName: MintName) {
  switch (mintName) {
    case "UXD":
      return 2;
    default:
      return window.__UXD__.solana.config.getMintInfo(mintName).decimals;
  }
}

function getDisplayPrice(
  price: string | null,
  collateralMintName: CollateralMintName
) {
  if (!price) return EMPTY_VALUE_PLACEHOLDER;

  const collateral =
    window.__UXD__.solana.config.getCollateralMintInfo(collateralMintName);
  const name = collateral.displayName ?? collateral.name;

  return `${formatAmount(
    Number(price),
    getDisplayDecimals("UXD")
  )} UXD per ${name}`;
}

function getDisplayedCollateralMintName(
  collateralMintName: CollateralMintName
) {
  const { name, displayName } =
    window.__UXD__.solana.config.getMintInfo(collateralMintName);
  return displayName ?? name;
}

function getDisplayMinimumReceived(
  minimumReceived: number,
  collateralMintName: CollateralMintName,
  action: SwapAction
) {
  if (!minimumReceived) return EMPTY_VALUE_PLACEHOLDER;

  const counterpartMint =
    action === "redeem"
      ? getDisplayedCollateralMintName(collateralMintName)
      : "UXD";

  return `${formatAmount(
    minimumReceived,
    getDisplayDecimals(counterpartMint)
  )} ${counterpartMint}`;
}

function getSwapFeesInBps(
  collateralMintName: CollateralMintName,
  activeTab: "mint" | "redeem"
): number {
  if (activeTab === "mint") {
    return window.__UXD__.solana.services.router.getMintingFeeInBps();
  }
  return window.__UXD__.solana.services.router.getRedeemingFeeInBps();
}

const Swap = () => {
  const [activeTab, setActiveTab] = useState<Tab>("mint");

  const connected = !!useSelector((state) =>
    state.wallet
      ? window.__UXD__.solana.config.getWalletAdapter(
          state.wallet as WalletAdapterName
        ).adapter.connected
      : false
  );

  const {
    onCollateralMintChange,
    onCollateralAmountChange,
    onRedeemableAmountChange,
    collateralAmount,
    collateralMintName,
    redeemableAmount,
  } = usePrepareSwap(activeTab);

  const collateralPrices = useSelector((state) => state.collateralPrices);
  const price = collateralPrices[collateralMintName];
  const displayPrice = getDisplayPrice(price, collateralMintName);

  const swapFeesInBps = getSwapFeesInBps(collateralMintName, activeTab);

  const minimumReceived = useMinimumReceived(activeTab, collateralMintName);
  const displayMinimumReceived = getDisplayMinimumReceived(
    minimumReceived,
    collateralMintName,
    activeTab
  );

  const swapFeesPercentage = (swapFeesInBps / 100).toFixed(2);
  const displaySwapFeesPercentage = `${swapFeesPercentage}%`;

  const { loading, valid, swap } = useSwap(activeTab);

  const isActive = (tab: Tab) => activeTab === tab;

  const activeTabObject = TABS.find(({ name }) =>
    isActive(name)
  ) as (typeof TABS)[number];

  const SwapContent = activeTabObject.component;

  const getTabButtonClassName = (tab: Tab) =>
    clsx({
      "Swap-tab": true,
      [`Swap-tab-${tab}`]: true,
      "Swap-tab-active": isActive(tab),
    });

  const formId = `swap-form--${activeTab}`;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <main className="Swap">
      <div className="Swap-inner">
        <nav className="Swap-tabs">
          {TABS.map(({ name: tab, label }) => (
            <TabButton
              key={`Swap-tab-${tab}`}
              label={label}
              className={getTabButtonClassName(tab)}
              handleClick={() => setActiveTab(tab)}
              disabled={false}
            />
          ))}
        </nav>
        <div className="Swap-content">
          <SwapContent
            id={formId}
            onCollateralMintChange={onCollateralMintChange}
            onCollateralAmountChange={onCollateralAmountChange}
            onRedeemableAmountChange={onRedeemableAmountChange}
            collateralAmount={collateralAmount}
            collateralMintName={collateralMintName}
            redeemableMintName="UXD"
            redeemableAmount={redeemableAmount}
            feature={activeTab}
            connected={connected}
            handleSubmit={() => {
              if (valid) {
                swap();
              }
            }}
          />
          <Settings
            displayPrice={displayPrice}
            slippageEnabled={collateralMintName !== "USDC_MERCURIAL"}
          >
            <SettingsRow
              label="Minimum Received"
              value={displayMinimumReceived}
            />
            <SettingsRow label="Swap Fee" value={displaySwapFeesPercentage} />
          </Settings>
        </div>
      </div>
      <SwapButton
        action={activeTab}
        connected={connected}
        valid={valid}
        loading={loading}
        formId={formId}
        handleClick={() => {
          setModalOpen(true);
        }}
      >
        {modalOpen ? <WalletModal setOpen={setModalOpen} /> : null}
      </SwapButton>
    </main>
  );
};

export default Swap;
