import AUXDBaseConfig from "./uxd.base";

import { Chain, ClusterName } from "./types";

import { Contracts, Tickers, Tokens } from "./optimism.types";
import { SlippageOptions } from "../components/slippage/Slippage";

export default abstract class AUXDOptimismBaseConfig extends AUXDBaseConfig {
  public readonly chain: Chain = "optimism";

  public abstract readonly clusterName: ClusterName;
  public abstract readonly defaultSlippage: SlippageOptions;
  public abstract readonly contracts: Contracts;
  public abstract readonly rpcProviderUrl: string;
  public abstract readonly tokens: Tokens;
  public abstract readonly tickers: Tickers;
}
