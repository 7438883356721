import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";
import { CollateralMintName as SolanaCollateralMintName } from "../config/solana.types";

export type RedeemState = {
  collateralMintName: SolanaCollateralMintName;
  collateralAmount: string;
  redeemableAmount: string;
};

const INITIAL_STATE = {
  collateralMintName: "USDC_MERCURIAL",
  collateralAmount: "",
  redeemableAmount: "",
} as const;

const redeemReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): RedeemState => {
  switch (action.type) {
    case ACTION_TYPES.SET_REDEEM_COLLATERAL_MINT:
      if (action.payload.collateralMintName === state.collateralMintName) {
        return state;
      }

      return {
        collateralAmount: INITIAL_STATE.collateralAmount,
        redeemableAmount: INITIAL_STATE.redeemableAmount,
        collateralMintName: action.payload.collateralMintName,
      };

    case ACTION_TYPES.SET_REDEEM_AMOUNTS:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_TYPES.REDEEM_SUCCESS:
      return {
        ...INITIAL_STATE,
        collateralMintName: state.collateralMintName,
      };

    default:
      return state;
  }
};

export default redeemReducer;
