import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { APR_BASIS, StakingAccount } from "@uxdprotocol/uxd-staking-client";
import { createNotificationAction } from "../../../actions/notification";
import { WalletState } from "../../../reducers/walletReducer";
import { Status } from "../../../hooks/useStakingAccounts";
import { timestampToDateString } from "../../../utils/date";
import { getDisplayMessageFromError } from "../../../utils/error";
import logger from "../../../utils/logger";
import { ONE_SECOND, ONE_YEAR, secondsToLabel } from "../../../utils/time";
import { renderAmount } from "../../../utils/formatting";
import { BN_ZERO, nativeToUi } from "../../../utils/amount";
import { StakingOptionUI } from "../../../types";
import ActionButton from "../../common/action-button/ActionButton";
import InputAmountMint from "../../common/input-amount/InputAmountMint";
import Options, { Option } from "../../common/options/Options";
import transactionLink from "../../common/transaction-link/TransactionLink";
import StakingCampaign from "../staking-campaign/StakingCampaign";
import { WalletAdapterName } from "../../../adapters/WalletAdapter";
import InputMaxButtonMint from "../../common/input-max-button/InputMaxButtonMint";
import "./StakingForm.scss";

const FORM_ID = "staking-form";
const FALLBACK_DISPLAY = "-";

function renderAPR(ratio: number | undefined): string | undefined {
  if (typeof ratio === "undefined") {
    return;
  }

  return ratio.toLocaleString("en-US", {
    style: "percent",
  });
}

function calculateRewards(
  rewards: number,
  amount: number,
  apr: number,
  aprBasis: number,
  nowTimestamp: number,
  endTimestamp: number
): number {
  const aprSec = apr / ONE_YEAR / aprBasis;
  const remaining = endTimestamp - nowTimestamp;
  const aprRemaining = aprSec * remaining;

  return rewards + amount * aprRemaining;
}

function calculateAverageApr(
  total: number,
  rewards: number,
  lockupSecs: number
): number | undefined {
  if (!total || !rewards || !lockupSecs) return undefined;

  return (rewards / lockupSecs / total) * ONE_YEAR;
}

type Props = {
  options: StakingOptionUI[];
  accounts: StakingAccount[];
  status: Status;
  walletAdapterName: WalletState;
  signalAccountsFetch: () => void;
};

const StakingForm = ({
  options,
  accounts,
  status,
  walletAdapterName,
  signalAccountsFetch,
}: Props) => {
  const connected = !!walletAdapterName;
  const dispatch = useDispatch();

  const optionsUI = useMemo<Array<Option>>(() => {
    return options.map(({ identifier, lockupSecs }) => ({
      value: identifier,
      label: secondsToLabel(lockupSecs),
    }));
  }, [options]);

  const [option, setOption] = useState<string>(optionsUI[0].value);
  const [amount, setAmount] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const UXP = window.__UXD__.solana.config.getMintInfo("UXP");

  const account =
    accounts.find(
      (account) => account.stakingOptionIdentifier.toString() === option
    ) ?? null;

  const { apr, lockupSecs } = options.find(
    (stakingOption) => stakingOption.identifier === option
  )!;

  const claimable =
    !!account?.stakeEndTs &&
    account.stakeEndTs.toNumber() * ONE_SECOND <= Date.now();

  useEffect(() => {
    setAmount("");
    signalAccountsFetch();
  }, [claimable, signalAccountsFetch]);

  const stakeDate = account?.stakeEndTs
    ? timestampToDateString(
        (account.stakeEndTs.toNumber() - lockupSecs) * ONE_SECOND
      )
    : timestampToDateString(Date.now());

  const redemptionDate = account?.stakeEndTs
    ? timestampToDateString(account.stakeEndTs.toNumber() * ONE_SECOND)
    : timestampToDateString(Date.now() + lockupSecs * ONE_SECOND);

  const currentlyStaked = useMemo(
    () => nativeToUi(account?.stakedAmount ?? BN_ZERO, UXP.decimals),
    [account?.stakedAmount, UXP.decimals]
  );

  const rewardsUnlock = useMemo(
    () =>
      claimable
        ? nativeToUi(account.rewardAmount, UXP.decimals)
        : calculateRewards(
            nativeToUi(account?.rewardAmount ?? BN_ZERO, UXP.decimals),
            Number(amount),
            apr,
            APR_BASIS,
            Date.now() / ONE_SECOND,
            account?.stakeEndTs.toNumber() ??
              Date.now() / ONE_SECOND + lockupSecs
          ),
    [
      UXP.decimals,
      account?.rewardAmount,
      account?.stakeEndTs,
      amount,
      apr,
      claimable,
      lockupSecs,
    ]
  );

  const totalStaked = currentlyStaked + Number(amount);

  const averageApr = calculateAverageApr(
    totalStaked,
    rewardsUnlock,
    lockupSecs
  );

  const handleSubmit = async (evt: SyntheticEvent) => {
    evt.preventDefault();

    if (!connected) {
      return;
    }

    setSubmitting(true);
    const walletAdapter = window.__UXD__.solana.config.getWalletAdapter(
      walletAdapterName as WalletAdapterName
    );

    try {
      const txId = await window.__UXD__.solana.services.staking.stake({
        amount: Number(amount),
        option: Number(option),
        walletAdapter,
      });

      setAmount("");
      dispatch(
        createNotificationAction({
          title: "Stake Successful",
          message: transactionLink({
            message: "Your stake transaction was a success",
            transaction: txId,
            cluster: window.__UXD__.solana.config.getClusterName(),
          }),
          level: "success",
          icon: "info",
        })
      );
    } catch (err) {
      logger.error(err);

      dispatch(
        createNotificationAction({
          title: "Stake Error",
          message: "Transaction Failed",
          details: getDisplayMessageFromError(err),
          level: "error",
          icon: "info",
        })
      );
    } finally {
      signalAccountsFetch();
      setSubmitting(false);
    }
  };

  const summaryTitle = amount ? (
    <>
      Summary <em>(estimated)</em>
    </>
  ) : (
    "Summary"
  );

  const disclaimerContent = claimable
    ? "UXP rewards must be claimed before staking again for this duration"
    : "Once staked, your UXP will be locked and only released after redemption period";

  const submitButtonLabel = submitting
    ? !!currentlyStaked && !claimable
      ? "Adding ..."
      : "Staking ..."
    : !!currentlyStaked && !claimable
    ? "Add UXP"
    : "Stake UXP";
  const submitButtonDisabled =
    !connected ||
    status === Status.LOADING ||
    submitting ||
    claimable ||
    Number(amount) <= 0;

  return (
    <div className="StakingForm">
      <section>
        <StakingCampaign />
        <form id={FORM_ID} onSubmit={handleSubmit}>
          <Options
            name="identifier"
            legend="Duration"
            value={option}
            options={optionsUI}
            handleChange={setOption}
          >
            {(value: string) => {
              const opt = options.find(
                ({ identifier }) => identifier === value
              )!;

              return (
                <span className="StakingForm-duration-apr-label">
                  <em>APR</em> <strong>{renderAPR(opt.apr / APR_BASIS)}</strong>
                </span>
              );
            }}
          </Options>

          <div
            className="StakingForm-input-label-wrapper"
            title={claimable ? disclaimerContent : undefined}
          >
            <label htmlFor="amount-input">Amount</label>
            <InputMaxButtonMint
              activeMint={UXP}
              handleClick={
                !claimable
                  ? (amount: string) => {
                      setAmount(amount);
                    }
                  : undefined
              }
            />
          </div>

          <InputAmountMint
            id="amount-input"
            name="amount"
            amount={amount}
            handleChange={setAmount}
            disabled={claimable}
            reset={connected}
            mint={window.__UXD__.solana.config.getMintInfo("UXP")}
          />
        </form>
      </section>
      <section>
        <h4>{summaryTitle}</h4>

        <div className="StakingForm-info">
          <strong>Stake Date</strong>
          <em>{stakeDate ?? FALLBACK_DISPLAY}</em>
        </div>

        <div className="StakingForm-info">
          <strong>Redemption Date</strong>
          <em>{redemptionDate ?? FALLBACK_DISPLAY}</em>
        </div>

        <div className="StakingForm-info">
          <strong>Currently Staked</strong>
          <em>
            {currentlyStaked
              ? renderAmount(currentlyStaked, {
                  decimals: UXP.decimals,
                  currency: "UXP",
                })
              : FALLBACK_DISPLAY}
          </em>
        </div>

        <div className="StakingForm-info">
          <strong>New Stake Amount</strong>
          <em>
            {amount !== ""
              ? renderAmount(Number(amount), {
                  decimals: UXP.decimals,
                  currency: "UXP",
                })
              : FALLBACK_DISPLAY}
          </em>
        </div>

        <hr className="StakingForm-separator" />

        <div className="StakingForm-info">
          <strong>Total Staked</strong>
          <em>
            {totalStaked
              ? renderAmount(totalStaked, {
                  decimals: UXP.decimals,
                  currency: "UXP",
                })
              : FALLBACK_DISPLAY}
          </em>
        </div>

        <div className="StakingForm-info">
          <strong>Average APR</strong>
          <em className="StakingForm-info--apr">
            {renderAPR(averageApr) ?? (
              <span className="StakingForm-info--fallback">
                {FALLBACK_DISPLAY}
              </span>
            )}
          </em>
        </div>

        <div className="StakingForm-info">
          <strong>Rewards On Unlock</strong>
          <em>
            {rewardsUnlock
              ? renderAmount(rewardsUnlock, {
                  decimals: UXP.decimals,
                  currency: "UXP",
                })
              : FALLBACK_DISPLAY}
          </em>
        </div>
        <div className="StakingForm-submit">
          <p className="StakingForm-disclaimer">{disclaimerContent}</p>
          <ActionButton
            type="submit"
            color="green"
            form={FORM_ID}
            disabled={submitButtonDisabled}
            label={submitButtonLabel}
          />
        </div>
      </section>
    </div>
  );
};

export default StakingForm;
