import { Chain, ClusterName } from "./types";

export default abstract class AUXDBaseConfig {
  public abstract chain: Chain;
  protected abstract clusterName: ClusterName;
  protected abstract defaultSlippage: string;

  public getClusterName(): ClusterName {
    return this.clusterName;
  }

  public getDefaultSlippagePercent(): string {
    return this.defaultSlippage;
  }
}
