import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import { getBalanceAction } from "../actions/balance";
import { MintName as SolanaMintName } from "../config/solana.types";
import { TokenName as OptimismTokenName } from "../config/optimism.types";
import { TokenName as ArbitrumTokenName } from "../config/arbitrum.types";
import { Balance } from "../types";
import logger from "../utils/logger";
import { useSelector } from "./hooks";
import usePeriodicFetch from "./usePeriodicFetch";

const FETCH_INTERVAL = 10_000;

const useBalance = (
  mintName: SolanaMintName | OptimismTokenName | ArbitrumTokenName
) => {
  const balance: Balance = useSelector((state) => state.balances[mintName]);

  const walletAdapterName = useSelector((state) => state.wallet);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchBalance = useMemo(() => {
    if (!walletAdapterName) return null;

    return async () => {
      setLoading(true);

      try {
        logger.debug("Fetching balance ...", { mintName });

        await dispatch(getBalanceAction(mintName));
      } catch (err) {
        logger.error(err, {
          info: "Unable to fetch balance",
          mintName,
        });
      } finally {
        setLoading(false);
      }
    };
  }, [walletAdapterName, mintName, dispatch]);

  usePeriodicFetch(fetchBalance, FETCH_INTERVAL);

  return { balance, balanceLoading: loading };
};

export default useBalance;
