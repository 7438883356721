import { Store } from "redux";
import * as Sentry from "@sentry/browser";

import { Actions } from "../actions/types";
import { APP_SCOPE, ENV, PARAMS_ITEMS, STORAGE_ITEMS } from "./constants";
import { getUserInfo, enforceGeoIp } from "../utils/geoip";
import { getLocalStorageItem } from "../utils/localstorage";
import initSolana from "./solana";
import initOptimism from "./optimism";
import initArbitrum from "./arbitrum";
import { createStore } from "../store";
import { Params, Storage, Globals, State, UserInfo, AppScope } from "../types";
import { Chain, ClusterName, IConfiguration } from "../config/types";
import { getConfigByNetwork } from "../config/uxd";
import { ISolanaConfiguration } from "../config/solana.types";
import { IOptimismConfiguration } from "../config/optimism.types";
import { IArbitrumConfiguration } from "../config/arbitrum.types";

function getChain(scope: AppScope): Chain {
  switch (scope) {
    case "swap-optimism":
      return "optimism";
    case "swap-arbitrum":
      return "arbitrum";
    case "swap":
    case "dashboard":
    case "staking":
    default:
      return "solana";
  }
}

function getDefaultCluster(scope: AppScope) {
  switch (scope) {
    case "swap-optimism":
      return "testnet";
    case "swap-arbitrum":
      return ENV === "production" ? "mainnet" : "testnet";
    case "swap":
    case "dashboard":
    case "staking":
    default:
      return ENV === "production" || APP_SCOPE === "dashboard"
        ? "mainnet-beta"
        : "devnet";
  }
}

export function initConfig({
  chain,
  defaultCluster,
  params,
  storage,
}: {
  chain: Chain;
  defaultCluster: ClusterName;
  params: Params;
  storage: Storage;
}) {
  for (const source of [params, storage]) {
    if (source.cluster !== null) {
      try {
        return getConfigByNetwork(chain, source.cluster as ClusterName);
      } catch {
        if (source === storage) {
          // cluster from storage is invalid, clear it.
          window.localStorage.removeItem("cluster");
        }
      }
    }
  }

  // Default config
  return getConfigByNetwork(chain, defaultCluster);
}

function getParams() {
  const searchParams = new URLSearchParams(window.location.search);

  return PARAMS_ITEMS.reduce((acc, item) => {
    acc[item] = searchParams.get(item);
    return acc;
  }, {} as Params);
}

function getStorage() {
  return STORAGE_ITEMS.reduce((acc, item) => {
    acc[item] = getLocalStorageItem(item);
    return acc;
  }, {} as Storage);
}

async function initUser(): Promise<UserInfo> {
  if (
    APP_SCOPE !== "dashboard" &&
    process.env.REACT_APP_GEOIP_ENFORCED === "true"
  ) {
    const user = await getUserInfo();

    enforceGeoIp(user);

    return user;
  }

  const { userAgent = "" } = window.navigator;

  // Default
  return { country: "", ip: "", isVpn: false, userAgent };
}

function initStore(): Store<State, Actions.AnyAction> {
  return createStore();
}

function exposeGlobals(globals: Globals) {
  window.__UXD__ = globals;
}

function initSentry(version: string) {
  const dsn = process.env.REACT_APP_SENTRY_DSN;

  if (!dsn || ENV !== "production") {
    return;
  }

  // gives us the possibility to filter staging event directly in staging
  const sentryEnv = window.location.origin.includes("staging")
    ? "staging"
    : window.location.origin.includes("uxd-app-frontend")
    ? "branch-preview"
    : ENV;

  // Hotfix to ignore bots running headless browsers from sanctioned areas
  const { userAgent = "" } = window.navigator;

  if (userAgent.includes("HeadlessChrome")) {
    return;
  }

  Sentry.init({
    dsn,
    environment: sentryEnv,
    release: version,
  });
}

async function getChainsGlobals(config: IConfiguration) {
  let optimismP = Promise.resolve({}) as {} as ReturnType<typeof initOptimism>;
  let arbitrumP = Promise.resolve({}) as {} as ReturnType<typeof initArbitrum>;
  let solanaP = Promise.resolve({}) as ReturnType<typeof initSolana>;

  switch (config.chain) {
    case "optimism": {
      optimismP = initOptimism(config as unknown as IOptimismConfiguration);
      break;
    }

    case "arbitrum": {
      arbitrumP = initArbitrum(config as unknown as IArbitrumConfiguration);
      break;
    }

    case "solana":
    default: {
      solanaP = initSolana(config as unknown as ISolanaConfiguration);
      break;
    }
  }

  const [optimism, arbitrum, solana] = await Promise.all([
    optimismP,
    arbitrumP,
    solanaP,
  ]);

  return { optimism, arbitrum, solana };
}

export default async function init() {
  const version = process.env.REACT_APP_VERSION || "";

  initSentry(version);

  // async init functions that can safely be ran in parallel with the rest
  const userP = initUser();

  const params = getParams();
  const storage = getStorage();

  const chain = getChain(APP_SCOPE);
  const defaultCluster = getDefaultCluster(APP_SCOPE);

  const config = initConfig({ chain, defaultCluster, params, storage });

  const chainsP = getChainsGlobals(config);

  const [user, { optimism, arbitrum, solana }] = await Promise.all([
    userP,
    chainsP,
  ]);

  exposeGlobals({
    optimism,
    arbitrum,
    solana,
    config,
    params,
    storage,
    user,
    version,
  });

  const store = initStore();

  return { store };
}
