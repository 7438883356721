import balanceReducer from "./balanceReducer";
import collateralPriceReducer from "./collateralPriceReducer";
import loadingReducer from "./loadingReducer";
import mintReducer from "./mintReducer";
import notificationReducer from "./notificationReducer";
import redeemReducer from "./redeemReducer";
import slippageReducer from "./slippageReducer";
import termsReducer from "./termsReducer";
import walletReducer from "./walletReducer";

export const reducers = {
  wallet: walletReducer,
  mint: mintReducer,
  redeem: redeemReducer,
  balances: balanceReducer,
  collateralPrices: collateralPriceReducer,
  notification: notificationReducer,
  slippage: slippageReducer,
  loading: loadingReducer,
  terms: termsReducer,
};

export default reducers;
