export const ACTION_TYPES = {
  CONNECT_WALLET: "connect_wallet",
  DISCONNECT_WALLET: "disconnect_wallet",
  GET_WALLET: "get_wallet",
  SET_BALANCE: "SET_BALANCE",
  CLEAR_BALANCE: "clear_balance",
  SET_COLLATERAL_PRICE: "SET_COLLATERAL_PRICE",
  CREATE_NOTIFICATION: "create_notification",
  DELETE_NOTIFICATION: "delete_notification",
  SET_SLIPPAGE_AMOUNT: "set_slippage_amount",
  SET_MINT_COLLATERAL_MINT: "SET_MINT_COLLATERAL_MINT",
  SET_MINT_AMOUNTS: "SET_MINT_AMOUNTS",
  MINT_SUCCESS: "MINT_SUCCESS",
  SET_REDEEM_COLLATERAL_MINT: "SET_REDEEM_COLLATERAL_MINT",
  SET_REDEEM_AMOUNTS: "SET_REDEEM_AMOUNTS",
  REDEEM_SUCCESS: "REDEEM_SUCCESS",
  SET_LOADING: "SET_LOADING",
  SET_TERMS_CONFIRMATION: "SET_TERMS_CONFIRMATION",
} as const;
