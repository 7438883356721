import { useCallback, useEffect, useState } from "react";
import { useSelector } from "./hooks";
import { IArbitrumConfiguration } from "../config/arbitrum.types";
import { IOptimismConfiguration } from "../config/optimism.types";

const useCheckWalletEvmChain = () => {
  const walletAdapterName = useSelector((state) => state.wallet);

  const [reevaluation, triggerReevaluation] = useState<number>(0);

  // true means OK
  const [chainState, setChainState] = useState<
    | true
    | {
        configurationTargetChainId: number;
        configurationTargetChainName: string;
        activeMetamaskChainId: number;
      }
  >();

  const checkFn = useCallback(async () => {
    // User is not connected yet
    if (!walletAdapterName || !window.ethereum) {
      setChainState(true);
      return;
    }

    const {
      chainId: configurationTargetChainId,
      chain: configurationTargetChainName,
    } = window.__UXD__.config as
      | IOptimismConfiguration
      | IArbitrumConfiguration;

    const activeMetamaskChainId = parseInt(
      (window.__UXD__[window.__UXD__.config.chain] as any).provider.chainId,
      16
    );

    // Chain is wrong
    if (configurationTargetChainId !== activeMetamaskChainId) {
      setChainState({
        configurationTargetChainId,
        configurationTargetChainName,
        activeMetamaskChainId,
      });
      return;
    }

    setChainState(true);
  }, [walletAdapterName]);

  // Listen to chain change to retrigger verification
  const provider = (window.__UXD__[window.__UXD__.config.chain] as any)
    .provider;

  // If provider is null, it means the user doesn't have metamask
  if (provider) {
    provider.on("chainChanged", () => triggerReevaluation(reevaluation + 1));
  }

  useEffect(() => {
    checkFn();
  }, [checkFn, reevaluation]);

  return chainState;
};

export default useCheckWalletEvmChain;
