import { MouseEvent } from "react";
import Button from "../button/Button";
import { Icon, IconName } from "../icon/Icon";

import "./IconButton.scss";

type Props = {
  icon: IconName;
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  type: "button" | "submit" | "reset";
};

const IconButton = ({ icon, onClick, className, type }: Props) => {
  return (
    <Button className={className} type={type} onClick={onClick}>
      <Icon name={icon} />
    </Button>
  );
};

export default IconButton;
