import { getFastExpiryJWTToken, JWTConfig } from "../utils/jwt";
import logger from "../utils/logger";

export default class ClusterRPCEndpoint {
  protected _url: string;
  protected _jwt: JWTConfig | false;

  constructor(url: string, jwt: JWTConfig | false = false) {
    this._url = url;
    this._jwt = jwt;
  }

  public get url(): string {
    return this._url;
  }

  public get jwt(): JWTConfig | false {
    return this._jwt;
  }

  public async checkHealth(): Promise<boolean> {
    let err: unknown | undefined;

    try {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      if (this.jwt) {
        headers["Authorization"] = `Bearer ${await getFastExpiryJWTToken(
          this.jwt
        )}`;
      }

      const response = await fetch(this.url, {
        method: "POST",
        headers,
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: 1,
          method: "getHealth",
        }),
      });

      const parsedResponse = await response.json();

      if (parsedResponse.result === "ok") {
        return true;
      }

      return false;
    } catch (e: unknown) {
      err = e;
    }

    logger.error(new Error(`RPC endpoint is not healthy`), {
      url: this._url,
      err,
    });

    return false;
  }
}
