import { TokenInfo } from "../../../config/arbitrum.types";
import { CLASS_NAME as COLLATERAL_FORM_CLASS_NAME } from "./CollateralForm";
import InputAmountArbitrum from "../../common/input-amount/InputAmountArbitrum";
import InputMaxButtonArbitrum from "../../common/input-max-button/InputMaxButtonArbitrum";

type Props = {
  id: string;
  name: string;
  label: string;
  value: string;
  handleChange: (amount: string) => void;
  maxButtonEnabled: boolean;
  connected: boolean;
  tokenInfo: TokenInfo;
};

const CollateralFormInput = ({
  id,
  name,
  label,
  value,
  handleChange,
  maxButtonEnabled,
  connected,
  tokenInfo,
}: Props) => {
  return (
    <>
      <span className={`${COLLATERAL_FORM_CLASS_NAME}-label-wrapper`}>
        <label htmlFor={id}>{label}</label>
        <InputMaxButtonArbitrum
          activeToken={tokenInfo}
          handleClick={maxButtonEnabled ? handleChange : undefined}
        />
      </span>
      <InputAmountArbitrum
        id={id}
        name={name}
        amount={value}
        handleChange={handleChange}
        reset={connected}
        token={tokenInfo}
      />
    </>
  );
};

export default CollateralFormInput;
