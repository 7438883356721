import { Connection } from "@solana/web3.js";
import {
  UXDClient,
  IdentityDepository,
  IdentityDepositoryAccount,
} from "@uxd-protocol/uxd-client";
import { ISolanaConfiguration } from "../config/solana.types";
import { ABaseClientService } from "./baseClientService";
import { TXN_OPTS } from "./constants";

export class IdentityClientService extends ABaseClientService {
  private client: UXDClient;
  private depository: IdentityDepository;
  private onChainAccount: IdentityDepositoryAccount;

  constructor(
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient,
    depository: IdentityDepository,
    onChainAccount: IdentityDepositoryAccount
  ) {
    super(config, connection);
    this.client = client;
    this.depository = depository;
    this.onChainAccount = onChainAccount;
  }

  public static initialize = async (
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient
  ): Promise<IdentityClientService> => {
    const uxdProgramId = config.getPrograms().uxd.address;

    const collateralInfo = config.getMintInfo("USDC_IDENTITY");

    const depository = new IdentityDepository(
      collateralInfo.mint,
      collateralInfo.name,
      collateralInfo.decimals,
      uxdProgramId
    );

    return new IdentityClientService(
      config,
      connection,
      client,
      depository,
      // Load the account once at start. Useful to get minting/redeeming fee and else
      await depository.getOnchainAccount(connection, TXN_OPTS)
    );
  };

  public getIdentityDepository(): IdentityDepository {
    return this.depository;
  }

  public async getIdentityDepositoryAccount(): Promise<IdentityDepositoryAccount> {
    return this.depository.getOnchainAccount(this.connection, TXN_OPTS);
  }

  public getIdentityDepositoryMintingFeeInBps(): number {
    return 0; // No fees on the identity depository
  }

  public getIdentityDepositoryRedeemingFeeInBps(): number {
    return 0; // No fees on the identity depository
  }
}
