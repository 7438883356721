import Section from "../../common/section/Section";
import "./CollateralComposition.scss";
import { DepositoryInfo } from "../../../types";
import {
  calculateMostAppropriateUnit,
  renderAmount,
} from "../../../utils/formatting";

const DISPLAY_FALLBACK = "-";

const formatRedeemableAmount = (amount: number) => {
  const amountDisplay = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const unit = calculateMostAppropriateUnit(amount);
  const amountCompactDisplay = unit
    ? `${(amount / unit.divisor).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 3,
      })}${unit.symbol}`
    : DISPLAY_FALLBACK;

  return (
    <>
      <span className="CollateralCompositionTable-col--value-full">
        {amountDisplay}
      </span>
      <strong className="CollateralCompositionTable-col--value-compact">
        {amountCompactDisplay}
      </strong>
    </>
  );
};

const IdentityDepositoryRow = ({
  collateral,
  redeemableAmount,
}: DepositoryInfo) => {
  const redeemableAmountDisplay =
    redeemableAmount !== undefined
      ? formatRedeemableAmount(redeemableAmount)
      : DISPLAY_FALLBACK;

  return (
    <tr key={`identity-${collateral}`}>
      <td>Solana</td>
      <td className="CollateralCompositionTable-col--asset">
        <strong>{collateral}</strong>
      </td>
      <td className="CollateralCompositionTable-col--strategy">
        Sovereign USDC
      </td>
      <td>{redeemableAmountDisplay}</td>
    </tr>
  );
};

const MercurialVaultDepositoryRow = ({
  collateral,
  redeemableAmount,
}: DepositoryInfo) => {
  const redeemableAmountDisplay =
    redeemableAmount !== undefined
      ? formatRedeemableAmount(redeemableAmount)
      : DISPLAY_FALLBACK;

  return (
    <tr key={`mercurial-vault-${collateral}`}>
      <td>Solana</td>
      <td className="CollateralCompositionTable-col--asset">
        <strong>{collateral}</strong>
      </td>
      <td className="CollateralCompositionTable-col--strategy">
        Mercurial vault
      </td>
      <td>{redeemableAmountDisplay}</td>
    </tr>
  );
};

const CredixLpDepositoryRow = ({
  collateral,
  redeemableAmount,
}: DepositoryInfo) => {
  const redeemableAmountDisplay =
    redeemableAmount !== undefined
      ? formatRedeemableAmount(redeemableAmount)
      : DISPLAY_FALLBACK;

  return (
    <tr key={`credix-lp-${collateral}`}>
      <td>Solana</td>
      <td className="CollateralCompositionTable-col--asset">
        <strong>{collateral}</strong>
      </td>
      <td className="CollateralCompositionTable-col--strategy">Credix Lp</td>
      <td>{redeemableAmountDisplay}</td>
    </tr>
  );
};

const CollateralCompositionTotalBalance = ({
  balance,
  title,
}: {
  balance?: number;
  title: string;
}) => {
  if (typeof balance === "undefined") {
    return null;
  }

  return (
    <span className="CollateralCompositionTotalBalance">
      <strong className="CollateralCompositionTotalBalance-title">
        {title}
      </strong>
      <strong>{renderAmount(balance)}</strong>
    </span>
  );
};

const CollateralComposition = ({
  identityDepository,
  mercurialVaultDepository,
  credixLpDepository,
}: {
  identityDepository: DepositoryInfo | null;
  mercurialVaultDepository: DepositoryInfo | null;
  credixLpDepository: DepositoryInfo | null;
}) => {
  const total =
    (identityDepository?.redeemableAmount ?? 0) +
    (mercurialVaultDepository?.redeemableAmount ?? 0) +
    (credixLpDepository?.redeemableAmount ?? 0);

  return (
    <div>
      <h3 className="CollateralCompositionTable-title">
        <span>UXD Collateral Composition</span>

        <CollateralCompositionTotalBalance balance={total} title="Total" />
      </h3>
      <Section title=" ">
        <table className="CollateralCompositionTable">
          <colgroup>
            <col />
            <col className="CollateralCompositionTable-col--blockchain" />
            <col className="CollateralCompositionTable-col--asset" />
            <col className="CollateralCompositionTable-col--funding" />
            <col className="CollateralCompositionTable-col--address" />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>Blockchain</th>
              <th className="CollateralCompositionTable-col--asset">Asset</th>
              <th className="CollateralCompositionTable-col--strategy">
                <abbr title="Asset Liability Management Strategy">
                  Strategy
                </abbr>
              </th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            {identityDepository ? (
              <IdentityDepositoryRow {...identityDepository} />
            ) : null}
            {credixLpDepository ? (
              <CredixLpDepositoryRow {...credixLpDepository} />
            ) : null}
            {mercurialVaultDepository ? (
              <MercurialVaultDepositoryRow {...mercurialVaultDepository} />
            ) : null}
          </tbody>
        </table>
      </Section>
    </div>
  );
};

export default CollateralComposition;
