import { Component } from "react";
import logger from "../../../utils/logger";

interface Props {}

interface ErrorState {
  thrownError?: boolean;
}

export default class ErrorBoundary extends Component<Props, ErrorState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      thrownError: false,
    };
  }

  static getDerivedStateFromError(error: unknown) {
    return { thrownError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    logger.error(error, { info: errorInfo });
  }

  render() {
    if (this.state.thrownError) {
      // relies on the global error page styling for now
      return (
        <div className="page-error-container">
          <p>
            Something went wrong...
            <br />
            Please <a href={window.location.href}>refresh the page</a>.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
