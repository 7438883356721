import AUXDBaseConfig from "./uxd.base";

import { Chain, ClusterName } from "./types";

import { Contracts, Tokens } from "./arbitrum.types";
import { SlippageOptions } from "../components/slippage/Slippage";

export default abstract class AUXDArbitrumBaseConfig extends AUXDBaseConfig {
  public readonly chain: Chain = "arbitrum";

  public abstract readonly clusterName: ClusterName;
  public abstract readonly defaultSlippage: SlippageOptions;
  public abstract readonly contracts: Contracts;
  public abstract readonly rpcProviderUrl: string;
  public abstract readonly tokens: Tokens;
}
