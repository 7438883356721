import { Connection, PublicKey } from "@solana/web3.js";
import {
  SingleSideStakingClient,
  StakingCampaign,
  StakingOption,
  StakingAccount,
} from "@uxdprotocol/uxd-staking-client";
import { WalletAdapter } from "../adapters/WalletAdapter";
import { ISolanaConfiguration } from "../config/solana.types";
import { nativeToUi } from "../utils/amount";
import logger from "../utils/logger";
import { ABaseClientService } from "./baseClientService";
import { TXN_OPTS } from "./constants";

export class StakingClientService extends ABaseClientService {
  constructor(
    public readonly config: ISolanaConfiguration,
    public readonly connection: Connection,
    protected readonly client: SingleSideStakingClient,
    protected readonly campaignPda: PublicKey,
    protected campaign: StakingCampaign
  ) {
    super(config, connection);
  }

  public static async initialize(
    config: ISolanaConfiguration,
    connection: Connection
  ): Promise<StakingClientService> {
    const programId = config.getPrograms().staking.address;
    const campaignPda = config.getStakingCampaignPDA();

    const client = SingleSideStakingClient.load({
      connection,
      programId,
    });

    const campaign = await client.getOnChainStakingCampaign(campaignPda);

    return new StakingClientService(
      config,
      connection,
      client,
      campaignPda,
      campaign
    );
  }

  public getStakingOptions(): StakingOption[] {
    return this.client.getStakingCampaignValidStakingOptions(this.campaign);
  }

  public getStakingAccountsRequiringMigrationFromV1ToV2(
    stakingAccounts: StakingAccount[]
  ): StakingAccount[] {
    return this.client.getStakingAccountsRequiringMigrationFromV1ToV2({
      stakingCampaign: this.campaign,
      stakingAccounts,
    });
  }

  public getUserValidStakingAccounts(
    user: PublicKey | null
  ): Promise<StakingAccount[]> {
    if (!user) {
      throw new Error("walletAdapter have no publicKey");
    }

    return this.client.getUserValidStakingAccounts({
      user,
      stakingCampaignPda: this.campaignPda,
    });
  }

  public async stake({
    amount,
    option,
    walletAdapter,
  }: {
    amount: number;
    option: number;
    walletAdapter: WalletAdapter;
  }): Promise<string> {
    const {
      adapter,
      adapter: { publicKey: user },
    } = walletAdapter;

    if (!user) {
      throw new Error("walletAdapter have no publicKey.");
    }

    const stakingAccountPda = this.client.findStakingAccountPda({
      stakingCampaignPda: this.campaignPda,
      user,
      stakingOptionIdentifier: option,
    });

    const instructions = [];

    const initialized = await this.client.isStakingAccountInitialized(
      stakingAccountPda
    );

    if (!initialized) {
      const initializeStakingAccountIx =
        await this.client.createInitializeStakingAccountInstruction({
          user,
          stakingOptionIdentifier: option,
          stakingCampaignPda: this.campaignPda,
          options: TXN_OPTS,
        });

      instructions.push(initializeStakingAccountIx);
    }

    const stakeIx = await this.client.createStakeInstruction({
      user,
      stakingOptionIdentifier: option,
      stakingCampaignPda: this.campaignPda,
      uiStakingAmount: amount,
      options: TXN_OPTS,
    });

    instructions.push(stakeIx);

    const { transaction, blockhash, lastValidBlockHeight } =
      await this.makeTransaction(instructions, user);

    const signature = await this.sendTransaction(transaction, adapter);
    await this.confirmTransaction({
      signature,
      blockhash,
      lastValidBlockHeight,
    });

    return signature;
  }

  public async migrateUserStakingAccountsFromV1ToV2(
    walletAdapter: WalletAdapter
  ): Promise<string> {
    const {
      adapter,
      adapter: { publicKey: user },
    } = walletAdapter;

    if (!user) {
      throw new Error("walletAdapter have no publicKey.");
    }

    const stakingAccounts = await this.getUserValidStakingAccounts(user);

    const stakingAccountsRequiringMigrationFromV1ToV2 =
      this.getStakingAccountsRequiringMigrationFromV1ToV2(stakingAccounts);

    if (!stakingAccountsRequiringMigrationFromV1ToV2.length) {
      throw new Error(
        "There are no staking account requiring migration from v1 to v2."
      );
    }

    const instructions = await Promise.all(
      stakingAccountsRequiringMigrationFromV1ToV2.map(
        ({ stakingOptionIdentifier }) =>
          this.client.createMigrateStakingAccountFromV1ToV2Instruction({
            stakingCampaignPda: this.campaignPda,

            stakingAccountPda: this.client.findStakingAccountPda({
              stakingCampaignPda: this.campaignPda,
              user,
              stakingOptionIdentifier,
            }),

            options: TXN_OPTS,
          })
      )
    );

    const { transaction, blockhash, lastValidBlockHeight } =
      await this.makeTransaction(instructions, user);
    const signature = await this.sendTransaction(transaction, adapter);
    await this.confirmTransaction({
      signature,
      blockhash,
      lastValidBlockHeight,
    });

    return signature;
  }

  public async unstake({
    option,
    walletAdapter,
  }: {
    option: number;
    walletAdapter: WalletAdapter;
  }): Promise<string> {
    const {
      adapter,
      adapter: { publicKey: user },
    } = walletAdapter;

    if (!user) {
      const err = new Error("walletAdapter have no publicKey.");
      throw err;
    }

    const stakingAccountPda = this.client.findStakingAccountPda({
      stakingCampaignPda: this.campaignPda,
      user,
      stakingOptionIdentifier: option,
    });

    const stakingAccount = await this.client.getOnChainStakingAccount(
      stakingAccountPda
    );

    const instructions = [];

    // If the account require migration from v2 to v1, add the instruction
    if (
      this.client.isStakingAccountRequiringMigrationFromV1ToV2({
        stakingAccount,
        stakingCampaign: this.campaign,
      })
    ) {
      const migrationIx =
        await this.client.createMigrateStakingAccountFromV1ToV2Instruction({
          stakingAccountPda,
          stakingCampaignPda: this.campaignPda,
          options: TXN_OPTS,
        });

      instructions.push(migrationIx);
    }

    const unstakeIx = await this.client.createUnstakeInstruction({
      user,
      stakingCampaignPda: this.campaignPda,
      stakingAccountPda,
      options: TXN_OPTS,
    });

    instructions.push(unstakeIx);

    const { transaction, blockhash, lastValidBlockHeight } =
      await this.makeTransaction(instructions, user);

    const signature = await this.sendTransaction(transaction, adapter);

    await this.confirmTransaction({
      signature,
      blockhash,
      lastValidBlockHeight,
    });

    return signature;
  }

  public getCampaignEndTimestamp() {
    if (!this.campaign.endTs) {
      return null;
    }

    return this.campaign.endTs.toNumber() * 1_000;
  }

  public async fetchStakedVaultBalance() {
    try {
      const {
        value: { uiAmount: balance = null },
      } = await this.connection.getTokenAccountBalance(
        this.campaign.stakedVault,
        TXN_OPTS.commitment
      );

      return balance;
    } catch (err: unknown) {
      // Do not log as error / report to Sentry if there's just no token account
      if (
        err instanceof Error &&
        err.message ===
          "failed to get token account balance: Invalid param: could not find account"
      ) {
        logger.debug(err);
      } else {
        logger.error(err);
      }

      return null;
    }
  }

  public async fetchStakedAmount(): Promise<number> {
    // To get the staked amount, we have to
    // 1/ take a look at stakedAmount in the staking campaign to get staked tokens for v2 staking accounts
    // 2/ take a look at the stakedVault balance to get staked tokens for v1 staking accounts

    const [refreshedCampaign, stakedTokenV1] = await Promise.all([
      this.client.getOnChainStakingCampaign(this.campaignPda),
      this.fetchStakedVaultBalance(),
    ]);

    this.campaign = refreshedCampaign;

    const stakedTokenV2 = nativeToUi(
      this.campaign.stakedAmount,
      this.campaign.stakedMintDecimals
    );

    return (stakedTokenV1 ?? 0) + stakedTokenV2;
  }
}
