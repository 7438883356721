import Button from "../button/Button";

type Props = {
  label: string;
  className: string;
  handleClick: () => void;
  disabled: boolean;
};

const TabButton = ({ label, className, handleClick, disabled }: Props) => {
  return (
    <Button
      type="button"
      className={className}
      onClick={() => handleClick()}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default TabButton;
