import { ReactNode } from "react";
import { TokenInfo } from "../../../config/optimism.types";
import InputAmount from "./InputAmount";

type Props = {
  id: string;
  name: string;
  amount: string;
  token: TokenInfo;
  handleChange: (value: string) => void;
  disabled?: boolean;
  reset?: boolean;
  children?: (node: ReactNode) => ReactNode;
};

const InputAmountOptimism = ({
  id,
  name,
  amount,
  token,
  handleChange,
  disabled,
  reset,
  children,
}: Props) => {
  return (
    <InputAmount
      id={id}
      name={name}
      amount={amount}
      assetName={token.name}
      assetCurrency={token.displayName}
      assetDecimals={token.decimals}
      handleChange={handleChange}
      disabled={disabled}
      reset={reset}
    >
      {children}
    </InputAmount>
  );
};

export default InputAmountOptimism;
