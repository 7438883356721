import { ethers } from "ethers";
import {
  calculateMinAmountOut,
  UXDClient,
  PerpWrapper,
  Address,
} from "@uxdprotocol/uxd-evm-client";
import { MetaMaskInpageProvider } from "@metamask/providers";
import {
  IOptimismConfiguration,
  Tickers,
  TokenInfo,
} from "../config/optimism.types";
import logger from "../utils/logger";
import { MaybePromise } from "../types";

export class UXDClientService {
  constructor(
    protected provider:
      | ethers.providers.ExternalProvider
      | MetaMaskInpageProvider
      | null,
    protected rpcProvider: ethers.providers.JsonRpcProvider,
    protected config: IOptimismConfiguration,
    protected client: UXDClient,
    protected perpWrapper: PerpWrapper
  ) {}

  public static initialize = async ({
    config,
    rpcProvider,
    provider,
  }: {
    config: IOptimismConfiguration;
    rpcProvider: ethers.providers.JsonRpcProvider;
    provider: MaybePromise<
      ethers.providers.ExternalProvider | MetaMaskInpageProvider | null
    >;
  }): Promise<UXDClientService> => {
    const client = new UXDClient({
      provider: rpcProvider,
      controller: config.contracts.controller.address,
      redeemable: config.tokens.UXD.address,
    });

    const resolvedProvider = provider ? await provider : null;

    const perpWrapper = await PerpWrapper.initialize(
      config.rpcProviderUrl,
      config.chainId
    );

    return new UXDClientService(
      resolvedProvider,
      rpcProvider,
      config,
      client,
      perpWrapper
    );
  };

  public async getEthBalance(account: string) {
    const balance = await this.rpcProvider.getBalance(account);
    return Number(ethers.utils.formatEther(balance));
  }

  public async getTokenBalance(params: {
    token: Address;
    account: Address;
    decimals: number;
  }) {
    return this.client.uxdController.getTokenBalance(params);
  }

  public getCollateralPerpPriceUI(collateral: keyof Tickers) {
    return this.perpWrapper.getMarkPrice(
      this.config.tickers[collateral].symbol
    );
  }

  private getSigner() {
    return new ethers.providers.Web3Provider(
      this.provider! as unknown as ethers.providers.ExternalProvider,
      "any"
    ).getSigner();
  }

  public async mint({
    collateralAmount,
    collateralPrice,
    slippage,
    collateral,
    collateralDecimals,
  }: {
    // The amount of collateral used to mint
    collateralAmount: number;
    collateralPrice: number;
    slippage: number;
    collateral: TokenInfo["address"];
    collateralDecimals: number;
  }): Promise<string> {
    const signer = this.getSigner();

    const { decimals: redeemableDecimals } =
      window.__UXD__.optimism.config.tokens.UXD;

    const minAmountOut = calculateMinAmountOut(
      collateralAmount * collateralPrice,
      slippage,
      redeemableDecimals
    );

    logger.log("Optimism: Attempting to mint ...", {
      signer,
      collateralAmount,
      minAmountOut,
      collateral,
    });

    const tx = await this.client.uxdController.mint({
      signer,
      amount: collateralAmount,
      minAmountOut,
      redeemableDecimals,
      collateralDecimals,

      // If collateral is ETH mint regularly
      collateral:
        collateral === this.config.tokens.ETH.address ? undefined : collateral,
    });

    const receipt = await tx.wait();
    return receipt.transactionHash;
  }

  public async redeem({
    redeemableAmount,
    slippage,
    collateral,
    collateralDecimals,
    collateralPrice,
  }: {
    // The amount to redeemable token being redeemed
    redeemableAmount: number;
    slippage: number;
    collateral: TokenInfo["address"];
    collateralDecimals: number;
    collateralPrice: number;
  }): Promise<string> {
    const signer = this.getSigner();

    const redeemableValue = (1 / collateralPrice) * redeemableAmount;
    const minAmountOut = calculateMinAmountOut(
      redeemableValue,
      slippage,
      collateralDecimals
    );

    logger.log("Optimism: Attempting to redeem ...", {
      signer,
      redeemableAmount,
      minAmountOut,
      collateral,
      collateralPrice,
    });

    const { decimals: redeemableDecimals } =
      window.__UXD__.optimism.config.tokens.UXD;

    await this.client.uxdController.approveUXDTransfer({
      amount: redeemableAmount,
      signer,
      spender: this.config.contracts.controller.address,
      decimals: redeemableDecimals,
    });

    const tx = await this.client.uxdController.redeem({
      signer,
      amount: redeemableAmount,
      minAmountOut,
      redeemableDecimals,
      collateralDecimals,
      collateral:
        collateral === this.config.tokens.ETH.address ? undefined : collateral,
    });
    const receipt = await tx.wait();
    return receipt.transactionHash;
  }
}
