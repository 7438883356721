import ActionButton from "../../common/action-button/ActionButton";
import { SwapAction } from "../../../types";

import "./SwapButton.scss";
import { ReactNode } from "react";

type Props = {
  handleClick: () => void;
  action: SwapAction;
  connected: boolean;
  loading: boolean;
  valid: boolean;
  formId: string;
  disabled?: boolean;
  children?: ReactNode;
};

function getLabel(action: SwapAction, loading: boolean) {
  if (!loading && action === "mint") {
    return "Mint";
  }

  if (loading && action === "mint") {
    return "Minting ...";
  }

  if (!loading && action === "redeem") {
    return "Redeem";
  }

  if (loading && action === "redeem") {
    return "Redeeming ...";
  }

  throw new Error("SwapButton > getLabel() unknown action");
}

const SwapButton = ({
  handleClick,
  action,
  connected,
  loading,
  valid,
  formId,
  disabled,
  children,
}: Props) => {
  if (!connected) {
    return (
      <>
        <ActionButton
          color="green"
          label="Connect"
          onClick={handleClick}
          type="button"
        />
        {children}
      </>
    );
  }
  return (
    <ActionButton
      color="green"
      label={getLabel(action, loading)}
      disabled={!valid || loading || disabled}
      form={formId}
      type="submit"
    />
  );
};

export default SwapButton;
