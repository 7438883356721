import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  ComputeBudgetProgram,
  Connection,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { findATAAddrSync, SOL_DECIMALS } from "@uxd-protocol/uxd-client";
import { MintName, ISolanaConfiguration } from "../config/solana.types";
import logger from "../utils/logger";
import { WalletAdapter } from "../adapters/WalletAdapter";
import {
  UXDTransactionConfirmError,
  UXDTransactionSendError,
  UXDTransactionSignError,
} from "../utils/error";
import { nativeToUi } from "../utils/amount";
import { TXN_OPTS } from "./constants";
import { BN } from "@project-serum/anchor";

export abstract class ABaseClientService {
  constructor(
    public config: ISolanaConfiguration,
    protected connection: Connection
  ) {}

  public async getTokenBalance(
    walletAdapter: WalletAdapter,
    mintName: MintName
  ) {
    const mintKey = this.config.getMintInfo(mintName).mint;

    const {
      adapter: { publicKey: walletKey },
    } = walletAdapter;

    if (!walletKey) {
      const err = new Error("walletAdapter have no publicKey.");
      throw err;
    }

    if (mintKey.equals(NATIVE_MINT)) {
      return this.getTotalSOLBalance(walletKey);
    }

    const mintATA = findATAAddrSync(walletKey, mintKey)[0];

    try {
      const {
        value: { uiAmount: balance = null },
      } = await this.connection.getTokenAccountBalance(
        mintATA,
        TXN_OPTS.commitment
      );

      return balance;
    } catch (err: unknown) {
      // Do not log as error / report to Sentry if there's just no token account
      if (
        err instanceof Error &&
        err.message ===
          "failed to get token account balance: Invalid param: could not find account"
      ) {
        logger.debug(err);
      } else {
        logger.error(err);
      }
      return null;
    }
  }

  protected async getWSOLLamportsBalance(
    walletKey: PublicKey
  ): Promise<null | BN> {
    const mintATA = findATAAddrSync(walletKey, NATIVE_MINT)[0];

    try {
      const {
        value: { amount = null },
      } = await this.connection.getTokenAccountBalance(
        mintATA,
        TXN_OPTS.commitment
      );

      if (amount === null) {
        return null;
      }

      return new BN(amount);
    } catch (e) {
      // The user do not have WSOL
      return null;
    }
  }

  // Addition of SOL + WSOL balances
  public async getTotalSOLBalance(
    walletKey: PublicKey
  ): Promise<number | null> {
    const [{ value: lamportsSOLBalance }, lamportsWSOLBalance] =
      await Promise.all([
        // Get SOL balance
        this.connection.getBalanceAndContext(walletKey),

        // Get WSOL balance
        this.getWSOLLamportsBalance(walletKey),
      ]);

    if (lamportsWSOLBalance === null) {
      return nativeToUi(new BN(lamportsSOLBalance), SOL_DECIMALS);
    }

    const lamportsTotalSOLBalance = new BN(lamportsSOLBalance).add(
      lamportsWSOLBalance
    );

    return nativeToUi(lamportsTotalSOLBalance, SOL_DECIMALS);
  }

  protected async makeTransaction(
    instructions: TransactionInstruction[],
    feePayer: PublicKey,
    setComputeUnitLimit?: number
  ) {
    const { blockhash, lastValidBlockHeight } =
      await this.connection.getLatestBlockhash(TXN_OPTS.commitment);
    const transaction = new Transaction();
    if (setComputeUnitLimit) {
      transaction.add(
        ComputeBudgetProgram.setComputeUnitLimit({
          units: setComputeUnitLimit,
        })
      );
    }
    transaction.add(...instructions);
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = feePayer;
    return { transaction, blockhash, lastValidBlockHeight };
  }

  protected async sendTransaction(
    transaction: Transaction,
    adapter: WalletAdapter["adapter"]
  ) {
    try {
      transaction = await adapter.signTransaction(transaction);
    } catch (err: unknown) {
      const signError = new UXDTransactionSignError(err);
      throw signError;
    }

    const rawTransaction = transaction.serialize({
      requireAllSignatures: false,
      verifySignatures: false,
    });

    try {
      const signature = await this.connection.sendRawTransaction(
        rawTransaction,
        TXN_OPTS
      );
      return signature;
    } catch (err: unknown) {
      const sendError = new UXDTransactionSendError(err);
      throw sendError;
    }
  }

  protected async confirmTransaction({
    signature,
    blockhash,
    lastValidBlockHeight,
  }: {
    signature: string;
    blockhash: string;
    lastValidBlockHeight: number;
  }) {
    try {
      await this.connection.confirmTransaction({
        signature,
        blockhash,
        lastValidBlockHeight,
      });
    } catch (err) {
      const confirmError = new UXDTransactionConfirmError({ err, signature });
      throw confirmError;
    }
  }
}

export function createATAItx(
  walletKey: PublicKey,
  mintKey: PublicKey
): TransactionInstruction {
  const assocKey = findATAAddrSync(walletKey, mintKey)[0];

  return new TransactionInstruction({
    keys: [
      { pubkey: walletKey, isSigner: true, isWritable: true },
      { pubkey: assocKey, isSigner: false, isWritable: true },
      { pubkey: walletKey, isSigner: false, isWritable: false },
      { pubkey: mintKey, isSigner: false, isWritable: false },
      {
        pubkey: SystemProgram.programId,
        isSigner: false,
        isWritable: false,
      },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      {
        pubkey: SYSVAR_RENT_PUBKEY,
        isSigner: false,
        isWritable: false,
      },
    ],
    programId: ASSOCIATED_TOKEN_PROGRAM_ID,
    data: Buffer.alloc(0),
  });
}

export function transferSolItx(
  fromKey: PublicKey,
  toKey: PublicKey,
  amountNative: number
): TransactionInstruction {
  return SystemProgram.transfer({
    fromPubkey: fromKey,
    toPubkey: toKey,
    lamports: Math.floor(amountNative),
  });
}
