import { ThunkAction, ThunkDispatch as Dispatch } from "redux-thunk";

import { Actions } from "./types";
import { ACTION_TYPES } from "./constants";
import { GetState, RootState } from "../store";
import { State } from "../types";
import { MintName as SolanaMintName } from "../config/solana.types";
import { TokenName as OptimismTokenName } from "../config/optimism.types";
import { TokenName as ArbitrumTokenName } from "../config/arbitrum.types";
import { WalletAdapter, WalletAdapterName } from "../adapters/WalletAdapter";

async function getOptimismTokenBalances(
  account: string,
  tokens: OptimismTokenName[]
) {
  const balances = await Promise.all(
    tokens.map(async (token) => {
      const info = window.__UXD__.optimism.config.tokens[token];

      if (token === "ETH") {
        return window.__UXD__.optimism.services.UXD.getEthBalance(account);
      }

      return window.__UXD__.optimism.services.UXD.getTokenBalance({
        account,
        token: info.address,
        decimals: info.decimals,
      });
    })
  );

  return balances;
}

async function getArbitrumTokenBalances(
  account: string,
  tokens: ArbitrumTokenName[]
) {
  const balances = await Promise.all(
    tokens.map(async (token) => {
      const info = window.__UXD__.arbitrum.config.tokens[token];

      return window.__UXD__.arbitrum.services.UXD.getTokenBalance({
        account,
        token: info.address,
        decimals: info.decimals,
      });
    })
  );

  return balances;
}

async function getSolanaTokenBalances(
  walletAdapter: WalletAdapter,
  mintNames: SolanaMintName[]
) {
  const balances = await Promise.all(
    mintNames.map(async (mintName) => {
      const balance = await window.__UXD__.solana.services.UXD.getTokenBalance(
        walletAdapter,
        mintName
      );

      const { decimals } = window.__UXD__.solana.config.getMintInfo(mintName);

      const newBalance =
        balance !== null ? Number(balance.toFixed(decimals)) : null;

      return newBalance;
    })
  );

  return balances;
}

export const getBalanceAction =
  (
    ...tokens: (SolanaMintName | OptimismTokenName | ArbitrumTokenName)[]
  ): ThunkAction<void, RootState, void, Actions.SetBalance> =>
  async (
    dispatch: Dispatch<State, void, Actions.SetBalance>,
    getState: GetState
  ): Promise<void> => {
    const walletAdapterName = ((state: State) => state.wallet)(getState());

    if (!walletAdapterName) {
      throw new Error("Missing wallet in `state.wallet`.");
    }

    let getBalancesFunction = (): ReturnType<
      | typeof getOptimismTokenBalances
      | typeof getArbitrumTokenBalances
      | typeof getSolanaTokenBalances
    > => {
      throw new Error("Unreachable");
    };
    switch (window.__UXD__.config.chain) {
      case "optimism": {
        const account =
          typeof walletAdapterName === "object"
            ? walletAdapterName.account
            : (() => {
                throw new Error("Unreachable");
              })();
        getBalancesFunction = getOptimismTokenBalances.bind(
          null,
          account,
          tokens as OptimismTokenName[]
        );
        break;
      }
      case "arbitrum": {
        const account =
          typeof walletAdapterName === "object"
            ? walletAdapterName.account
            : (() => {
                throw new Error("Unreachable");
              })();
        getBalancesFunction = getArbitrumTokenBalances.bind(
          null,
          account,
          tokens as ArbitrumTokenName[]
        );
        break;
      }
      case "solana":
      default: {
        const walletAdapter = window.__UXD__.solana.config.getWalletAdapter(
          walletAdapterName as WalletAdapterName
        );
        getBalancesFunction = getSolanaTokenBalances.bind(
          null,
          walletAdapter,
          tokens as SolanaMintName[]
        );
      }
    }

    const balances = await getBalancesFunction();
    const payload = tokens.reduce((acc, token, index) => {
      acc[token] = balances[index];

      return acc;
    }, {} as Actions.SetBalance["payload"]);

    dispatch({
      type: ACTION_TYPES.SET_BALANCE,
      payload,
    });
  };
