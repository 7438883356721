import { ReactNode } from "react";
import {
  CollateralMintInfo,
  CollateralMintName,
  MintInfo,
} from "../../../config/solana.types";
import { CLASS_NAME as COLLATERAL_FORM_CLASS_NAME } from "./CollateralForm";
import CollateralMintDropdown from "../collateral-mint-dropdown/CollateralMintDropdown";
import InputMaxButtonMint from "../input-max-button/InputMaxButtonMint";
import InputAmountMint from "../input-amount/InputAmountMint";

type BaseProps = {
  id: string;
  name: string;
  label: string;
  value: string;
  handleChange: (amount: string) => void;
  maxButtonEnabled: boolean;
  connected: boolean;
  mintInfo: MintInfo;
};

type WithCollateralMintDropdownProps = BaseProps & {
  availableMints: CollateralMintInfo[];
  handleMintChange: (newMintName: CollateralMintName) => void;
};

type Props = BaseProps | WithCollateralMintDropdownProps;

const CollateralFormInput = ({
  id,
  name,
  label,
  value,
  handleChange,
  maxButtonEnabled,
  connected,
  mintInfo,
  ...props
}: Props) => {
  const withDropdown =
    "availableMints" in props &&
    props.availableMints.length > 1 &&
    "handleMintChange" in props &&
    typeof props.handleMintChange !== "undefined";

  return (
    <>
      <span className={`${COLLATERAL_FORM_CLASS_NAME}-label-wrapper`}>
        <label htmlFor={id}>{label}</label>
        <InputMaxButtonMint
          activeMint={mintInfo}
          handleClick={maxButtonEnabled ? handleChange : undefined}
        />
      </span>
      <InputAmountMint
        id={id}
        name={name}
        amount={value}
        handleChange={handleChange}
        reset={connected}
        mint={mintInfo}
      >
        {withDropdown
          ? (anchorLabelNode: ReactNode) => (
              <CollateralMintDropdown
                collateralMintName={(mintInfo as CollateralMintInfo).name}
                onCollateralMintChange={
                  (props as WithCollateralMintDropdownProps).handleMintChange
                }
                availableCollateralMints={
                  (props as WithCollateralMintDropdownProps).availableMints
                }
              >
                {anchorLabelNode}
              </CollateralMintDropdown>
            )
          : undefined}
      </InputAmountMint>
    </>
  );
};

export default CollateralFormInput;
