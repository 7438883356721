import { Connection } from "@solana/web3.js";
import {
  UXDClient,
  MercurialVaultDepository,
  MercurialVaultDepositoryAccount,
} from "@uxd-protocol/uxd-client";
import { ISolanaConfiguration } from "../config/solana.types";
import { ABaseClientService } from "./baseClientService";
import { TXN_OPTS } from "./constants";

export class MercurialClientService extends ABaseClientService {
  private client: UXDClient;
  private depository: MercurialVaultDepository;
  private onChainAccount: MercurialVaultDepositoryAccount;

  constructor(
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient,
    depository: MercurialVaultDepository,
    onChainAccount: MercurialVaultDepositoryAccount
  ) {
    super(config, connection);
    this.client = client;
    this.depository = depository;
    this.onChainAccount = onChainAccount;
  }

  public static initialize = async (
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient
  ): Promise<MercurialClientService> => {
    const uxdProgramId = config.getPrograms().uxd.address;

    const {
      mint,
      name,
      symbol = "USDC",
      decimals,
    } = config.getMintInfo("USDC_MERCURIAL");
    const collateralMint = { mint, name, symbol: symbol as string, decimals };
    // create UXD which has access to mint/redeem instructions
    const depository = await MercurialVaultDepository.initialize({
      connection,
      collateralMint,
      uxdProgramId,
    });

    return new MercurialClientService(
      config,
      connection,
      client,
      depository,
      // Load the account once at start. Useful to get minting/redeeming fee and else
      await depository.getOnchainAccount(connection, TXN_OPTS)
    );
  };

  public getMercurialVaultDepository(): MercurialVaultDepository {
    return this.depository;
  }

  public getMercurialVaultDepositoryAccount(): Promise<MercurialVaultDepositoryAccount> {
    return this.depository.getOnchainAccount(this.connection, TXN_OPTS);
  }

  public getMercurialVaultDepositoryMintingFeeInBps(): number {
    return this.onChainAccount.mintingFeeInBps;
  }

  public getMercurialVaultDepositoryRedeemingFeeInBps(): number {
    return this.onChainAccount.redeemingFeeInBps;
  }
}
