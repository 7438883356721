import { MouseEvent } from "react";
import clsx from "clsx";
import Button from "../button/Button";
import "./ActionButton.scss";

type Props = {
  color: string;
  label: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type: "submit" | "reset" | "button";
  form?: string;
};

const ActionButton = ({
  color,
  label,
  onClick,
  disabled = false,
  type,
  form,
}: Props) => {
  const className = clsx(["ActionButton", `ActionButton-${color}`]);
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      form={form}
    >
      {label}
    </Button>
  );
};

export default ActionButton;
