import { MintName } from "../config/solana.types";
import { calculateMinimumReceived } from "../solana/utils";
import { RootState } from "../store";
import { SwapAction } from "../types";
import { useSelector } from "./hooks";

const AMOUNT_SELECTORS = {
  mint: (state: RootState) => state.mint.redeemableAmount,
  redeem: (state: RootState) => state.redeem.collateralAmount,
} as const;

const useMinimumReceived = (
  action: SwapAction,
  collateralMintName: MintName
): number => {
  const counterpartMint = action === "redeem" ? collateralMintName : "UXD";
  const { decimals } =
    window.__UXD__.solana.config.getMintInfo(counterpartMint);

  const storedSlippage = useSelector((state) => state.slippage);
  const slippage =
    collateralMintName === "USDC_MERCURIAL"
      ? null
      : Number(storedSlippage) / 100;
  const amount = Number(useSelector(AMOUNT_SELECTORS[action]));

  return Number.parseFloat(
    calculateMinimumReceived({
      amount,
      slippage,
      decimals,
    })
  );
};

export default useMinimumReceived;
