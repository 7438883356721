import { SlippageOptions } from "../components/slippage/Slippage";
import {
  Contracts,
  IOptimismConfiguration,
  Tokens,
  Tickers,
} from "./optimism.types";
import { ClusterName } from "./types";
import AUXDOptimismBaseConfig from "./uxd.optimism.base";

class UXDOptimismTestnetConfig
  extends AUXDOptimismBaseConfig
  implements IOptimismConfiguration
{
  public readonly clusterName: ClusterName = "testnet";

  public readonly contracts: Contracts = {
    controller: {
      address: "0x7Db1f727f70e5Ad380D1fb76294a3eeF54dF2912",
    },
  };

  public readonly defaultSlippage: SlippageOptions = "0.1";

  public readonly rpcProviderUrl =
    "https://optimism-goerli.infura.io/v3/1b07c06cf0494e879d4eaa6f555cbd7f";

  // Goerli Optimism
  public readonly chainId = 420;

  public readonly tokens: Tokens = {
    ETH: {
      name: "ETH",
      displayName: "ETH",
      decimals: 18,
      address: "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
    },
    UXD: {
      name: "UXD",
      displayName: "UXD",
      decimals: 18,
      address: "0x8A9915F28382FBaca5b37dC5b284c2b090727500",
    },
    WETH: {
      name: "WETH",
      displayName: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
    },
  };

  public readonly tickers: Tickers = {
    ETH: {
      symbol: "ETHUSD",
    },
    WETH: {
      symbol: "ETHUSD",
    },
  };
}

const UXDOptimismTestnetConfigInstance = new UXDOptimismTestnetConfig();

export default UXDOptimismTestnetConfigInstance;
