import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";

export type LoadingState = boolean;

const INITIAL_STATE = false;

const loadingReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): LoadingState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING:
      return action.payload;

    default:
      return state;
  }
};

export default loadingReducer;
