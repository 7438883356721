import { Connection, PublicKey, TransactionInstruction } from "@solana/web3.js";
import { UXDClient, findATAAddrSync } from "@uxd-protocol/uxd-client";
import { ISolanaConfiguration } from "../config/solana.types";
import { ABaseClientService } from "./baseClientService";
import { TXN_OPTS } from "./constants";
import { IdentityClientService } from "./identityClientService";
import { MercurialClientService } from "./mercurialClientService";
import { CredixClientService } from "./credixClientService";
import { WalletAdapter } from "../adapters/WalletAdapter";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";
import { UXDClientService } from "./uxdClientService";

export class RouterClientService extends ABaseClientService {
  private client: UXDClient;
  private uxd: UXDClientService;
  private identity: IdentityClientService;
  private mercurial: MercurialClientService;
  private credix: CredixClientService;
  private collateralMint: PublicKey;

  constructor(
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient,
    uxd: UXDClientService,
    identity: IdentityClientService,
    mercurial: MercurialClientService,
    credix: CredixClientService,
    collateralMint: PublicKey
  ) {
    super(config, connection);
    this.client = client;
    this.uxd = uxd;
    this.identity = identity;
    this.mercurial = mercurial;
    this.credix = credix;
    this.collateralMint = collateralMint;
  }

  public static initialize = async (
    config: ISolanaConfiguration,
    connection: Connection,
    client: UXDClient,
    uxd: UXDClientService,
    identity: IdentityClientService,
    mercurial: MercurialClientService,
    credix: CredixClientService
  ): Promise<RouterClientService> => {
    const identityDepositoryCollateralMint =
      identity.getIdentityDepository().collateralMint;
    const mercurialVaultDepositoryCollateralMint =
      mercurial.getMercurialVaultDepository().collateralMint.mint;
    const credixLpDepositoryCollateralMint =
      credix.getCredixLpDepository().collateralMint;

    if (
      identityDepositoryCollateralMint !==
      mercurialVaultDepositoryCollateralMint
    ) {
      console.warn(
        "Identity depository collateral mint is different than mercurial vault depository collateral mint",
        identityDepositoryCollateralMint,
        mercurialVaultDepositoryCollateralMint
      );
    }
    if (identityDepositoryCollateralMint !== credixLpDepositoryCollateralMint) {
      console.warn(
        "Identity depository collateral mint is different than credix lp depository collateral mint",
        identityDepositoryCollateralMint,
        credixLpDepositoryCollateralMint
      );
    }

    const collateralMint = identityDepositoryCollateralMint;

    return new RouterClientService(
      config,
      connection,
      client,
      uxd,
      identity,
      mercurial,
      credix,
      collateralMint
    );
  };

  public getMintingFeeInBps(): number {
    // We display the worst case scenario
    return Math.max(
      this.identity.getIdentityDepositoryMintingFeeInBps(),
      this.mercurial.getMercurialVaultDepositoryMintingFeeInBps(),
      this.credix.getCredixLpDepositoryMintingFeeInBps()
    );
  }

  public getRedeemingFeeInBps(): number {
    // We display the worst case scenario
    return Math.max(
      this.identity.getIdentityDepositoryRedeemingFeeInBps(),
      this.mercurial.getMercurialVaultDepositoryRedeemingFeeInBps(),
      this.credix.getCredixLpDepositoryRedeemingFeeInBps()
    );
  }

  private async prepareMintInstructions(
    amount: number, // Collateral UI Units
    walletKey: PublicKey
  ): Promise<TransactionInstruction[]> {
    const controller = this.uxd.getController();

    const instructions = [];

    // Create the redeemable ATA if it doesn't exist
    const [redeemableATA] = findATAAddrSync(
      walletKey,
      controller.redeemableMintPda
    );
    const redeemableATAInfo = await this.connection.getAccountInfo(
      redeemableATA
    );
    if (!redeemableATAInfo || !redeemableATAInfo.lamports) {
      const ix = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        controller.redeemableMintPda,
        redeemableATA,
        walletKey,
        walletKey
      );
      instructions.push(ix);
    }

    // Mint Redeemable IX
    const mintIx = this.client.createMintInstruction(
      controller,
      this.identity.getIdentityDepository(),
      this.mercurial.getMercurialVaultDepository(),
      this.credix.getCredixLpDepository(),
      walletKey,
      amount,
      TXN_OPTS
    );
    instructions.push(mintIx);

    return instructions;
  }

  public async mint(
    amount: number, // Collateral UI Units
    walletAdapter: WalletAdapter
  ): Promise<string> {
    const walletKey = walletAdapter.adapter.publicKey;
    if (!walletKey) {
      const err = new Error("walletAdapter have no publicKey.");
      throw err;
    }
    const instructions = await this.prepareMintInstructions(amount, walletKey);
    const { transaction, blockhash, lastValidBlockHeight } =
      await this.makeTransaction(instructions, walletKey, 800_000);
    const signature = await this.sendTransaction(
      transaction,
      walletAdapter.adapter
    );
    await this.confirmTransaction({
      signature,
      blockhash,
      lastValidBlockHeight,
    });
    return signature;
  }

  private async prepareRedeemInstructions(
    amount: number, // Redeemable UI Units
    walletKey: PublicKey
  ): Promise<TransactionInstruction[]> {
    const controller = this.uxd.getController();

    const instructions = [];

    // Create the collateral ATA if it doesn't exist
    const [collateralATA] = findATAAddrSync(walletKey, this.collateralMint);
    const collateralATAInfo = await this.connection.getAccountInfo(
      collateralATA
    );
    if (!collateralATAInfo || !collateralATAInfo.lamports) {
      const ix = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        controller.redeemableMintPda,
        collateralATA,
        walletKey,
        walletKey
      );
      instructions.push(ix);
    }

    // Redeem collateral IX
    const redeemFromCredixLpDepositoryIx = this.client.createRedeemInstruction(
      controller,
      this.identity.getIdentityDepository(),
      this.mercurial.getMercurialVaultDepository(),
      this.credix.getCredixLpDepository(),
      walletKey,
      amount,
      TXN_OPTS
    );
    instructions.push(redeemFromCredixLpDepositoryIx);

    return instructions;
  }

  public async redeem(
    amount: number, // Redeemable UI Units
    walletAdapter: WalletAdapter
  ): Promise<string> {
    const walletKey = walletAdapter.adapter.publicKey;
    if (!walletKey) {
      const err = new Error("walletAdapter have no publicKey.");
      throw err;
    }
    const instructions = await this.prepareRedeemInstructions(
      amount,
      walletKey
    );
    const { transaction, blockhash, lastValidBlockHeight } =
      await this.makeTransaction(instructions, walletKey, 800_000);
    const signature = await this.sendTransaction(
      transaction,
      walletAdapter.adapter
    );
    await this.confirmTransaction({
      signature,
      blockhash,
      lastValidBlockHeight,
    });
    return signature;
  }
}
