import * as Sentry from "@sentry/browser";
import { Extras } from "@sentry/types";

// keys for string values on the `context` object
// we want to index in Sentry for search, graphs ..
const CONTEXT_TAGS = ["country"] as const;
const ENV = process.env.NODE_ENV || "development";

const reportToSentry = async (
  payload: any,
  level: Sentry.Severity,
  context: Extras | void
) => {
  Sentry.withScope((scope) => {
    scope.setLevel(level);

    if (context !== undefined) {
      CONTEXT_TAGS.forEach((tag) => {
        scope.setTag(`uxd-${tag}`, context[tag] as string);
      });
      scope.setExtras(context);
    }
    Sentry.captureException(payload);
  });

  try {
    await Sentry.flush();
  } catch (e) {
    console.error(e);
  }
};

const methodToSeverityMap = {
  warn: Sentry.Severity.Warning,
  error: Sentry.Severity.Error,
};

const LOGGER_METHODS = ["debug", "log", "info", "warn", "error"] as const;
type Logger = {
  debug: (payload: any, context?: Extras) => void;
  log: (payload: any, context?: Extras) => void;
  info: (payload: any, context?: Extras) => void;
  warn: (payload: any, context?: Extras) => void;
  error: (payload: any, context?: Extras) => void;
};

const logger = LOGGER_METHODS.reduce((acc, method) => {
  acc[method] = function (...args) {
    if (ENV === "test") return;

    const [payload, context] = args;
    const extra = payload.context
      ? { ...payload.context, ...context }
      : context;

    if (method !== "debug" || ENV === "development") {
      console[method].call(console, payload, extra);
    }

    if (
      ENV === "production" &&
      method in methodToSeverityMap &&
      // only reports if there is some network access, doesn't guarantee
      // internet access, but it's a good enough filter to avoid spamming sentry
      // when user is offline for sure.
      window.navigator.onLine
    ) {
      reportToSentry(
        payload,
        methodToSeverityMap[method as keyof typeof methodToSeverityMap],
        extra
      );
    }
  };
  return acc;
}, {} as Logger);

export default logger;
