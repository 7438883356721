import { useDispatch } from "react-redux";

import { connectWalletAction } from "../../../actions/wallet";
import { WalletAdapterName } from "../../../adapters/WalletAdapter";
import useTermsOfService from "../../../hooks/useTermsOfService";

import Modal, { Modal as BaseModal } from "../../common/modal/Modal";
import ActionButton from "../../common/action-button/ActionButton";
import WalletList from "../wallet-list/WalletList";
import TermsOfService, {
  FORM_ID as TERMS_OF_SERVICE_FORM_ID,
} from "../../terms-of-service/TermsOfService";

type Props = {
  setOpen: (open: boolean) => void;
};

const MODAL_KEY = "wallet-modal";

const WalletModal = ({ setOpen }: Props) => {
  const dispatch = useDispatch();

  const close = setOpen.bind(null, false);

  const handleSelectWallet = (walletAdapter: WalletAdapterName) => {
    dispatch(connectWalletAction(walletAdapter));
    close();
  };

  const { checked, confirmed, setChecked, setConfirmed } = useTermsOfService();

  const ModalComponent = process.env.NODE_ENV === "test" ? BaseModal : Modal;

  if (!confirmed) {
    return (
      <ModalComponent
        key={MODAL_KEY}
        close={close}
        title="Connect wallet"
        size="medium"
        action={
          <ActionButton
            type="submit"
            label="Confirm"
            color="green"
            disabled={!checked}
            form={TERMS_OF_SERVICE_FORM_ID}
          />
        }
      >
        <TermsOfService
          formId={TERMS_OF_SERVICE_FORM_ID}
          checked={checked}
          handleCheck={setChecked}
          handleSubmit={() => setConfirmed()}
        />
      </ModalComponent>
    );
  }

  return (
    <ModalComponent
      key={MODAL_KEY}
      close={close}
      title="Connect wallet"
      size="small"
    >
      <WalletList
        handleSelectWallet={handleSelectWallet}
        walletAdapters={window.__UXD__.solana.config.getWalletAdapters()}
      />
    </ModalComponent>
  );
};

export default WalletModal;
