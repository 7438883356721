import { SyntheticEvent, useEffect, useRef, useState } from "react";
import ActionButton from "../common/action-button/ActionButton";
import Options from "../common/options/Options";
import Modal, { Modal as BaseModal } from "../common/modal/Modal";
import { sanitizeValue } from "../../utils";

import "./Slippage.scss";

type Props = {
  setOpen: (open: boolean) => void;
  slippage: string;
  setSlippage: (slippage: string) => void;
};

const FORM_ID = "slippage-form";
const INPUT_PLACEHOLDER = "1.5";
const SHOW_WARNING_THRESHOLD = 0.1;
const SLIPPAGE_DECIMALS = 1;

export type SlippageOptions = "0.1" | "0.5" | "1.0" | "2.0";
export const SLIPPAGE_OPTION_VALUES = ["0.1", "0.5", "1.0", "2.0"] as const;

function getInitialControlValues(slippage: string) {
  const optionValue = SLIPPAGE_OPTION_VALUES.find(
    (value) => Number(slippage) === Number(value)
  );
  if (optionValue) {
    return { initialInputValue: "", initialOptionValue: optionValue };
  }

  return { initialInputValue: slippage || "", initialOptionValue: "" };
}

const SlippageModal = ({ setOpen, slippage, setSlippage }: Props) => {
  const { initialInputValue, initialOptionValue } =
    getInitialControlValues(slippage);

  // Local states for the input & the options
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [optionValue, setOptionValue] = useState(initialOptionValue);

  // Refs to keep track of changes in  input / option values
  const previousInputValueRef = useRef(initialInputValue);
  const previousOptionValueRef = useRef(initialOptionValue);
  const inputValueChange = previousInputValueRef.current !== inputValue;
  const optionValueChange = previousOptionValueRef.current !== optionValue;

  // When the state value for one type of input is set
  // clear the value of the opposite type of input.
  // This way, only ever one type of input is "active" in the user's POV.
  useEffect(() => {
    if (inputValue !== "" && inputValueChange) {
      setOptionValue("");
      previousInputValueRef.current = inputValue;
      previousOptionValueRef.current = "";
    } else if (optionValue !== "" && optionValueChange) {
      setInputValue("");
      previousInputValueRef.current = "";
      previousOptionValueRef.current = optionValue;
    }
  }, [inputValue, optionValue, inputValueChange, optionValueChange]);

  const value = inputValue || optionValue;
  const close = setOpen.bind(null, false);

  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    // Prevent React's stupid event system to bubble this event
    // up the tree to the swap form even though we render this modal
    // in a portal - so in a DOM node away from the swap form.
    evt.stopPropagation();

    if (value) {
      setSlippage(value);
    }

    close();
  };

  const showWarning = Number(value) < SHOW_WARNING_THRESHOLD;

  const ModalComponent = process.env.NODE_ENV === "test" ? BaseModal : Modal;

  return (
    <ModalComponent
      close={close}
      title="Adjust slippage tolerance"
      action={
        <ActionButton
          color="green"
          type="submit"
          form={FORM_ID}
          label="Apply"
        />
      }
    >
      <p className="Slippage-title">
        Slippage refers to the difference between the expected price of a trade
        and the price at which the trade is executed
      </p>
      <form className="Slippage-form" id={FORM_ID} onSubmit={handleSubmit}>
        <Options
          options={SLIPPAGE_OPTION_VALUES.map((value) => ({
            value,
            label: `${value}%`,
          }))}
          value={optionValue}
          name="slippage"
          handleChange={setOptionValue}
        />
        <div className="Slippage-input-wrapper">
          <label htmlFor="slippage-input">Custom</label>
          <div className="Slippage-input-container">
            <input
              data-testid="custom-slippage-input"
              type="text"
              name="slippage-input"
              id="slippage-input"
              placeholder={INPUT_PLACEHOLDER}
              autoComplete="off"
              value={inputValue}
              onChange={(evt) => {
                const target = evt.target;
                const sanitized = sanitizeValue(
                  target.value,
                  SLIPPAGE_DECIMALS
                );
                if (Number(sanitized) < 100) {
                  setInputValue(sanitized);
                }
              }}
              onBlur={() => {
                const fixed = Number(value).toFixed(SLIPPAGE_DECIMALS);
                if (value !== fixed) {
                  setInputValue(fixed);
                }
              }}
            />
          </div>
        </div>
        {showWarning ? (
          <strong className="Slippage-warning">
            Your transaction may fail
          </strong>
        ) : null}
      </form>
    </ModalComponent>
  );
};

export default SlippageModal;
