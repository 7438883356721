import logger from "./logger";
import {
  FetchUserInfoError,
  SanctionedUserError,
  UnknownUserError,
} from "./error";
import { UserInfo } from "../types";

const ENDPOINT = process.env.REACT_APP_GEOIP_ENDPOINT || "";

export async function getUserInfo(): Promise<UserInfo> {
  try {
    if (!ENDPOINT) {
      throw new Error(
        "Missing geo-ip endpoint process.env.REACT_APP_GEOIP_ENDPOINT"
      );
    }
    const res = await fetch(new URL("/api", ENDPOINT).toString(), {
      cache: "no-cache",
    });
    const { country, ip, isVpn } = await res.json();
    const { userAgent = "" } = window.navigator;
    return { country, ip, userAgent, isVpn };
  } catch (err) {
    logger.error(err);
    throw new FetchUserInfoError(ENDPOINT);
  }
}

// https://en.wikipedia.org/wiki/ISO_3166-1
const SANCTIONED_COUNTRIES = [
  { country: "Antigua and Barbuda", code: "AG" },
  { country: "Algeria", code: "DZ" },
  { country: "Bangladesh", code: "BD" },
  { country: "Bolivia", code: "BO" },
  { country: "Belarus", code: "BY" },
  { country: "Burundi", code: "BI" },
  { country: "Burma (Myanmar)", code: "MM" },
  { country: "Cote D'Ivoire (Ivory Coast)", code: "CI" },
  { country: "Cuba", code: "CU" },
  { country: "Democratic Republic of Congo", code: "CD" },
  { country: "Ecuador", code: "EC" },
  { country: "Iran", code: "IR" },
  { country: "Iraq", code: "IQ" },
  { country: "Liberia", code: "LR" },
  { country: "Libya", code: "LY" },
  { country: "Mali", code: "ML" },
  { country: "Morocco", code: "MA" },
  { country: "Nepal", code: "NP" },
  { country: "North Korea", code: "KP" },
  { country: "Somalia", code: "SO" },
  { country: "Sudan", code: "SD" },
  { country: "Syria", code: "SY" },
  { country: "Venezuela", code: "VE" },
  { country: "Yemen", code: "YE" },
  { country: "Zimbabwe", code: "ZW" },
  { country: "United States", code: "US" },
  { country: "Nicaragua", code: "NI" },
  { country: "United Kingdom", code: "GB" },
  { country: "Russian Federation", code: "RU" },
];

export const isUserFromSanctionedArea = ({ country }: UserInfo) =>
  SANCTIONED_COUNTRIES.find(({ code }) => code === country);

export const enforceGeoIp = (user: UserInfo) => {
  if (!user.country || !user.ip) {
    throw new UnknownUserError(user);
  }

  const sanctioned = isUserFromSanctionedArea(user);
  if (sanctioned || user.isVpn) {
    throw new SanctionedUserError(user);
  }
};
