import useMercurialVaultDepositoryInfo from "../../hooks/useMercurialVaultDepositoryInfo";
import useCredixLpDepositoryInfo from "../../hooks/useCredixLpDepositoryInfo";
import Section from "../common/section/Section";
import Tokens from "./tokens/Tokens";
import CollateralComposition from "./collateral-composition/CollateralComposition";
import InsuranceFundPositions from "./insurance-fund-positions/InsuranceFundPositions";
import withPeriodicRemount from "./with-periodic-remount/withPeriodicRemount";
import useIdentityDepositoryInfo from "../../hooks/useIdentityDepositoryInfo";

import "./Dashboard.scss";

export const Dashboard = () => {
  const identityDepository = useIdentityDepositoryInfo();
  const mercurialVaultDepository = useMercurialVaultDepositoryInfo();
  const credixLpDepository = useCredixLpDepositoryInfo();

  return (
    <main className="Dashboard">
      <Section>
        <Tokens />
      </Section>

      <CollateralComposition
        identityDepository={identityDepository}
        mercurialVaultDepository={mercurialVaultDepository}
        credixLpDepository={credixLpDepository}
      />

      <InsuranceFundPositions />
    </main>
  );
};

const ONE_SECOND = 1_000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

export default withPeriodicRemount(Dashboard, ONE_HOUR);
