import { ReactNode } from "react";
import { CollateralMintName } from "../../../config/solana.types";
import { FormAddon } from "../form/Form";
import CollateralForm, { Props as CollateralFormProps } from "./CollateralForm";
import CollateralFormInput from "./CollateralFormInput";

type Props = Omit<CollateralFormProps, "children"> & {
  onCollateralMintChange?: (newCollateralMintName: CollateralMintName) => void;
  onCollateralAmountChange: (amount: string) => void;
  onRedeemableAmountChange: (amount: string) => void;
  collateralAmount: string;
  redeemableAmount: string;
  connected: boolean;
  children?: ReactNode;
};

const ToCollateralForm = ({
  onCollateralMintChange,
  onCollateralAmountChange,
  onRedeemableAmountChange,
  collateralAmount,
  redeemableAmount,
  connected,
  children,
  ...formProps
}: Props) => {
  return (
    <CollateralForm {...formProps}>
      {({ collateral, redeemable, availableCollateralMints }) => {
        return (
          <>
            <CollateralFormInput
              id="redeemable-amount-input"
              name="redeemable-amount"
              label="From"
              value={redeemableAmount}
              handleChange={onRedeemableAmountChange}
              maxButtonEnabled={true}
              connected={connected}
              mintInfo={redeemable}
            />
            {children ? (
              <FormAddon className="CollateralForm">{children}</FormAddon>
            ) : null}
            <CollateralFormInput
              id="collateral-amount-input"
              name="collateral-amount"
              label="To"
              value={collateralAmount}
              availableMints={availableCollateralMints}
              handleMintChange={onCollateralMintChange}
              handleChange={onCollateralAmountChange}
              maxButtonEnabled={false}
              connected={connected}
              mintInfo={collateral}
            />
          </>
        );
      }}
    </CollateralForm>
  );
};

export default ToCollateralForm;
