/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import useTermsOfService from "../../../hooks/useTermsOfService";
import ActionButton from "../../common/action-button/ActionButton";
import Modal, { Modal as BaseModal } from "../../common/modal/Modal";
import TermsOfService, {
  FORM_ID as TERMS_OF_SERVICE_FORM_ID,
} from "../../terms-of-service/TermsOfService";
import "./UXPCallToAction.scss";

const SWAP_URL = "https://jup.ag/swap/USDC-UXP";

const openSwapURL = () => {
  window.open(SWAP_URL, "blank", "noreferrer");
};

const UXPCallToAction = () => {
  const [termsOfServiceModalOpen, setTermsOfServiceModalOpen] = useState(false);
  const { checked, confirmed, setChecked, setConfirmed } = useTermsOfService();

  const closeTermsOfServiceModal = setTermsOfServiceModalOpen.bind(null, false);

  const ModalComponent = process.env.NODE_ENV === "test" ? BaseModal : Modal;

  const termsOfService = termsOfServiceModalOpen ? (
    <ModalComponent
      close={closeTermsOfServiceModal}
      title="Terms of service"
      size="medium"
      action={
        <ActionButton
          type="submit"
          label="Confirm"
          color="green"
          disabled={!checked}
          form={TERMS_OF_SERVICE_FORM_ID}
        />
      }
    >
      <TermsOfService
        formId={TERMS_OF_SERVICE_FORM_ID}
        checked={checked}
        handleCheck={setChecked}
        handleSubmit={() => {
          setConfirmed();
          openSwapURL();
          setTermsOfServiceModalOpen(false);
        }}
      />
    </ModalComponent>
  ) : null;

  return (
    <>
      {termsOfService}

      <a
        href={SWAP_URL}
        onClick={(evt) => {
          evt.preventDefault();

          if (!confirmed) {
            setTermsOfServiceModalOpen(true);
            return;
          }

          openSwapURL();
        }}
        className="UXPCallToAction"
      >
        $UXP
      </a>
    </>
  );
};

export default UXPCallToAction;
