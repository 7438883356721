import { calculateMostAppropriateUnit } from "../../../utils/formatting";
import ExternalLink from "../../common/external-link/ExternalLink";

const FALLBACK_DISPLAY = "-";
const INITIAL_INSURANCE_FUND_AMOUNT = 57_086_131;

export function getPercentageOfInitialInsuranceFund(amount: number) {
  return (amount / INITIAL_INSURANCE_FUND_AMOUNT).toLocaleString("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
  });
}

type Props = {
  blockchain: "Solana" | "Optimism" | "Arbitrum" | "Ethereum";
  platform: string;
  name: string;
  amount: number | null;
  linkHref?: string;
  linkTitle?: string;
};

const PositionRow = ({
  blockchain,
  platform,
  name,
  amount,
  linkHref,
  linkTitle,
}: Props) => {
  // Do not display the row if the value is 0
  if (amount === 0) {
    return null;
  }

  const amountDisplay =
    amount?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }) ?? FALLBACK_DISPLAY;

  const unit = amount && calculateMostAppropriateUnit(amount);

  const amountCompactDisplay = unit
    ? `${(amount / unit.divisor).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 3,
      })}${unit.symbol}`
    : FALLBACK_DISPLAY;

  const nameDisplay = linkHref ? (
    <ExternalLink name={name} href={linkHref} title={linkTitle} />
  ) : (
    name
  );

  return (
    <tr>
      <td className="InsuranceFundPositions-col--blockchain">{blockchain}</td>
      <td className="InsuranceFundPositions-col--platform">{platform}</td>
      <td className="InsuranceFundPositions-col--position">{nameDisplay}</td>
      <td>
        <span className="InsuranceFundPositions-col--value-full">
          {amountDisplay}
        </span>
        <strong className="InsuranceFundPositions-col--value-compact">
          {amountCompactDisplay}
        </strong>
      </td>
      <td className="InsuranceFundPositions-col--fund-percentage">
        {amount
          ? getPercentageOfInitialInsuranceFund(amount)
          : FALLBACK_DISPLAY}
      </td>
    </tr>
  );
};

export default PositionRow;
