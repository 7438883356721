import { ACTION_TYPES } from "../actions/constants";
import { Actions } from "../actions/types";
import { MintName as SolanaMintName } from "../config/solana.types";
import { TokenName as OptimismTokenName } from "../config/optimism.types";
import { TokenName as ArbitrumTokenName } from "../config/arbitrum.types";
import { Balance } from "../types";

type Keys = SolanaMintName | OptimismTokenName | ArbitrumTokenName;

export type BalanceState = {
  [key in Keys]: Balance;
};

export const INITIAL_STATE: BalanceState = {
  UXD: null,
  ETH: null,
  UXP: null,
  WETH: null,
  USDC: null,
  USDC_IDENTITY: null,
  USDC_MERCURIAL: null,
  USDC_CREDIX: null,
  USDC_ARBITRUM: null,
} as const;

const balanceReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): BalanceState => {
  switch (action.type) {
    case ACTION_TYPES.SET_BALANCE: {
      // Check for changes. If no change, return actual state.
      if (
        Object.entries(action.payload).every(
          ([key, value]) => state[key as Keys] === value
        )
      ) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_TYPES.DISCONNECT_WALLET: {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};

export default balanceReducer;
