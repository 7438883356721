import { TokenInfo } from "../../../config/optimism.types";
import InputMaxButton from "./InputMaxButton";

type Props = {
  handleClick?: ((balance: string) => void) | void;
  activeToken: TokenInfo;
};

const InputMaxButtonOptimism = ({ handleClick, activeToken }: Props) => {
  return (
    <InputMaxButton
      handleClick={handleClick}
      assetName={activeToken.name}
      assetCurrency={activeToken.displayName}
      assetDecimals={activeToken.decimals}
    />
  );
};

export default InputMaxButtonOptimism;
