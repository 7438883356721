import { ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import "./Button.scss";

type ButtonHTMLProps = ButtonHTMLAttributes<HTMLButtonElement>;
type Props = ButtonHTMLProps & { type: NonNullable<ButtonHTMLProps["type"]> };

const Button = ({ className, type, ...props }: Props) => (
  <button {...props} type={type} className={clsx(["Button", className])} />
);

export default Button;
