import { ReactNode } from "react";
import { MintInfo } from "../../../config/solana.types";
import InputAmount from "./InputAmount";

type Props = {
  id: string;
  name: string;
  amount: string;
  mint: MintInfo;
  handleChange: (value: string) => void;
  disabled?: boolean;
  reset?: boolean;
  children?: (node: ReactNode) => ReactNode;
};

const InputAmountMint = ({
  id,
  name,
  amount,
  mint,
  handleChange,
  disabled,
  reset,
  children,
}: Props) => {
  return (
    <InputAmount
      id={id}
      name={name}
      amount={amount}
      assetName={mint.name}
      assetCurrency={mint.displayName ?? mint.name}
      assetDecimals={mint.decimals}
      handleChange={handleChange}
      disabled={disabled}
      reset={reset}
    >
      {children}
    </InputAmount>
  );
};

export default InputAmountMint;
