import { SlippageOptions } from "../components/slippage/Slippage";
import { Contracts, IArbitrumConfiguration, Tokens } from "./arbitrum.types";
import { ClusterName } from "./types";
import AUXDArbitrumBaseConfig from "./uxd.arbitrum.base";

class UXDArbitrumTestnetConfig
  extends AUXDArbitrumBaseConfig
  implements IArbitrumConfiguration
{
  public readonly clusterName: ClusterName = "mainnet";

  public readonly contracts: Contracts = {
    controller: {
      address: "0xf73Ec7aF85066598b8acD61Ed7940523A3320649",
    },
  };

  public readonly defaultSlippage: SlippageOptions = "0.1";

  public readonly rpcProviderUrl =
    "https://arbitrum-mainnet.infura.io/v3/1b07c06cf0494e879d4eaa6f555cbd7f";

  // Goerli Arbitrum
  public readonly chainId = 42_161;

  public readonly tokens: Tokens = {
    USDC_ARBITRUM: {
      name: "USDC_ARBITRUM",
      displayName: "USDC",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    },
    UXD: {
      name: "UXD",
      displayName: "UXD",
      decimals: 18,
      address: "0x0bb04ab98fCF145F43EB3D8E7c6d73919C0e0689",
    },
  };
}

const UXDArbitrumTestnetConfigInstance = new UXDArbitrumTestnetConfig();

export default UXDArbitrumTestnetConfigInstance;
