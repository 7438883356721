import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { ExodusWalletAdapter } from "@solana/wallet-adapter-exodus";
import { BackpackWalletAdapter } from "@solana/wallet-adapter-backpack";

// Same order as they are presented in the UI.
export function getWalletAdapters() {
  return {
    phantom: {
      name: "phantom",
      label: "Phantom",
      adapter: new PhantomWalletAdapter(),
    },
    solflare: {
      name: "solflare",
      label: "Solflare",
      adapter: new SolflareWalletAdapter(),
    },
    backpack: {
      name: "backpack",
      label: "Backpack",
      adapter: new BackpackWalletAdapter(),
    },
    slope: {
      name: "slope",
      label: "Slope",
      adapter: new SlopeWalletAdapter(),
    },
    exodus: {
      name: "exodus",
      label: "Exodus",
      adapter: new ExodusWalletAdapter(),
    },
  } as const;
}

export type WalletAdapters = ReturnType<typeof getWalletAdapters>;
export type WalletAdapterName = keyof WalletAdapters;

export type WalletAdapter = WalletAdapters[WalletAdapterName];
