import clsx from "clsx";
import { memo } from "react";
import { MintName as SolanaMintName } from "../../../config/solana.types";
import { TokenName as OptimismTokenName } from "../../../config/optimism.types";
import { TokenName as ArbitrumTokenName } from "../../../config/arbitrum.types";
import useBalance from "../../../hooks/useBalance";
import { renderAmount } from "../../../utils/formatting";
import "./InputMaxButton.scss";

type Props = {
  handleClick?: ((balance: string) => void) | void;
  assetName: SolanaMintName | OptimismTokenName | ArbitrumTokenName;
  assetCurrency: string;
  assetDecimals: number;
};

const InputMaxButton = ({
  handleClick,
  assetName,
  assetCurrency,
  assetDecimals,
}: Props) => {
  const { balance } = useBalance(assetName);

  const onMaxButtonClick = () => {
    // impossible case here to make TS happy.
    if (balance === null) return;
    if (typeof handleClick !== "function") return;
    handleClick(balance.toString());
  };

  if (balance !== null) {
    const className = clsx([
      "InputMaxButton",
      typeof handleClick !== "function" && `InputMaxButton--disabled`,
    ]);

    const displayBalance = renderAmount(balance, {
      currency: assetCurrency,
      decimals: assetDecimals,
    });

    return (
      <span>
        <span
          data-testid="InputMaxButton-testid"
          className={className}
          onClick={onMaxButtonClick}
        >
          {displayBalance}
        </span>
      </span>
    );
  }

  return null;
};

export default memo(InputMaxButton);
