import { useEffect, useRef, ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";
import clsx from "clsx";
import logger from "../../../utils/logger";
import IconButton from "../icon-button/IconButton";

import "./Modal.scss";

type Props = {
  title: string;
  action?: ReactNode;
  children: ReactNode;
  close: () => void;
  size?: "small" | "medium";
};

const ESCAPE_KEYS = ["Esc", "Escape"];

export const ModalPortalContainer = ({ children }: { children: ReactNode }) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    const container = document.getElementById("modal-portal-container");
    if (!container) {
      logger.error(new Error("modal portal container node unreachable"));
    }

    setPortalContainer(container);
  }, []);

  if (portalContainer === null) {
    return null;
  }

  return createPortal(children, portalContainer);
};

export const Modal = ({
  close,
  title,
  children,
  action,
  size = "medium",
}: Props) => {
  const { current: handleKeyDown } = useRef((evt: KeyboardEvent) => {
    if (ESCAPE_KEYS.includes(evt.key)) {
      close();
    }
  });

  // While there is no focus trap on the modal, we must listen for the
  // keydown "escape key" event globally.
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const innerClassName = clsx({
    "Modal-inner": true,
    [`Modal-inner-${size}`]: true,
    "Modal-inner-with-action": !!action,
  });

  return (
    <div className="Modal">
      <div
        className="Modal-overlay"
        data-testid="Modal-overlay"
        onClick={() => close()}
      />
      <div className={innerClassName} role="dialog">
        <div className="Modal-header">
          <h4>{title}</h4>
          <IconButton
            icon="cross"
            className="Modal-close-button"
            onClick={() => close()}
            type="button"
          />
        </div>
        <div className="Modal-content">{children}</div>
        {action ? <div className="Modal-footer">{action}</div> : null}
      </div>
    </div>
  );
};

export const PortalizedModal = (props: Props) => (
  <ModalPortalContainer>
    <FocusTrap>
      <div>
        <Modal {...props} />
      </div>
    </FocusTrap>
  </ModalPortalContainer>
);

export default PortalizedModal;
