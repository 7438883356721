import { useCallback, useEffect, useState } from "react";
import useTokenInfo, { TokenSymbol } from "../../../hooks/useTokenInfo";
import logger from "../../../utils/logger";
import { Icon } from "../../common/icon/Icon";

import "./TokenInfo.scss";

type Props = {
  symbol: TokenSymbol;
  decimals?: number;
};

const getCoingeckoLink = (coingeckoId: string) =>
  `https://www.coingecko.com/en/coins/${coingeckoId}`;

const getCoingeckoPriceEndpoint = (coingeckoId: string) =>
  `https://api.coingecko.com/api/v3/simple/price?ids=${coingeckoId}&vs_currencies=usd`;

const TokenInfo = ({ symbol, decimals = 2 }: Props) => {
  const { coingeckoId, label, icon } = useTokenInfo(symbol);
  const [price, setPrice] = useState<number | null>(null);

  const fetchCoingeckoPrice = useCallback(async () => {
    const res = await fetch(getCoingeckoPriceEndpoint(coingeckoId), {
      headers: { Accept: "application/json" },
    });
    const json = await res.json();

    const usdPrice: number = json[coingeckoId].usd;
    setPrice(usdPrice);
  }, [coingeckoId]);

  useEffect(() => {
    fetchCoingeckoPrice().catch((err) => {
      logger.error(`Unable to fetch prices from coingecko`, { err });
    });
  }, [fetchCoingeckoPrice]);

  return (
    <a
      className="TokenInfo"
      href={getCoingeckoLink(coingeckoId)}
      target="_blank"
      rel="external noreferrer"
    >
      <Icon name={icon} className="TokenInfo-icon" />
      <span className="TokenInfo-content">
        <strong className="TokenInfo-token-label">{label}</strong>
        <em className="TokenInfo-token-price">
          {price?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: decimals,
          }) ?? "-"}
        </em>
      </span>
    </a>
  );
};

export default TokenInfo;
