import { ReactNode, useEffect, useRef } from "react";
import clsx from "clsx";

import { sanitizeValue } from "../../../utils";
import { MintName as SolanaMintName } from "../../../config/solana.types";
import { TokenName as OptimismTokenName } from "../../../config/optimism.types";
import { TokenName as ArbitrumTokenName } from "../../../config/arbitrum.types";

import "./InputAmount.scss";

const parseAmount = (amount: string | number): number => Number(String(amount));

type Props = {
  id: string;
  name: string;
  amount: string;
  assetName: SolanaMintName | OptimismTokenName | ArbitrumTokenName;
  assetCurrency: string;
  assetDecimals: number;
  handleChange: (value: string) => void;
  disabled?: boolean;
  reset?: boolean;
  children?: (node: ReactNode) => ReactNode;
};

const InputAmount = ({
  id,
  name,
  amount,
  assetName,
  assetCurrency,
  assetDecimals,
  disabled = false,
  handleChange,
  reset,
  children: renderAddon = (node: ReactNode) => node,
}: Props) => {
  const previousResetRef = useRef<boolean>(reset ?? false);
  const resetChange = previousResetRef.current !== reset;

  useEffect(() => {
    previousResetRef.current = reset ?? false;
  }, [reset]);

  useEffect(() => {
    if (resetChange) {
      handleChange("");
    }
  }, [resetChange, reset, handleChange]);

  const displayAmount =
    amount !== "" ? sanitizeValue(amount, assetDecimals) : "";

  const className = clsx([
    "InputAmount-asset-icon",
    `InputAmount-asset-icon--${assetName.toLowerCase()}`,
  ]);

  return (
    <div className="InputAmount" data-testid="InputAmount-testid">
      <span className={className} />
      <input
        id={id}
        name={name}
        type="text"
        placeholder="0"
        value={displayAmount}
        disabled={disabled}
        onChange={(e) => {
          const caret = e.target.selectionStart;
          const element = e.target;

          window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
          });

          const sanitized = sanitizeValue(e.target.value, assetDecimals);

          handleChange(sanitized);
        }}
        onFocus={() => {
          if (!parseAmount(amount)) {
            handleChange("");
          }
        }}
        onBlur={() => {
          if (amount !== "" && !parseAmount(amount)) {
            handleChange("0");
          }
        }}
        autoComplete="off"
      />
      {<span className="InputAmount-asset">{renderAddon(assetCurrency)}</span>}
    </div>
  );
};

export default InputAmount;
