import { memo } from "react";
import Dropdown, { TOption } from "../common/dropdown/Dropdown";
import "./Network.scss";

// False in prod

const OPTIONS = [
  {
    name: "Solana",
    label: "Solana Mainnet-Beta",
    icon: "solana" as const,
    value: "solana-mainnet-beta",
    href:
      process.env.REACT_APP_IS_PRODUCTION === "true"
        ? "https://app.uxd.fi"
        : "/?cluster=mainnet-beta",
    enabled: process.env.REACT_APP_ENABLE_SOLANA_MAINNET === "true",
  },
  {
    name: "Solana Dev",
    label: "Solana Devnet",
    icon: "solana" as const,
    value: "solana-devnet",
    href: "https://app.uxd.fi/?cluster=devnet",
    enabled: process.env.REACT_APP_ENABLE_SOLANA_DEVNET === "true",
  },
  {
    name: "Optimism",
    label: "Optimism Goerli Testnet",
    icon: "optimism" as const,
    value: "optimism-testnet",
    href: "https://optimism.uxd.fi",
    enabled: process.env.REACT_APP_ENABLE_OPTIMISM_TESTNET === "true",
  },
  {
    name: "Arbitrum",
    label: "Arbitrum One",
    icon: "arbitrum" as const,
    value: "arbitrum-mainnet",
    href: "https://arbitrum.uxd.fi",
    enabled: process.env.REACT_APP_ENABLE_ARBITRUM_MAINNET === "true",
  },
  {
    name: "Arbitrum",
    label: "Arbitrum Goerli Testnet",
    icon: "arbitrum" as const,
    value: "arbitrum-testnet",
    href: "https://arbitrum.uxd.fi/?cluster=testnet",
    enabled: process.env.REACT_APP_ENABLE_ARBITRUM_TESTNET === "true",
  },
];

const Network = () => {
  const network = `${
    window.__UXD__.config.chain
  }-${window.__UXD__.config.getClusterName()}`;

  const options = OPTIONS.reduce((acc, { enabled, ...option }) => {
    if (enabled) {
      acc.push(option);
    }
    return acc;
  }, [] as TOption[]);

  const { name } = options.find((option) => option.value === network)!;

  return (
    <div className="Network">
      <Dropdown
        standalone={false}
        name="network-dropdown"
        value={network}
        options={options}
      >
        <span className="Network-dropdown-anchor">{name}</span>
      </Dropdown>
    </div>
  );
};

export default memo(Network, () => true);
