import { ReactNode } from "react";
import "./Section.scss";

type Props = {
  title?: ReactNode;
  children: ReactNode;
};

const Section = ({ title, children }: Props) => {
  return (
    <section className="Section">
      {title ? <h3 className="Section-title">{title}</h3> : null}
      <div className="Section-content">{children}</div>
    </section>
  );
};

export default Section;
