import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";
import { CollateralMintName as SolanaCollateralMintName } from "../config/solana.types";

export type MintState = {
  collateralMintName: SolanaCollateralMintName;
  collateralAmount: string;
  redeemableAmount: string;
};

const INITIAL_STATE = {
  collateralMintName: "USDC_MERCURIAL",
  collateralAmount: "",
  redeemableAmount: "",
} as const;

const mintReducer = (
  state = INITIAL_STATE,
  action: Actions.AnyAction
): MintState => {
  switch (action.type) {
    case ACTION_TYPES.SET_MINT_COLLATERAL_MINT:
      if (action.payload.collateralMintName === state.collateralMintName) {
        return state;
      }

      return {
        collateralAmount: INITIAL_STATE.collateralAmount,
        redeemableAmount: INITIAL_STATE.redeemableAmount,
        collateralMintName: action.payload.collateralMintName,
      };

    case ACTION_TYPES.SET_MINT_AMOUNTS:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_TYPES.MINT_SUCCESS:
      return {
        ...INITIAL_STATE,
        collateralMintName: state.collateralMintName,
      };

    default:
      return state;
  }
};

export default mintReducer;
