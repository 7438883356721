import { Actions } from "../actions/types";
import { ACTION_TYPES } from "../actions/constants";

export type SlippageState = string;

const getInitialState = () => {
  return (
    window.__UXD__.storage.slippagePercent ||
    window.__UXD__.config.getDefaultSlippagePercent()
  );
};

const slippageReducer = (
  state: SlippageState | null,
  action: Actions.AnyAction
): SlippageState => {
  if (action.type === ACTION_TYPES.SET_SLIPPAGE_AMOUNT) {
    // This should ideally be done through a Redux Middleware.
    // It's generally not a good practice to perform side-effects in reducers.
    // Reducers are meant to be pure functions.
    // However, we do not want to introduce such advanced Redux functionality,
    // as we eventually want to move away from Redux, or maybe not.
    // In any case, this is fine for now, since we're careful about it.
    if (window !== undefined && window.localStorage !== undefined) {
      try {
        window.localStorage.setItem("slippagePercent", action.payload);
      } catch {
        /* pass */
      }
    }
    return action.payload;
  }

  return state ?? getInitialState();
};

export default slippageReducer;
