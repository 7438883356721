import { memo, ReactNode } from "react";

import { Icon } from "../common/icon/Icon";

import "./Footer.scss";

const Link = ({
  href,
  children,
  className,
}: {
  href: string;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <a href={href} className={className} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export const Footer = () => {
  return (
    <footer className="Footer" data-testid="footer">
      <Link href="https://docs.uxd.fi">
        <Icon name="gitbook" className="Footer-icon-gitbook" />
      </Link>
      <Link href="https://uxdprotocol.medium.com/">
        <Icon name="medium" className="Footer-icon-medium" />
      </Link>
      <Link href="https://twitter.com/UXDProtocol">
        <Icon name="twitter" className="Footer-icon-twitter" />
      </Link>
      <Link href="https://github.com/uxdprotocol">
        <Icon name="github" className="Footer-icon-github" />
      </Link>
      <Link href="https://discord.com/invite/BHfpYmjsBM">
        <Icon name="discord" className="Footer-icon-discord" />
      </Link>
      <span className="Footer-vertical-separator" />
      <Link href="https://terms.uxd.fi" className="Footer-text-link">
        T&amp;C
      </Link>
    </footer>
  );
};

export default memo(Footer, () => true);
