import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Buffer } from "buffer";

import { UnknownUserError, SanctionedUserError } from "./utils/error";
import logger from "./utils/logger";
import init from "./init";
import App from "./App";

import "./index.scss";

// Buffer polyfills were injected automatically with react-scripts 4.0, now we need to inject it manually ourselves
window.Buffer = window.Buffer || Buffer;

async function start() {
  try {
    const { store } = await init();

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  } catch (err) {
    // Unable to initialize the app.
    logger.error(err);
    // Better to avoid rendering anything in our React app in such extreme cases
    // Instead, toggle visibility for the appropriate error page already present
    // in the DOM.

    // Hide the app loader in any case
    const appLoader = document.getElementById("app-loader");
    if (appLoader) {
      // @ts-expect-error
      appLoader.style = "display: none;";
    }

    // User must be blocked
    const geoBlockingErrors = [UnknownUserError, SanctionedUserError];
    if (geoBlockingErrors.some((errorType) => err instanceof errorType)) {
      const pageBlockedContainer = document.getElementById("page-blocked");
      // make ts happy, container should always be there
      if (pageBlockedContainer) {
        // @ts-expect-error
        pageBlockedContainer.style = "display: block;";
      }
    }

    // Something else is wrong
    else {
      const pageErrorContainer = document.getElementById("page-error");
      // make ts happy, container should always be there
      if (pageErrorContainer) {
        // @ts-expect-error
        pageErrorContainer.style = "display: block;";
      }
    }
  }
}

start();
