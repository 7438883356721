export const TOKENS = {
  UXD: {
    label: "UXD Stablecoin",
    coingeckoId: "uxd-stablecoin",
    icon: "UXDToken",
  },
  UXP: {
    label: "UXP Governance Token",
    coingeckoId: "uxd-protocol-token",
    icon: "UXPToken",
  },
} as const;

export type TokenSymbol = keyof typeof TOKENS;

const useTokenInfo = (symbol: TokenSymbol) => {
  return TOKENS[symbol];
};

export default useTokenInfo;
