import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createNotificationAction } from "../actions/notification";
import { getDisplayMessageFromError } from "../utils/error";
import logger from "../utils/logger";
import { useSelector } from "./hooks";
import transactionLink from "../components/common/transaction-link/TransactionLink";
import { SwapAction } from "../types";
import { mintAction } from "../actions/mint";
import { redeemAction } from "../actions/redeem";

function useSwap(swapAction: SwapAction): {
  swap: () => void;
  valid: boolean;
  loading: boolean;
} {
  const loading = useSelector((state) => state.loading);

  const { redeemableAmount, collateralAmount, collateralMintName } =
    useSelector((state) => (swapAction === "mint" ? state.mint : state.redeem));

  const dispatch = useDispatch();

  const doMint = useCallback(async () => {
    try {
      const txId = await dispatch(
        mintAction(collateralAmount, collateralMintName)
      );

      dispatch(
        createNotificationAction({
          title: "Mint Successful",
          message: transactionLink({
            message: "Your mint transaction was a success",
            transaction: txId as unknown as string,
            cluster: window.__UXD__.solana.config.getClusterName(),
          }),
          level: "success",
          icon: "info",
        })
      );
    } catch (err) {
      logger.error(err);

      dispatch(
        createNotificationAction({
          title: "Mint Error",
          message: "Transaction Failed",
          details: getDisplayMessageFromError(err),
          level: "error",
          icon: "info",
        })
      );
    }
  }, [collateralAmount, collateralMintName, dispatch]);

  const doRedeem = useCallback(async () => {
    try {
      const txId = await dispatch(
        redeemAction(redeemableAmount, collateralMintName)
      );

      dispatch(
        createNotificationAction({
          title: "Redeem Successful",
          message: transactionLink({
            message: "Your redeem transaction was a success",
            transaction: txId as unknown as string,
            cluster: window.__UXD__.solana.config.getClusterName(),
          }),
          level: "success",
          icon: "info",
        })
      );
    } catch (err) {
      logger.error(err);

      dispatch(
        createNotificationAction({
          title: "Redeem Error",
          message: "Transaction Failed",
          details: getDisplayMessageFromError(err),
          level: "error",
          icon: "info",
        })
      );
    }
  }, [collateralMintName, dispatch, redeemableAmount]);

  return {
    swap: swapAction === "mint" ? doMint : doRedeem,
    valid: Number(collateralAmount) > 0 && Number(redeemableAmount) > 0,
    loading,
  };
}

export default useSwap;
