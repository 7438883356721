import { PublicKey } from "@solana/web3.js";
import AUXDBaseConfig from "./uxd.base";
import {
  getWalletAdapters,
  WalletAdapter,
  WalletAdapterName,
  WalletAdapters,
} from "../adapters/WalletAdapter";

import {
  ClusterRPCEndpoints,
  CollateralMintInfo,
  CollateralMintName,
  CollateralMints,
  CounterpartMints,
  MintInfo,
  MintName,
  Mints,
  OtherMintInfo,
  OtherMintName,
  OtherMints,
} from "./solana.types";

import { ProgramInfo, ProgramName, Programs, Chain } from "./types";
import { SlippageOptions } from "../components/slippage/Slippage";

export default abstract class AUXDSolanaBaseConfig extends AUXDBaseConfig {
  public chain: Chain = "solana";
  protected abstract collateralMints: CollateralMints;
  protected abstract counterpartMints: CounterpartMints;
  protected abstract otherMints: OtherMints;

  protected abstract programs: Programs;
  protected abstract clusterRPCEndpoints: ClusterRPCEndpoints;
  protected abstract defaultSlippage: SlippageOptions;

  protected abstract stakingCampaign: PublicKey;

  // Used for staking program
  protected abstract governanceRealmAccountPubkey: PublicKey;
  protected abstract governanceProgramPubkey: PublicKey;

  protected walletAdapters: WalletAdapters = getWalletAdapters();

  public getWalletAdapters(): WalletAdapters {
    return this.walletAdapters;
  }

  public getWalletAdapter(walletAdapterName: WalletAdapterName): WalletAdapter {
    return this.walletAdapters[walletAdapterName];
  }

  public getMints(): Mints {
    return {
      ...this.collateralMints,
      ...this.counterpartMints,
      ...this.otherMints,
    };
  }

  public getMintInfo(mintName: MintName): MintInfo {
    return this.getMints()[mintName];
  }

  public GetOtherMintInfo(otherMintName: OtherMintName): OtherMintInfo {
    return this.otherMints[otherMintName];
  }

  public getCollateralMints(): CollateralMints {
    return this.collateralMints;
  }

  public getCollateralMintInfo(
    collateralMintName: CollateralMintName
  ): CollateralMintInfo {
    return this.collateralMints[collateralMintName];
  }

  public getPrograms(): Programs {
    return this.programs;
  }

  public getGovernanceRealmPubkey(): PublicKey {
    return this.governanceRealmAccountPubkey;
  }

  public getGovernanceProgramPubkey(): PublicKey {
    return this.governanceProgramPubkey;
  }

  public getProgramInfo(programName: ProgramName): ProgramInfo {
    return this.programs[programName];
  }

  public getClusterRPCEndpoints(): ClusterRPCEndpoints {
    return this.clusterRPCEndpoints;
  }

  public getDefaultSlippagePercent(): string {
    return this.defaultSlippage;
  }

  public getStakingCampaignPDA(): PublicKey {
    return this.stakingCampaign;
  }
}
