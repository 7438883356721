import { ChangeEvent } from "react";
import "./TermsOfService.scss";

type Props = {
  formId: string;
  checked: boolean;
  handleCheck: (checked: boolean) => void;
  handleSubmit: () => void;
};

export const FORM_ID = "terms-of-service-form";

const TermsOfService = ({ checked, handleCheck, handleSubmit }: Props) => {
  return (
    <form
      className="TermsOfService"
      data-testid="TermsOfService"
      id={FORM_ID}
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <input
        id="terms-of-service-checkbox"
        name="terms-of-service"
        type="checkbox"
        checked={checked}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          handleCheck(evt.target.checked);
        }}
      />
      <label htmlFor="terms-of-service-checkbox">
        Acknowledge that you have read and agreed to the{" "}
        <a href="https://terms.uxd.fi" rel="noreferrer" target="_blank">
          terms of service
        </a>{" "}
        and confirm that you are not based in a jurisdiction in which accessing
        this app may be restricted, including the United States
      </label>
    </form>
  );
};

export default TermsOfService;
