export function renderAmount(
  amount: number,
  {
    currency = "usd",
    decimals = 0,
  }: { currency?: string; decimals?: number } = {}
) {
  const formatted = amount.toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: 0,
    currency: "usd",
    style: "currency",
  });

  if (currency === "usd") {
    return formatted;
  }

  return `${formatted.replace("$", "")} ${currency}`;
}

export function calculateMostAppropriateUnit(value: number) {
  return (
    [
      {
        divisor: 1_000_000_000,
        symbol: "B",
      },
      {
        divisor: 1_000_000,
        symbol: "M",
      },
      {
        divisor: 1_000,
        symbol: "K",
      },
      {
        divisor: 1,
        symbol: "",
      },
    ] as const
  ).find((x) => value >= x.divisor);
}
