import { useCallback, useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { StakingAccount } from "@uxdprotocol/uxd-staking-client";
import logger from "../utils/logger";

export enum Status {
  INITIAL = "initial",
  LOADING = "loading",
  LOADED = "loaded",
  ERRORED = "errored",
}

const useStakingAccounts = (user: PublicKey | null) => {
  const [accounts, setAccounts] = useState<StakingAccount[]>([]);
  const [status, setStatus] = useState<Status>(Status.INITIAL);

  const fetchStakingAccounts = useCallback(async () => {
    // user disconnected their wallet, reset
    if (!user && status === Status.LOADED) {
      setAccounts([]);
      setStatus(Status.INITIAL);
      return;
    }

    // user is not connected yet, nothing to do
    if (!user) {
      return;
    }

    // need to fetch staking accounts
    // - user just connected their wallet
    // or
    // - need to re-fetch staking accounts
    if (user && status === Status.INITIAL) {
      setStatus(Status.LOADING);

      try {
        setAccounts(
          await window.__UXD__.solana.services.staking.getUserValidStakingAccounts(
            user
          )
        );
        setStatus(Status.LOADED);
      } catch (err) {
        setStatus(Status.ERRORED);
        logger.error(err);
      }
    }
  }, [status, user]);

  const signalAccountsFetch = useCallback(() => {
    setStatus(Status.INITIAL);
  }, []);

  useEffect(() => {
    fetchStakingAccounts();
  }, [fetchStakingAccounts]);

  return {
    accounts,
    status,
    signalAccountsFetch,
  };
};

export default useStakingAccounts;
